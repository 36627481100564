import styled from '@emotion/styled';

import { Flex } from '@Calix-Commerce/design-system/layout';
import { ButtonLink } from '@Calix-Commerce/design-system/components';

export const Title = styled.h2`
  font-size: 1.15rem;
  margin: 1.43rem 0 0;
`;

export const Product = styled(Flex)`
  padding: 2rem 0;
  border-bottom: 1px solid #a0a0a0;
`;

export const ProductInfoBlock = styled.div`
  width: 50%;
  padding: 0 1.07rem;
`;

export const ProductTitle = styled.h3`
  font-size: 1.14rem;
  font-weight: 700;
  margin: 0.21rem 0 1.43rem;
`;

export const ProductNotes = styled.ul`
  padding: 0.71rem 0 0.71rem 1.07rem;
  margin: 0;
`;

export const ProductDetailsButton = styled(ButtonLink)`
  padding: 0;

  .MuiButton-label {
    color: #4ab1b4;
    font-size: 1.43rem;
  }

  &:hover {
    text-decoration: none;
  }
`;

export const BomListTable = styled.table`
  width: 100%;
  margin-top: 0.5rem;
  border-bottom: 1px solid #000;
  border-collapse: collapse;
  text-align: left;

  thead {
    border-bottom: 1px solid #000;
  }

  th {
    color: #000;
    font-weight: normal;
  }

  td {
    padding: 1.25rem;
    padding-left: 0;
  }
`;
