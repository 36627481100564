import { ReactNode, useState } from 'react';
import { useMutation } from '@apollo/client';

import { Feedback as FeedbackComponent } from '@Calix-Commerce/design-system/components';
import { SEND_FEEDBACK } from 'connectors/mutations/feedback';

import { useRecoilState } from 'recoil';
import { feedbackCollectedState } from 'state/atoms';

import { FEEDBACK_SESSION_KEY } from 'utils/constants';
import { Rating } from '@Calix-Commerce/design-system/components';

const Feedback = (): ReactNode => {
  const [feedbackValue, feedbackSetValue] = useRecoilState(feedbackCollectedState);
  const [rating, setRating] = useState<Rating>(null);
  const [comment, setComment] = useState('');

  const [sendFeedback] = useMutation(SEND_FEEDBACK, {
    errorPolicy: 'ignore',
  });

  const handleFeedbackSubmit = () => {
    setTimeout(() => feedbackSetValue({ isFeedbackCollected: true }), 2000);
    sessionStorage.setItem(FEEDBACK_SESSION_KEY, String(true));

    sendFeedback({
      variables: {
        feedbackInput: {
          rating,
          comment,
          context: 'QuoteManager',
        },
      },
    });
  };

  return feedbackValue.isFeedbackCollected ? null : (
    <FeedbackComponent
      rating={rating}
      comment={comment}
      onSubmit={handleFeedbackSubmit}
      onRatingChange={setRating}
      onCommentChange={setComment}
    />
  );
};

export { Feedback };
