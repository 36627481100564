import DialogContentText from '@mui/material/DialogContentText';
import { ButtonRounded } from '@Calix-Commerce/design-system';

import { Dialog, DialogTitle, DialogContent, DialogActions } from './styledComponents';

export type ModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirmation: () => void;
  onDiscard: () => void;
  openingQuoteTransactionId: string;
  unsavedQuoteTransactionId: string;
};

const UnsavedChangesModal = ({
  isOpen = false,
  onCancel,
  onConfirmation,
  openingQuoteTransactionId,
  onDiscard,
  unsavedQuoteTransactionId,
}: ModalProps) => {
  return (
    <Dialog
      id="modal"
      open={isOpen}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      position={'center'}
    >
      <DialogTitle id="alert-dialog-slide-title">Unsaved Changes</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          You're leaving an{' '}
          <b>{unsavedQuoteTransactionId ? unsavedQuoteTransactionId : 'unsaved quote'}</b> and
          opening the quote <b>{openingQuoteTransactionId}</b>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonRounded id="cancel-btn" colorVariant="tertiary" onClick={onCancel}>
          CANCEL
        </ButtonRounded>
        <ButtonRounded id="confirm-btn" onClick={onDiscard}>
          DISCARD CHANGES
        </ButtonRounded>
        <ButtonRounded id="confirm-btn" onClick={onConfirmation}>
          SAVE CHANGES
        </ButtonRounded>
      </DialogActions>
    </Dialog>
  );
};

export { UnsavedChangesModal };
