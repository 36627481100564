import {
  Modal,
  SecondaryTable,
  SecondaryTableBody,
  SecondaryTableHead,
  SecondaryTableRow,
} from '@Calix-Commerce/design-system/components';
import { LineItem } from 'types';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirmation: () => void;
  items: LineItem[];
};

const UnavailableItemsModal = ({
  onClose,
  isOpen,
  items,
  onConfirmation,
  ...props
}: ModalProps) => {
  const ModalContent = () => {
    return (
      <>
        <p>
          This quote contains items that are no longer available to be quoted and will need to be
          removed in order to proceed with this quote. To remove unavailable items you can click the
          “Remove Unavailable Items” button or click the dismiss button and remove them manually.
        </p>
        <SecondaryTable>
          <SecondaryTableHead>
            <td>Part Number</td>
            <td>Name</td>
          </SecondaryTableHead>
          <SecondaryTableBody>
            {items.map((item) => {
              return (
                <SecondaryTableRow key={item.sequenceNumber}>
                  <td>{item.partNumber}</td>
                  <td>{item.partName}</td>
                </SecondaryTableRow>
              );
            })}
          </SecondaryTableBody>
        </SecondaryTable>
      </>
    );
  };
  return (
    <Modal
      title="This Quote Contains Unavailable Items"
      //@ts-expect-error check how to pass component for content
      content={ModalContent()}
      confirmButtonText="REMOVE UNAVAILABLE ITEMS"
      cancelButtonText="DISMISS"
      isOpen={isOpen}
      onConfirmation={onConfirmation}
      onClose={onClose}
      onDismiss={onClose}
      onCancel={onClose}
      {...props}
    />
  );
};

export { UnavailableItemsModal };
