export default {
  'United States': [
    {
      text: 'AK',
      id: 'AK',
    },
    {
      text: 'AL',
      id: 'AL',
    },
    {
      text: 'AR',
      id: 'AR',
    },
    {
      text: 'AS',
      id: 'AS',
    },
    {
      text: 'AZ',
      id: 'AZ',
    },
    {
      text: 'CA',
      id: 'CA',
    },
    {
      text: 'CO',
      id: 'CO',
    },
    {
      text: 'CT',
      id: 'CT',
    },
    {
      text: 'DC',
      id: 'DC',
    },
    {
      text: 'DE',
      id: 'DE',
    },
    {
      text: 'FL',
      id: 'FL',
    },
    {
      text: 'FM',
      id: 'FM',
    },
    {
      text: 'GA',
      id: 'GA',
    },
    {
      text: 'GU',
      id: 'GU',
    },
    {
      text: 'HI',
      id: 'HI',
    },
    {
      text: 'IA',
      id: 'IA',
    },
    {
      text: 'ID',
      id: 'ID',
    },
    {
      text: 'IL',
      id: 'IL',
    },
    {
      text: 'IN',
      id: 'IN',
    },
    {
      text: 'KS',
      id: 'KS',
    },
    {
      text: 'KY',
      id: 'KY',
    },
    {
      text: 'LA',
      id: 'LA',
    },
    {
      text: 'MA',
      id: 'MA',
    },
    {
      text: 'MD',
      id: 'MD',
    },
    {
      text: 'ME',
      id: 'ME',
    },
    {
      text: 'MH',
      id: 'MH',
    },
    {
      text: 'MI',
      id: 'MI',
    },
    {
      text: 'MN',
      id: 'MN',
    },
    {
      text: 'MO',
      id: 'MO',
    },
    {
      text: 'MP',
      id: 'MP',
    },
    {
      text: 'MS',
      id: 'MS',
    },
    {
      text: 'MT',
      id: 'MT',
    },
    {
      text: 'NC',
      id: 'NC',
    },
    {
      text: 'ND',
      id: 'ND',
    },
    {
      text: 'NE',
      id: 'NE',
    },
    {
      text: 'NH',
      id: 'NH',
    },
    {
      text: 'NJ',
      id: 'NJ',
    },
    {
      text: 'NM',
      id: 'NM',
    },
    {
      text: 'NV',
      id: 'NV',
    },
    {
      text: 'NY',
      id: 'NY',
    },
    {
      text: 'OH',
      id: 'OH',
    },
    {
      text: 'OK',
      id: 'OK',
    },
    {
      text: 'OR',
      id: 'OR',
    },
    {
      text: 'PA',
      id: 'PA',
    },
    {
      text: 'PR',
      id: 'PR',
    },
    {
      text: 'PW',
      id: 'PW',
    },
    {
      text: 'RI',
      id: 'RI',
    },
    {
      text: 'SC',
      id: 'SC',
    },
    {
      text: 'SD',
      id: 'SD',
    },
    {
      text: 'TN',
      id: 'TN',
    },
    {
      text: 'TX',
      id: 'TX',
    },
    {
      text: 'UT',
      id: 'UT',
    },
    {
      text: 'VA',
      id: 'VA',
    },
    {
      text: 'VI',
      id: 'VI',
    },
    {
      text: 'VT',
      id: 'VT',
    },
    {
      text: 'WA',
      id: 'WA',
    },
    {
      text: 'WI',
      id: 'WI',
    },
    {
      text: 'WV',
      id: 'WV',
    },
    {
      text: 'WY',
      id: 'WY',
    },
  ],
  Canada: [
    {
      text: 'AB',
      id: 'AB',
    },
    {
      text: 'BC',
      id: 'BC',
    },
    {
      text: 'MB',
      id: 'MB',
    },
    {
      text: 'NB',
      id: 'NB',
    },
    {
      text: 'NL',
      id: 'NL',
    },
    {
      text: 'NS',
      id: 'NS',
    },
    {
      text: 'NT',
      id: 'NT',
    },
    {
      text: 'NU',
      id: 'NU',
    },
    {
      text: 'ON',
      id: 'ON',
    },
    {
      text: 'PE',
      id: 'PE',
    },
    {
      text: 'QC',
      id: 'QC',
    },
    {
      text: 'SK',
      id: 'SK',
    },
    {
      text: 'YT',
      id: 'YT',
    },
  ],
};
