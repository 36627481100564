import { atom } from 'recoil';

import {
  InfoModalState,
  NotificationState,
  Account,
  AuthenticationState,
  CurrentQuoteState,
  CurrentQuoteValidationState,
  FeedbackCollectedState,
} from 'types';
import {
  defaultAccountState,
  defaultAddressState,
  defaultAuthenticationState,
  defaultCurrentQuoteState,
  defaultCurrentQuoteValidationState,
  defaultFeedbackCollectedState,
  defaultInfoModalState,
  defaultIsLockingQuoteState,
  defaultLoadingState,
  defaultNotificationState,
} from './defaults';

export const authenticationState = atom<AuthenticationState>({
  key: 'authenticationState',
  default: defaultAuthenticationState,
});

export const loadingState = atom<boolean>({
  key: 'loadingState',
  default: defaultLoadingState,
});

export const isLockingQuoteState = atom<boolean>({
  key: 'isLockingQuoteState',
  default: defaultIsLockingQuoteState,
});

export const infoModalState = atom<InfoModalState>({
  key: 'infoModalState',
  default: defaultInfoModalState,
});

export const notificationState = atom<NotificationState>({
  key: 'notificationState',
  default: defaultNotificationState,
});

export const loggedInAccountState = atom<Account>({
  key: 'loggedInAccountState',
  default: defaultAccountState,
});

export const selectedAccountState = atom<Account>({
  key: 'selectedAccountState',
  default: defaultAccountState,
});

export const currentQuoteState = atom<CurrentQuoteState>({
  key: 'currentQuoteState',
  default: defaultCurrentQuoteState,
});

export const currentQuoteValidationState = atom<CurrentQuoteValidationState>({
  key: 'currentQuoteValidationState',
  default: defaultCurrentQuoteValidationState,
});

export const feedbackCollectedState = atom<FeedbackCollectedState>({
  key: 'feedbackCollectedState',
  default: defaultFeedbackCollectedState,
});

export const checkoutAddressesState = atom({
  key: 'checkoutAddressesState',
  default: defaultAddressState,
});
