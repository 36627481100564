import { PrimaryTable as BaseTable } from '@Calix-Commerce/design-system/components';
import { QuoteDetailTableProps } from 'types';
import { QuoteDetailGroup } from './QuoteDetailGroup';
import { TotalRow } from './TotalRow';

export const CheckoutQuoteDetails = ({
  quoteGroups,
  quoteTotalPrice,
  oneTimeDiscountPrice,
}: QuoteDetailTableProps) => {
  return (
    <>
      {quoteGroups.map((quoteGroup) => {
        return (
          <QuoteDetailGroup key={quoteGroup.id + quoteGroup.groupName} groupData={quoteGroup} />
        );
      })}
      <BaseTable>
        <tbody>
          {oneTimeDiscountPrice ? (
            <TotalRow
              backgroundColor="#0B38DB"
              textColor="#fff"
              totalText="One Time Discount"
              price={oneTimeDiscountPrice}
              showContactMessage={false}
            />
          ) : null}
          <TotalRow
            backgroundColor="#0B38DB"
            textColor="#fff"
            totalText="Total"
            price={quoteTotalPrice}
            showContactMessage={false}
          />
        </tbody>
      </BaseTable>
    </>
  );
};
