import bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

export const startBugsnag = () =>
  bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    appVersion: import.meta.env.VITE_VERSION,
    releaseStage: import.meta.env.VITE_ENV,
    networkBreadcrumbsEnabled: true,
    autoCaptureSessions: true,
    plugins: [new BugsnagPluginReact()],
  });
