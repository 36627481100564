import { Flex } from '@Calix-Commerce/design-system';
import styled from '@emotion/styled';

export const TotalContainer = styled(Flex)`
  padding: 10px 15px;
  align-items: flex-end;
  text-align: right;
`;

export const TotalLabel = styled.div`
  flex-grow: 1;
`;

export const TotalPrice = styled.div`
  min-width: 200px;
`;
