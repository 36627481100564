import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  authenticationState,
  currentQuoteValidationState,
  isLockingQuoteState,
  loadingState,
  notificationState,
} from 'state/atoms';
import { getQuoteInputFromQuote, mapCurrentQuoteStateFromResponse } from 'utils/helpers/quote';
import {
  ALTER_QUOTE,
  ALTER_QUOTE_CART,
  CLONE_QUOTE,
  CREATE_QUOTE,
  DELETE_QUOTE,
  EXPORT_QUOTE,
  LOCK_QUOTE,
  REFRESH_QUOTE,
  SUBMIT_ORDER,
  UNLOCK_QUOTE,
} from 'connectors/mutations/quote';
import { GET_QUOTE } from 'connectors/queries/quote';
import { storageCurrentQuoteSelector, storageSelectedAccountSelector } from 'state/selectors';
import { useApolloClient } from '@apollo/client';
import { CurrentQuoteState, CurrentQuoteValidationState, ProductData } from 'types';
import { downloadFile } from 'utils/helpers/file';
import {
  hasOutdatedDiscount,
  hasWritePermission,
  isQuoteUnlocked,
} from 'utils/helpers/quoteValidation';
import { FETCH_POLICY } from 'utils/constants';
import { useAppNavigation, useFormatPrice } from '..';
import {
  alterQuoteMutationUpdate,
  cloneQuoteMutationUpdate,
  createQuoteMutationUpdate,
  deleteQuoteMutationUpdate,
} from 'connectors/mutationUpdates/quote';
import { ErrorMessage } from 'types/Error';
import { PRODUCT_INFO } from 'connectors/fragments/product';
import { SEARCH_PARTS_BY_TERM } from 'connectors/queries/partSearch';
import { useState } from 'react';
import { validateAlterQuote, validateCloneQuote, validateCreateQuote } from './actionValidations';
import { getI18NText } from 'I18N';
import { resetSearchData } from 'utils/helpers/cache';

export function useQuoteActionController() {
  const { email } = useRecoilValue(authenticationState);
  const { accountId } = useRecoilValue(storageSelectedAccountSelector);
  const currentQuoteValidation = useRecoilValue(currentQuoteValidationState);
  const setNotification = useSetRecoilState(notificationState);
  const [currentQuote, setCurrentQuote] = useRecoilState(storageCurrentQuoteSelector);
  const [isQuoteActionInProcess, setIsQuoteActionInProcess] = useState(false);
  const [isLockingQuote, setIsLockingQuote] = useRecoilState(isLockingQuoteState);
  const setLoadingState = useSetRecoilState(loadingState);

  const { redirectToQuoteDetails, redirectToCheckoutWarranty, redirectToCheckoutShipping } =
    useAppNavigation();
  const apolloClient = useApolloClient();
  const formatPrice = useFormatPrice();

  const alterQuote = async ({
    quoteData,
    validationData,
  }: {
    quoteData: CurrentQuoteState;
    validationData: CurrentQuoteValidationState;
  }) => {
    try {
      validateAlterQuote({ validationState: validationData });

      const quoteParameters = { ...quoteData, lockedBy: email, unlock: false };

      const alterQuoteInput = {
        transactionId: quoteParameters.transactionId,
        quote: getQuoteInputFromQuote(quoteParameters),
      };

      setIsQuoteActionInProcess(true);

      const {
        data: { alterQuote },
      } = await apolloClient.mutate({
        mutation: ALTER_QUOTE,
        variables: {
          quoteInput: alterQuoteInput,
        },
        update: alterQuoteMutationUpdate,
      });

      const completeQuoteData = await addQuoteProductData(
        mapCurrentQuoteStateFromResponse(alterQuote, formatPrice)
      );

      setCurrentQuote(completeQuoteData);
      setIsQuoteActionInProcess(false);

      return completeQuoteData;
    } catch (error) {
      setIsQuoteActionInProcess(false);
      throw error;
    }
  };

  const createQuote = async ({
    quoteData,
    validationData,
    redirectToNewQuote = true,
  }: {
    quoteData: CurrentQuoteState;
    validationData: CurrentQuoteValidationState;
    redirectToNewQuote?: boolean;
  }) => {
    try {
      validateCreateQuote({ validationState: validationData });

      const quoteParameters = { ...quoteData, lockedBy: email };
      const mappedQuote = getQuoteInputFromQuote(quoteParameters);

      setIsQuoteActionInProcess(true);
      const {
        data: { createQuote },
      } = await apolloClient.mutate({
        mutation: CREATE_QUOTE,
        variables: {
          quoteInput: { ...mappedQuote, accountId },
        },
        update: createQuoteMutationUpdate,
      });

      const mappedQuoteResponse = await addQuoteProductData(
        mapCurrentQuoteStateFromResponse(createQuote, formatPrice)
      );

      setCurrentQuote(mappedQuoteResponse);

      setIsQuoteActionInProcess(false);

      if (redirectToNewQuote) {
        redirectToQuoteDetails(createQuote.transactionId);
      }

      return mappedQuoteResponse;
    } catch (error) {
      setIsQuoteActionInProcess(false);
      throw error;
    }
  };

  const saveUnsavedChanges = async ({
    quoteData,
    validationData,
  }: {
    quoteData: CurrentQuoteState;
    validationData: CurrentQuoteValidationState;
  }) => {
    if (!validationData.isModified) {
      return quoteData;
    }

    if (!quoteData?.transactionId) {
      return await createQuote({ quoteData, validationData });
    }

    return await alterQuote({ quoteData, validationData });
  };

  const cloneQuote = async ({
    quoteData,
    validationData,
  }: {
    quoteData: CurrentQuoteState;
    validationData: CurrentQuoteValidationState;
  }) => {
    try {
      validateCloneQuote({ validationState: validationData });

      const { transactionId, currencyCode } = quoteData;
      setIsQuoteActionInProcess(true);

      const {
        data: { cloneQuote },
      } = await apolloClient.mutate({
        variables: {
          transactionId,
          currencyCode,
          accountId,
        },
        mutation: CLONE_QUOTE,
        update: cloneQuoteMutationUpdate,
      });

      setIsQuoteActionInProcess(false);
      redirectToQuoteDetails(cloneQuote.transactionId);

      return await addQuoteProductData(mapCurrentQuoteStateFromResponse(cloneQuote, formatPrice));
    } catch (error) {
      setIsQuoteActionInProcess(false);
      throw error;
    }
  };

  const deleteQuote = async (transactionId: string) => {
    try {
      setIsQuoteActionInProcess(true);

      const {
        data: {
          getAccount: { getQuote: responseQuoteData },
        },
      } = await apolloClient.query({
        query: GET_QUOTE,
        variables: {
          transactionId,
          accountId,
        },
        fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
      });

      const { lockedBy, lockExpiry } = responseQuoteData;
      if (isQuoteUnlocked({ lockedBy, lockExpiry }) || lockedBy === email) {
        const response = await apolloClient.mutate({
          variables: {
            transactionId,
            //accountId is not required on this mutation
            //but was added here to allow the mutation update function to work properly
            accountId,
          },
          mutation: DELETE_QUOTE,
          update: deleteQuoteMutationUpdate,
        });
        setIsQuoteActionInProcess(false);
        return response;
      } else {
        setIsQuoteActionInProcess(false);
        throw new Error(ErrorMessage.QUOTE_LOCKED_BY_ANOTHER_USER);
      }
    } catch (error) {
      setIsQuoteActionInProcess(false);
      throw error;
    }
  };

  const submitQuote = async ({
    transactionId,
    quoteData,
  }: {
    transactionId: string;
    quoteData: CurrentQuoteState;
  }) => {
    try {
      setLoadingState(true);

      const {
        data: { alterQuote },
      } = await apolloClient.mutate({
        variables: {
          quoteInput: {
            transactionId,
            quote: {
              currencyCode: quoteData.currencyCode,
              quoteName: quoteData.quoteName,
              quoteDescription: quoteData.quoteDescription,
              lockedBy: quoteData.lockedBy,
              confirmationRecipients: quoteData.confirmationRecipients,
              quoteGroups: [{}],
              lineItems: [],
              shippingAddress: quoteData.shippingAddress,
              shipmentSchedule: quoteData.shipmentSchedule,
              packingInstructions: quoteData.packingInstructions,
              shippingInstructions: quoteData.shippingInstructions,
              requestedShippingType: quoteData.requestedShippingType,
              requestedShippingDate: quoteData.requestedShippingDate,
              multipleShipments: quoteData.multipleShipments,
              shippingContactFirstName: quoteData.shippingContactFirstName,
              shippingContactLastName: quoteData.shippingContactLastName,
              shippingContactPhone: quoteData.shippingContactPhone,
              shippingContactEmail: quoteData.shippingContactEmail,
              billingAddress: quoteData.billingAddress,
              lesingAgent: quoteData.lesingAgent,
              poNumber: quoteData.poNumber,
              submitOrder: true,
            },
          },
        },
        mutation: SUBMIT_ORDER,
        update: alterQuoteMutationUpdate,
      });

      const completeQuoteData = await addQuoteProductData(
        mapCurrentQuoteStateFromResponse(alterQuote, formatPrice)
      );

      setCurrentQuote(completeQuoteData);
      setLoadingState(false);

      Promise.resolve();
    } catch (error) {
      setLoadingState(false);

      setNotification({
        text: getI18NText('CHECKOUT_SUBMIT_QUOTE_ERROR'),
        show: true,
        type: 'error',
        timeout: null,
      });

      throw new Error(error?.toString());
    }
  };

  const lockQuote = async ({
    transactionId,
    currencyCode,
  }: {
    transactionId: string;
    currencyCode: string;
  }) => {
    try {
      setIsLockingQuote(true);
      const {
        data: { alterQuote },
      } = await apolloClient.mutate({
        variables: {
          quoteInput: { transactionId, quote: { lockedBy: email, currencyCode } },
        },
        mutation: LOCK_QUOTE,
      });
      setIsLockingQuote(false);
      return mapCurrentQuoteStateFromResponse(alterQuote, formatPrice);
    } catch (error) {
      setIsLockingQuote(false);
      throw error;
    }
  };

  const unlockQuote = async ({
    transactionId,
    currencyCode,
  }: {
    transactionId: string;
    currencyCode: string;
  }) => {
    try {
      setIsLockingQuote(true);
      const {
        data: { alterQuote },
      } = await apolloClient.mutate({
        variables: {
          quoteInput: { transactionId, quote: { currencyCode, unlock: true } },
        },
        mutation: UNLOCK_QUOTE,
      });
      setIsLockingQuote(false);
      return mapCurrentQuoteStateFromResponse(alterQuote, formatPrice);
    } catch (error) {
      setIsLockingQuote(false);
      throw error;
    }
  };

  const refreshQuote = async (transactionId: string) => {
    try {
      setIsQuoteActionInProcess(true);

      const {
        data: { refreshQuotePricing },
      } = await apolloClient.mutate({
        variables: {
          transactionId,
        },
        mutation: REFRESH_QUOTE,
      });

      const mappedData = mapCurrentQuoteStateFromResponse(refreshQuotePricing, formatPrice);

      const completeData = await addQuoteProductData(mappedData);

      setCurrentQuote(completeData);
      setIsQuoteActionInProcess(false);
      return completeData;
    } catch (error) {
      setIsQuoteActionInProcess(false);
      throw error;
    }
  };

  const addQuoteProductData = async (quoteData: CurrentQuoteState): Promise<CurrentQuoteState> => {
    const partNumbers = Object.keys(
      quoteData.items.reduce(
        (partNumberList, item) => ({
          ...partNumberList,
          ...(item.partNumber ? { [item.partNumber]: '' } : {}),
        }),
        {}
      )
    );

    const { currencyCode } = quoteData;

    let productList = partNumbers.map((partNumber) =>
      getProductDataFromCache(partNumber)
    ) as ProductData[];

    if (productList.some((product) => !product)) {
      const {
        data: { searchPartsByTerm = [] },
      } = await apolloClient.query({
        query: SEARCH_PARTS_BY_TERM,
        variables: {
          input: {
            requestedAccountId: accountId,
            partNumbers,
            limit: partNumbers.length,
            currencyCode,
          },
        },
      });
      productList = searchPartsByTerm as ProductData[];
    }

    const completedQuote = {
      ...quoteData,
      items: quoteData.items.map((item) => {
        const productInfo = productList.find((product) => product.partNumber === item.partNumber);
        const available = !!productInfo;

        const {
          images = [],
          bom: packageBom = [],
          discountType,
          extendedWarrantyEligible = false,
          minimumOrderQuantity = null,
          maximumOrderQuantity = null,
        } = productInfo || ({} as ProductData);

        return {
          ...item,
          images,
          discountType,
          available,
          discountOutdated: hasOutdatedDiscount({ quote: quoteData, quoteItem: item, productList }),
          extendedWarrantyEligible:
            extendedWarrantyEligible ||
            packageBom.some((item) => item.productDetails.extendedWarrantyEligible),
          packageBom,
          minimumOrderQuantity,
          maximumOrderQuantity,
        };
      }),
    };

    return completedQuote;
  };

  const loadQuote = async ({
    transactionId,
    overwriteLocalQuote,
    fetchPolicy = FETCH_POLICY.CACHE_FIRST,
  }) => {
    try {
      setIsQuoteActionInProcess(true);

      if (
        transactionId !== currentQuote.transactionId &&
        currentQuoteValidation.isLockedByCurrentUser
      ) {
        unlockQuote({
          transactionId: currentQuote.transactionId,
          currencyCode: currentQuote.currencyCode,
        }).catch(() => {});
      }

      const {
        data: {
          getAccount: { getQuote: responseQuoteData },
        },
      } = await apolloClient.query({
        query: GET_QUOTE,
        variables: {
          transactionId,
          accountId,
        },
        fetchPolicy,
      });

      const {
        lockedBy: lockedByFromResponse,
        lockExpiry: lockExpiryFromResponse,
        transactionId: transactionIdFromResponse,
        isOrdered: isOrderedFromResponse,
        eCommercePermissions: eCommercePermissionsFromResponse,
        currencyCode,
      } = responseQuoteData;

      const isReplaceableQuoteData =
        overwriteLocalQuote ||
        currentQuote?.transactionId !== transactionIdFromResponse ||
        isOrderedFromResponse ||
        !hasWritePermission(eCommercePermissionsFromResponse);

      const mappedQuoteData = isReplaceableQuoteData
        ? mapCurrentQuoteStateFromResponse(responseQuoteData, formatPrice)
        : currentQuote;

      if (responseQuoteData.currencyCode !== currentQuote.currencyCode) {
        resetSearchData(apolloClient.cache);
      }

      const promisesArray = [addQuoteProductData(mappedQuoteData)];

      const isQuoteLockable =
        !isOrderedFromResponse && hasWritePermission(eCommercePermissionsFromResponse);

      if (
        isQuoteLockable &&
        isQuoteUnlocked({ lockedBy: lockedByFromResponse, lockExpiry: lockExpiryFromResponse })
      ) {
        promisesArray.push(lockQuote({ transactionId, currencyCode }));
      }

      const [completeQuote, lockedQuote] = await Promise.all(promisesArray);

      const { lockedBy, lockExpiry } = lockedQuote || completeQuote;
      const upToDateQuote = {
        ...completeQuote,
        lockedBy,
        lockExpiry,
      };
      setCurrentQuote(upToDateQuote);
      setIsQuoteActionInProcess(false);
      return upToDateQuote;
    } catch (error) {
      setIsQuoteActionInProcess(false);
      throw error;
    }
  };

  const exportQuote = async ({ transactionId, fileType }) => {
    try {
      setIsQuoteActionInProcess(true);
      const input = {
        sectionDisplay: ['EquipmentSummary', 'EquipmentGroups'],
      };

      let savedQuoteTransactionId = '';

      if (currentQuote?.transactionId === transactionId || !transactionId) {
        const savedQuote = await saveUnsavedChanges({
          quoteData: currentQuote,
          validationData: currentQuoteValidation,
        });
        setIsQuoteActionInProcess(true);

        savedQuoteTransactionId = savedQuote.transactionId;
      }

      const {
        data: { generateQuoteDocument },
      } = await apolloClient.mutate({
        variables: {
          transactionId: !transactionId ? savedQuoteTransactionId : transactionId,
          accountId,
          documentFormat: fileType,
          input,
        },
        mutation: EXPORT_QUOTE,
      });

      const { base64: fileData, name: fileName } = generateQuoteDocument;
      downloadFile({
        fileName,
        fileData,
        type: fileType,
      });
      setIsQuoteActionInProcess(false);
    } catch (error) {
      setIsQuoteActionInProcess(false);
      throw error;
    }
  };

  const alterArchivingStatus = async ({ transactionId, archived }) => {
    try {
      setIsQuoteActionInProcess(true);
      const {
        data: {
          getAccount: { getQuote: responseQuoteData },
        },
      } = await apolloClient.query({
        query: GET_QUOTE,
        variables: {
          transactionId,
          accountId,
        },
        fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
      });

      const { lockedBy, lockExpiry, currencyCode } = responseQuoteData;
      if (isQuoteUnlocked({ lockedBy, lockExpiry }) || lockedBy === email) {
        const {
          data: { alterQuote },
        } = await apolloClient.mutate({
          variables: {
            quoteInput: {
              transactionId,
              quote: { lockedBy, currencyCode, archived },
            },
          },
          mutation: ALTER_QUOTE_CART,
        });

        const completeQuote = await addQuoteProductData(
          mapCurrentQuoteStateFromResponse(alterQuote, formatPrice)
        );

        setIsQuoteActionInProcess(false);
        return completeQuote;
      } else {
        setIsQuoteActionInProcess(false);
        throw new Error(ErrorMessage.QUOTE_LOCKED_BY_ANOTHER_USER);
      }
    } catch (error) {
      setIsQuoteActionInProcess(false);
      throw error;
    }
  };

  const getProductDataFromCache = (partNumber) =>
    apolloClient.readFragment({
      id: `SearchPartsResponse:{"partNumber":"${partNumber}"}`,
      fragment: PRODUCT_INFO,
    });

  const startCheckout = async ({
    quoteData,
    validationData,
  }: {
    quoteData: CurrentQuoteState;
    validationData: CurrentQuoteValidationState;
  }) => {
    try {
      const currentQuote = await saveUnsavedChanges({ quoteData, validationData });
      setIsQuoteActionInProcess(true);

      const { isEmpty, hasCurrencyMismatch, allItemsQuotable, hasUnavailableItems } =
        validationData;

      if (isEmpty) {
        throw new Error(ErrorMessage.EMPTY_QUOTE);
      }
      if (hasCurrencyMismatch) {
        throw new Error(ErrorMessage.HAS_CURRENCY_MISMATCH);
      }
      if (!allItemsQuotable) {
        throw new Error(ErrorMessage.HAS_NON_QUOTABLE_ITEM);
      }
      if (hasUnavailableItems) {
        throw new Error(ErrorMessage.HAS_NON_AVAILABLE_ITEM);
      }
      if (!currentQuote.commerceComplete) {
        throw new Error(ErrorMessage.QUOTE_NOT_READY_FOR_CHECKOUT);
      }

      if (
        currentQuote.requiresWarrantyConfiguration &&
        currentQuote.items.some((currentQuoteItem) => currentQuoteItem.extendedWarrantyEligible)
      ) {
        redirectToCheckoutWarranty(currentQuote.transactionId);
      } else {
        redirectToCheckoutShipping(currentQuote.transactionId);
      }

      setIsQuoteActionInProcess(false);
      return currentQuote;
    } catch (error) {
      setIsQuoteActionInProcess(false);
      throw error;
    }
  };

  return {
    addQuoteProductData,
    alterArchivingStatus,
    alterQuote,
    createQuote,
    cloneQuote,
    deleteQuote,
    submitQuote,
    lockQuote,
    unlockQuote,
    loadQuote,
    refreshQuote,
    exportQuote,
    startCheckout,
    isLockingQuote,
    isQuoteActionInProcess,
  };
}
