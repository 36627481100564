import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { authenticationState } from 'state/atoms';
import { useAppNavigation } from 'utils/hooks';

const withCheckoutPermissions = (CheckoutPage: React.ComponentType) => {
  const WrappedComponent = () => {
    const { redirectToQuoteDetails } = useAppNavigation();
    const authentication = useRecoilValue(authenticationState);

    useEffect(() => {
      if (!authentication.ecomCheckout) {
        redirectToQuoteDetails();
      }
    }, [authentication, redirectToQuoteDetails]);

    return <CheckoutPage />;
  };

  return WrappedComponent;
};

export { withCheckoutPermissions };
