import { Container, Flex } from '@Calix-Commerce/design-system/layout';
import styled from '@emotion/styled';

export const ShipmentSchedulerContainer = styled.div`
  .MuiButtonBase-root {
    & .add-calendar {
      width: 31px;
      height: 26px;
      margin-right: 8px;

      .cls-1 {
        fill: #ffffff;
      }
    }
  }

  .remove-scheduler-group {
    margin-left: calc(50% - 12px);
    cursor: pointer;
  }
`;

export const ShippingFormSubHeader = styled.h1`
  font-size: 1rem;
  font-weight: bold;
  margin-top: 2px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

export const ShipmentInfoParagraph = styled.p`
  font-size: 0.9rem;
  font-weight: normal;
  margin-top: 5px;
  line-height: 150%;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

export const AdditionalShippingInfoWarning = styled.p`
  max-width: 750px;
  min-width: 470px;
  padding: 15px;
  font-size: 1rem;
  font-weight: normal;
  line-height: 150%;
  color: #8a6d3b;
  background-color: #fcf8e3;
  border: 1px solid #faebcc;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

  & a {
    color: #195d8d;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const AdditionalShippingInstructionRow = styled(Flex)`
  margin-top: 22px;
  max-width: 750px;
  min-width: 470px;

  & .instruction-label {
    width: 25%;
    font-size: 1rem;
    color: #333333;
    font-weight: bold;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  & .instruction-data {
    width: 75%;
    display: flex;
    flex-direction: column;

    & .pkg-instructions {
      width: 100%;
      height: 34px;
    }

    & .remaining-character {
      margin-top: 7px;
      color: #808080;
      font-size: 0.9rem;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }

    & .instruction-info {
      margin-top: 10px;
      color: #333333;
      font-size: 0.9rem;
      line-height: 150%;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
  }
`;

export const ShipmentContactInfoParagraph = styled.p`
  font-size: 1rem;
  font-weight: normal;
  margin-top: 0px;
  line-height: 150%;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

export const ContactContainer = styled(Container)`
  max-width: 750px;
  min-width: 470px;
  margin: 0;
`;
