import { gql } from '@apollo/client';

export const PRODUCT_INFO = gql`
  fragment productInfo on SearchPartsResponse {
    description
    listPrice
    discountTotal
    extendedWarrantyEligible
    sellPrice
    priceLine
    partName
    partNumber
    discountType
    customSku
    maximumOrderQuantity
    minimumOrderQuantity
    images {
      imageAlt
      imageAbsoluteUrl
    }
    description
    bom {
      partNumber
      productDetails {
        description
        extendedWarrantyEligible
      }
      quantity
    }
  }
`;
