import {
  ExtendedWarrantyOutput,
  getWarrantyConfigStateFromQuote,
} from '@Calix-Commerce/extended-warranty-component';
import { useCallback, useMemo, useState } from 'react';
import { useWarrantyRequest } from './useWarrantyRequest';
import { roundPrice } from 'utils/helpers/price';
import { CurrentQuoteState } from 'types';
import { useRecoilValue } from 'recoil';
import { authenticationState } from 'state/atoms';

type UseWarrantyControllerProps = {
  quote: CurrentQuoteState;
};

export function useWarrantyController({ quote }: UseWarrantyControllerProps) {
  const { email } = useRecoilValue(authenticationState);
  const { addExternalConfiguration, updateExternalConfiguration } = useWarrantyRequest();
  const [configState, setConfigState] = useState(() => getWarrantyConfigStateFromQuote(quote));

  const totalWithoutExtendedWarranty = useMemo(
    () => roundPrice(quote.total - quote.warrantyTotal),
    [quote.total, quote.warrantyTotal]
  );

  const warrantyInput = useMemo(
    () =>
      quote.warrantyInput.map((input) => ({
        ...input,
        warrantyOptions: [{ duration: 0, price: 0 }, ...input.warrantyOptions],
      })),
    [quote.warrantyInput]
  );

  const disableWarrantyStep = useMemo(() => warrantyInput.length === 0, [warrantyInput]);

  const getWarrantyLine = useCallback((quote: CurrentQuoteState) => {
    return quote.items.find((line) => line.modelName === 'Warranty');
  }, []);

  const handleWarrantyChange = useCallback((newValue: ExtendedWarrantyOutput) => {
    setConfigState((warranty) => ({ ...warranty, ...newValue }));
  }, []);

  const handleWarrantySubmit = useCallback(() => {
    const warrantyLine = getWarrantyLine(quote);
    const input = {
      configurator: 'Warranty',
      transactionId: quote.transactionId,
      configState: JSON.stringify(configState),
    } as {
      configurator: string;
      transactionId: string;
      configState: string;
      lockedBy?: string;
      headerLineNumber?: number;
    };

    let request = addExternalConfiguration;

    if (warrantyLine) {
      input.headerLineNumber = warrantyLine.documentNumber;
      input.lockedBy = email;
      request = updateExternalConfiguration;
    }

    return request({ variables: { input } });
  }, [
    quote,
    email,
    configState,
    getWarrantyLine,
    addExternalConfiguration,
    updateExternalConfiguration,
  ]);

  return {
    configState,
    warrantyInput,
    totalWithoutExtendedWarranty,
    currencyCode: quote.currencyCode,
    handleWarrantyChange,
    handleWarrantySubmit,
    disableWarrantyStep,
  };
}
