import { useState } from 'react';
import Collapse from '@mui/material/Collapse';

import { ProductImage } from '@Calix-Commerce/design-system/components';
import { noImageSrc } from '@Calix-Commerce/design-system/assets';

import { AddToQuote } from 'pages/PartSearch/subcomponents/AddToQuote';
import {
  PART_SEARCH_RESULT_CLASS,
  PART_SEARCH_PRODUCT_DETAILS_CLASS,
} from 'utils/constants/selectors';

import {
  Product as ProductStyled,
  ProductInfoBlock,
  ProductTitle,
  ProductNotes,
  ProductDetailsButton,
  BomListTable,
} from './styledComponents';
import { PartSearchResponse } from 'types/state/Part';

const getProductNotesList = ({ productData }: { productData: PartSearchResponse }) => {
  const hasWarrantyEligibleItems = productData.bom.some(
    ({ productDetails }) => productDetails.extendedWarrantyEligible
  );

  const notes: string[] = [];

  if (productData.customSku) {
    notes.push('This item is a custom SKU');
  }

  if (productData.extendedWarrantyEligible) {
    notes.push('This item is extended warranty eligible');
  }

  if (hasWarrantyEligibleItems) {
    notes.push('This package contains one or more items that are extended warranty eligible');
  }

  return notes;
};

const Product = ({
  productData = null,
  isBackgroundLoading = false,
}: {
  productData: PartSearchResponse | null;
  isBackgroundLoading: boolean;
}) => {
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false);

  if (!productData) {
    return null;
  }

  const { partNumber, partName, description, images, bom = [] } = productData;

  const { imageAbsoluteUrl, imageAlt } = images?.at(0) ?? { imageAbsoluteUrl: '', imageAlt: '' };

  const isPackage = partNumber.startsWith('0');
  const productNotes = getProductNotesList({ productData });

  const renderExpandedDetails = () => {
    return bom.length ? (
      <BomListTable>
        <thead>
          <tr>
            <th>Part</th>
            <th>Description</th>
            <th>Qty</th>
          </tr>
        </thead>
        <tbody>
          {bom.map((item) => (
            <tr key={item.partNumber}>
              <td style={{ whiteSpace: 'nowrap' }}>{item.partNumber}</td>
              <td>{item.productDetails?.description}</td>
              <td>{item.quantity}</td>
            </tr>
          ))}
        </tbody>
      </BomListTable>
    ) : null;
  };

  return (
    <ProductStyled className={PART_SEARCH_RESULT_CLASS}>
      <div style={{ width: '20%' }}>
        <ProductImage
          src={imageAbsoluteUrl}
          fallbackSrc={noImageSrc}
          alt={imageAlt}
          style={{ maxWidth: '100%' }}
        />
      </div>
      <ProductInfoBlock>
        <p>{partNumber}</p>
        <ProductTitle>{partName}</ProductTitle>
        <p style={{ lineHeight: '150%' }}>{description}</p>
        <ProductNotes>
          {productNotes.map((note) => (
            <li key={note}>{note}</li>
          ))}
        </ProductNotes>
        {isPackage && (
          <ProductDetailsButton
            onClick={() => setIsDetailsExpanded(!isDetailsExpanded)}
            className={PART_SEARCH_PRODUCT_DETAILS_CLASS}
          >
            {isDetailsExpanded ? '-' : '+'} Details
          </ProductDetailsButton>
        )}
        <Collapse in={isDetailsExpanded}>{renderExpandedDetails()}</Collapse>
      </ProductInfoBlock>
      <AddToQuote product={productData} isBackgroundLoading={isBackgroundLoading} />
    </ProductStyled>
  );
};

export { Product };
