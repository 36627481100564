import styled from '@emotion/styled';

export const Container = styled.div`
  padding: 10px;
  max-width: 400px;
  margin: auto;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-color: #ededed;
`;

export const EmojiList = styled.ul`
  font-family: ${({ theme }) => theme.fonts.emoji};
  display: flex;
  list-style: none;
  margin: 0;
  padding: 5px 4px 10px 2px;
  li {
    display: block;
    width: 18px;
    margin: 0 2px;
    font-size: 18px;
    cursor: pointer;
    transition:
      width 0.3s,
      height 0s;
  }
  .hide-emoji {
    width: 0px;
    opacity: 0;
    margin: 0;
  }
  .show-emoji {
    width: auto;
    opacity: 1;
    margin: 0 2px;
  }
`;

export const EmojiText = styled.div`
  background: #ffffff;
  padding: 5px;
  border-radius: 5px;
  margin-left: 10px;
  line-height: 0;
  text-align: left;
  height: 20px;
  display: flex;
  align-items: center;
  margin-top: 6px;
`;

export const FeedbackComment = styled.textarea`
  width: 100%;
  border-radius: 5px;
  height: 70px;
  font-size: 12px;
  padding: 4px 8px;
  &::placeholder {
    color: #cecbcb;
  }
`;

export const FeedbackConfirmationHeading = styled.p`
  font-size: 16px;
  margin-top: 40px;
`;
