import { useRecoilValue } from 'recoil';

import {
  QUOTE,
  ORDER_MANAGER,
  RMA_MANAGER_USER,
  RMA_MANAGER_MANAGER,
} from 'connectors/permissions';
import { authenticationState } from 'state/atoms';

export const useUserPermissions = () => {
  const { eCommerceAccess } = useRecoilValue(authenticationState);

  return {
    hasQuoteAccess: eCommerceAccess.indexOf(QUOTE) !== -1,
    hasOrderAccess: eCommerceAccess.indexOf(ORDER_MANAGER) !== -1,
    hasRmaAccess: eCommerceAccess.some(
      (access) => access === RMA_MANAGER_USER || access === RMA_MANAGER_MANAGER
    ),
  };
};
