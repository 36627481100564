import { useEffect } from 'react';
import { getSavedCheckoutData } from 'utils/helpers/checkout';
import { useAppNavigation } from 'utils/hooks';
import { useParams } from 'react-router-dom';
import { validateCheckoutBilling } from 'pages/CheckoutBilling/CheckoutBillingValidations';

export const withCheckoutBillingConfigured = (CheckoutPage: React.ComponentType) => {
  const WrappedComponent = () => {
    const { transactionId } = useParams();
    const { redirectToCheckoutBilling } = useAppNavigation();
    const { billingAddress, poNumber } = getSavedCheckoutData(transactionId);

    useEffect(() => {
      const { isAllDataValid } = validateCheckoutBilling({
        billingAddress,
        poNumber,
      });

      if (!isAllDataValid) {
        redirectToCheckoutBilling();
      }
    }, [billingAddress, poNumber, redirectToCheckoutBilling]);

    return <CheckoutPage />;
  };

  return WrappedComponent;
};
