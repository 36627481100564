import { useEffect, useRef, useCallback, ReactElement } from 'react';
import { useRecoilState } from 'recoil';

import { Notification as BaseNotification } from '@Calix-Commerce/design-system/components';

import { notificationState } from 'state/atoms';

const Notification = (): ReactElement => {
  const [notification, setNotification] = useRecoilState(notificationState);
  const resetTimeout = useRef<NodeJS.Timeout | null>(null);

  const { show, text, type, timeout } = notification;

  const closeNotification = useCallback(
    () => setNotification({ ...notification, timeout: null, show: false }),
    [notification, setNotification]
  );

  useEffect(() => {
    if (timeout) {
      resetTimeout.current = setTimeout(closeNotification, timeout);
    } else if (resetTimeout.current) {
      clearTimeout(resetTimeout.current);
    }
  }, [closeNotification, timeout]);

  return (
    <BaseNotification show={show} type={type} onClose={closeNotification}>
      {text}
    </BaseNotification>
  );
};

export { Notification };
