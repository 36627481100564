import styled from '@emotion/styled';

export const TagsWrapper = styled.div`
  margin: 10px 0;
`;

export const TagsListWrapper = styled.div`
  margin: 5px 0 10px;
`;

export const TagForm = styled.form`
  display: flex;
  position: relative;
  alignitems: center;
  width: fit-content;
`;

export const TagInput = styled.input`
  background: none;
  padding: 3px 24px 3px 15px;
  width: 240px;
  border: 1px solid #ccc;
  height: 32px;
`;

export const Tag = styled.div`
  position: relative;
  display: inline-block;
  line-height: 30px;
  color: #333;
  padding: 0 7px;
  background-color: #dee7f8;
  border: 1px solid #cad8f3;
  border-radius: 4px;
  margin: 0 10px 0 0;
  cursor: pointer;

  &:hover {
    color: #ff7600;
  }
`;

export const TagListItem = styled.li`
  padding: 1rem;
  list-style: none;
  font-size: 1rem;

  &:hover {
    background: #e0e0e0;
  }
`;

export const TagSubmitButton = styled.button`
  display: flex;
  position: absolute;
  justify-content: flex-end;
  top: 4px;
  right: 0;
  background: none;
  border: none;
  cursor: pointer;
`;
