import { useState } from 'react';

import {
  filterUniqueAccountsByIdAndName,
  filterUniqueAccountsByParam,
} from 'utils/helpers/account';

const DEFAULT_DUPLICATED_ACCOUNTS = { byId: [], byName: [] };

export const useAccountResults = () => {
  const [allAccounts, setAllAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [duplicatedAccounts, setDuplicatedAccounts] = useState(DEFAULT_DUPLICATED_ACCOUNTS);

  const addAccounts = (accounts = []) => {
    const accountsToBeFiltered = [...filteredAccounts, ...accounts];

    const { filtered: filteredIdAccounts, duplicated: duplicatedIdAccounts } =
      filterUniqueAccountsByIdAndName(accountsToBeFiltered);

    const { filtered: filteredNameAccounts, duplicated: duplicatedNameAccounts } =
      filterUniqueAccountsByParam(filteredIdAccounts, 'name');

    setDuplicatedAccounts({
      byName: [...duplicatedAccounts.byName, ...duplicatedNameAccounts],
      byId: [...duplicatedAccounts.byId, ...duplicatedIdAccounts],
    });
    setFilteredAccounts(filteredNameAccounts);
    setAllAccounts([...allAccounts, ...accounts]);
  };

  const resetAccounts = () => {
    setFilteredAccounts([]);
    setAllAccounts([]);
    setDuplicatedAccounts(DEFAULT_DUPLICATED_ACCOUNTS);
  };

  return {
    addAccounts,
    resetAccounts,
    duplicatedAccounts,
    filteredAccounts,
    allAccounts,
  };
};
