import { useState, useEffect, useRef } from 'react';
import Fade from '@mui/material/Fade';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';

import { ButtonLink } from '@Calix-Commerce/design-system/components';
import { SuccessMessageBlock } from './styledComponents';
import { useAppNavigation } from 'utils/hooks';

const SuccessMessage = ({
  onFinish = () => null,
  messageDuration = 4,
  fadeOutDuration = 3,
}: {
  onFinish?: () => void;
  messageDuration?: number;
  fadeOutDuration?: number;
}) => {
  const messageTimeout = useRef<null | NodeJS.Timeout>(null);
  const [showMessage, setShowMessage] = useState(true);
  const [isHovering, setIsHovering] = useState(false);
  const { redirectToQuoteDetailsForNew, redirectToQuoteDetails } = useAppNavigation();

  const { transactionId } = useParams();

  // Keep message on screen as long as it is being hovered
  const refreshMessage = () => {
    messageTimeout.current && clearTimeout(messageTimeout.current);
    messageTimeout.current = setTimeout(() => {
      if (isHovering) {
        refreshMessage();
      } else {
        setShowMessage(false);
      }
    }, messageDuration * 1000);
  };

  useEffect(() => {
    if (showMessage || isHovering) {
      refreshMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMessage, isHovering]);

  return (
    <div
      style={{ marginTop: '1rem' }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Fade
        in={showMessage || isHovering}
        timeout={{ enter: 1000, exit: fadeOutDuration * 1000 }}
        onExited={() => {
          messageTimeout.current && clearTimeout(messageTimeout.current);
          onFinish();
        }}
      >
        <SuccessMessageBlock alignment="center">
          <FontAwesomeIcon icon={faCheck as IconProp} size="lg" />
          Added <span style={{ margin: '0 0.5rem' }}>|</span>
          <ButtonLink
            onClick={() =>
              transactionId ? redirectToQuoteDetails(transactionId) : redirectToQuoteDetailsForNew()
            }
          >
            View Quote Detail
          </ButtonLink>
        </SuccessMessageBlock>
      </Fade>
    </div>
  );
};

export { SuccessMessage };
