import { useState, useMemo, ReactNode } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { InView } from 'react-intersection-observer';
import { currentQuoteState, currentQuoteValidationState, loadingState } from 'state/atoms';
import {
  DotPulseLoader,
  Tabs,
  Tab,
  TabLabel,
  TabBadge,
  Modal,
} from '@Calix-Commerce/design-system/components';
import { Flex } from '@Calix-Commerce/design-system/layout';
import { applyFilters, ACTIVE_FILTER, ARCHIVED_FILTER } from 'pages/Quotes/filters';
import { NoQuotesMessage, NoFilterResultsMessage, NoMoreResultsMessage } from './TableMessages';
import {
  QUOTE_SEARCH_ACTIVE_TAB_ID,
  QUOTE_SEARCH_ARCHIVE_TAB_ID,
  QUOTE_SEARCH_TABLE_ID,
} from 'utils/constants/selectors';
import { BlinkTransition } from 'components';
import { useQuoteActionController, useQuoteController } from 'utils/hooks';
import { ConfirmationModal } from './ConfirmationModal';
import { ActionStatus } from './ActionStatus';
import { ResultRow } from './ResultRow';
import { Table } from './styledComponents';
import { Quote } from 'types';
import { ErrorMessage } from 'types/Error';
import dayjs from 'dayjs';
import { FileType } from 'types/FileType';

const INITIAL_ITEMS = 20;
const SCROLL_INCREMENT_ITEMS = 10;

type ConfirmationStatus = {
  action: 'delete' | 'archive' | 'unarchive' | null;
  transactionId: string | null;
};

type ActionStatus = {
  action: 'delete' | 'archive' | 'unarchive' | 'export' | null;
  type: 'success' | 'error';
  show: boolean;
  message?: string;
};

const DEFAULT_ACTION_STATUS: ActionStatus = {
  action: null,
  type: 'error',
  show: false,
};

const DEFAULT_CONFIRMATION_STATUS: ConfirmationStatus = {
  action: null,
  transactionId: null,
};

export const ResultsTable = ({
  allItems = [],
  filteredItems = [],
  loading = false,
  tabFilter = ACTIVE_FILTER,
  onTabSelect = () => null,
  clearAllFilters = () => null,
  resultsTransitionKey = '',
  onActionCompleted,
}: {
  allItems: Quote[];
  filteredItems: Quote[];
  loading: boolean;
  tabFilter?: 'active' | 'archived';
  onTabSelect: (tab: 'active' | 'archived') => void;
  clearAllFilters: () => void;
  resultsTransitionKey: string;
  onActionCompleted: () => void;
}): ReactNode => {
  const currentQuote = useRecoilValue(currentQuoteState);
  const setLoading = useSetRecoilState(loadingState);
  const { isModified } = useRecoilValue(currentQuoteValidationState);
  const [maxVisibleItems, setMaxVisibleItems] = useState(INITIAL_ITEMS);
  const { deleteQuote, alterArchivingStatus, exportQuote, isQuoteActionInProcess } =
    useQuoteActionController();
  const [actionStatus, setActionStatus] = useState(DEFAULT_ACTION_STATUS);
  const [isCurrentQuoteLocked, setIsCurrentQuoteLocked] = useState(false);
  const [confirmationStatus, setConfirmationStatus] = useState<ConfirmationStatus>(
    DEFAULT_CONFIRMATION_STATUS
  );
  const { createFirstQuote } = useQuoteController();
  const resetConfirmationStatus = () => setConfirmationStatus(DEFAULT_CONFIRMATION_STATUS);
  const resetActionStatus = () => setActionStatus(DEFAULT_ACTION_STATUS);
  const resetIsCurrentQuoteLocked = () => setIsCurrentQuoteLocked(false);
  const { action, transactionId } = confirmationStatus;

  const currentTabItems = useMemo(
    () => applyFilters({ items: filteredItems, tabFilter }),
    [filteredItems, tabFilter]
  );
  const visibleItems = currentTabItems.slice(0, maxVisibleItems);

  // Number of items not visible on current tab
  const remainingItemsCount = filteredItems.length - currentTabItems.length;
  const isActiveTab = tabFilter === ACTIVE_FILTER;

  const renderNoResultsMessage = () => {
    const isEndOfList = visibleItems.length === currentTabItems.length;

    if (!allItems.length) {
      return <NoQuotesMessage createQuote={createFirstQuote} />;
    } else if (!currentTabItems.length) {
      return <NoFilterResultsMessage clearAllFilters={clearAllFilters} />;
    } else if (isEndOfList) {
      return <NoMoreResultsMessage />;
    } else return null;
  };

  const handleTabChange = (newTab) => {
    setMaxVisibleItems(INITIAL_ITEMS); //Reset visible items for performance
    onTabSelect(newTab);
  };

  const handleEndOfList = () => {
    const hasHiddenItems = currentTabItems.length > visibleItems.length;
    if (hasHiddenItems) {
      setMaxVisibleItems(maxVisibleItems + SCROLL_INCREMENT_ITEMS);
    }
  };

  const handleDeleteQuote = () => {
    setLoading(true);
    transactionId &&
      deleteQuote(transactionId)
        .then((data) => {
          if (data) {
            onActionCompleted();
            setLoading(false);
            setActionStatus({ type: 'success', action: 'delete', show: true });
            resetConfirmationStatus();
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err.message === ErrorMessage.QUOTE_LOCKED_BY_ANOTHER_USER) {
            setIsCurrentQuoteLocked(true);
          } else {
            setActionStatus({
              type: 'error',
              action: 'delete',
              message: 'Something went wrong deleting the quote.',
              show: true,
            });
          }
          resetConfirmationStatus();
        });
  };

  const handleAlterArchiveQuote = ({ archived }: { archived: boolean }) => {
    setLoading(true);
    transactionId &&
      alterArchivingStatus({ transactionId, archived: archived })
        .then(() => {
          onActionCompleted();
          setLoading(false);
          setActionStatus({
            type: 'success',
            action: archived ? 'archive' : 'unarchive',
            show: true,
          });
          resetConfirmationStatus();
        })
        .catch((err) => {
          setLoading(false);
          if (err.message === ErrorMessage.QUOTE_LOCKED_BY_ANOTHER_USER) {
            setIsCurrentQuoteLocked(true);
          } else {
            setActionStatus({
              type: 'error',
              action: archived ? 'archive' : 'unarchive',
              message: `Something went wrong ${archived ? 'archiving' : 'unarchiving'} the quote`,
              show: true,
            });
          }
          resetConfirmationStatus();
        });
  };

  const handleExportQuote = (transactionId: string, fileType: FileType) => {
    setLoading(true);
    exportQuote({ transactionId, fileType })
      .then(() => {
        setLoading(false);
        setActionStatus({ type: 'success', action: 'export', show: true });
        resetConfirmationStatus();
      })
      .catch(() => {
        setLoading(false);
        setActionStatus({
          type: 'error',
          action: 'export',
          message: 'Something went wrong exporting the quote.',
          show: true,
        });
        resetConfirmationStatus();
      });
  };

  const confirmQuoteAction: { [k in 'delete' | 'archive' | 'unarchive']: () => void } = {
    delete: handleDeleteQuote,
    archive: () => handleAlterArchiveQuote({ archived: true }),
    unarchive: () => handleAlterArchiveQuote({ archived: false }),
  };

  const handleConfirmQuoteAction = action && transactionId ? confirmQuoteAction[action] : null;
  const showConfirmation = action && transactionId && !isQuoteActionInProcess;
  const isCurrentQuoteBeingModified = isModified && currentQuote.transactionId === transactionId;

  return (
    <>
      {showConfirmation && (
        <ConfirmationModal
          action={action}
          showWarning={isCurrentQuoteBeingModified}
          onCancel={resetConfirmationStatus}
          onConfirm={handleConfirmQuoteAction}
          isLoading={isQuoteActionInProcess}
        />
      )}
      <Modal
        isOpen={isCurrentQuoteLocked}
        title="Locked Quote"
        content="This quote is locked, you cannot update it."
        onConfirmation={null}
        onDismiss={resetIsCurrentQuoteLocked}
        onCancel={resetIsCurrentQuoteLocked}
        onClose={resetIsCurrentQuoteLocked}
      />
      <ActionStatus {...actionStatus} onClose={resetActionStatus} />
      <div style={{ marginTop: '1.5rem' }}>
        <Tabs>
          <Tab
            id={QUOTE_SEARCH_ACTIVE_TAB_ID}
            selected={isActiveTab}
            onClick={() => handleTabChange(ACTIVE_FILTER)}
          >
            <TabLabel>ACTIVE QUOTES</TabLabel>
            <TabBadge>{isActiveTab ? currentTabItems.length : remainingItemsCount}</TabBadge>
          </Tab>
          <Tab
            id={QUOTE_SEARCH_ARCHIVE_TAB_ID}
            selected={!isActiveTab}
            onClick={() => handleTabChange(ARCHIVED_FILTER)}
          >
            <TabLabel>ARCHIVE</TabLabel>
            <TabBadge>{!isActiveTab ? currentTabItems.length : remainingItemsCount}</TabBadge>
          </Tab>
        </Tabs>
        <BlinkTransition transitionKey={resultsTransitionKey}>
          <Table id={QUOTE_SEARCH_TABLE_ID} style={{ width: '100%' }}>
            <thead style={{ height: '64px' }}>
              <tr>
                <th style={{ width: '30%' }}>Name</th>
                <th style={{ width: '14%' }}>ID</th>
                <th style={{ width: '10%' }}>Description</th>
                <th style={{ width: '12%' }}>Created By</th>
                <th style={{ width: '12%' }}>Last Modified ({dayjs().format('Z')})</th>
                <th style={{ width: '12%' }}>Total</th>
                <th style={{ width: '10%' }} />
              </tr>
            </thead>
            <tbody>
              {visibleItems.map((item, idx) => (
                <ResultRow
                  idx={idx}
                  key={item.transactionId}
                  item={item}
                  isModified={isCurrentQuoteBeingModified}
                  onUnarchive={() =>
                    setConfirmationStatus({
                      action: 'unarchive',
                      transactionId: item.transactionId,
                    })
                  }
                  onArchive={() =>
                    setConfirmationStatus({
                      action: 'archive',
                      transactionId: item.transactionId,
                    })
                  }
                  onDelete={() => {
                    setConfirmationStatus({
                      action: 'delete',
                      transactionId: item.transactionId,
                    });
                  }}
                  onExport={handleExportQuote}
                />
              ))}
            </tbody>
          </Table>
        </BlinkTransition>
        <InView as="div" rootMargin="400px" onChange={(inView) => inView && handleEndOfList()} />
        {loading ? (
          <Flex spacing="center">
            <DotPulseLoader />
          </Flex>
        ) : (
          renderNoResultsMessage()
        )}
      </div>
    </>
  );
};
