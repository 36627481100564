import { useCallback, useState } from 'react';
import { Zoom, ClickAwayListener } from '@mui/material';

import { ButtonSlim, ButtonLink } from '@Calix-Commerce/design-system';

import { AddMenu, AddMenuHeader, AddMenuFooter } from './styledComponents';

const AddGroupPopup = ({
  show = false,
  onConfirm,
  onClose,
  expandDuration = 0.3,
}: {
  show: boolean;
  onConfirm: (groupName: string) => void;
  onClose: () => void;
  expandDuration: number;
}) => {
  const [newGroupName, setNewGroupName] = useState('');

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewGroupName(event.target.value);
    },
    [setNewGroupName]
  );

  const handleClose = useCallback(() => {
    setNewGroupName('');
    onClose();
  }, [onClose]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    },
    [handleClose]
  );

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      if (newGroupName && newGroupName.trim()) {
        onConfirm(newGroupName);
        setNewGroupName('');
      }
    },
    [newGroupName, onConfirm]
  );

  return (
    <div style={{ position: 'absolute', zIndex: 1000 }}>
      <Zoom
        in={show}
        timeout={expandDuration * 1000}
        style={{ transformOrigin: '0 0 0' }}
        unmountOnExit
      >
        <div>
          <ClickAwayListener onClickAway={handleClose}>
            <AddMenu>
              <AddMenuHeader alignment="center" spacing="space-between">
                <span>+ Add a Group in Quote</span>
                <ButtonLink onClick={handleClose}>X</ButtonLink>
              </AddMenuHeader>
              <form onSubmit={handleSubmit}>
                <div style={{ padding: '0.8rem 0' }}>
                  <input
                    type="text"
                    placeholder="New Group Name"
                    value={newGroupName}
                    autoFocus={true}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    style={{ width: '200px', marginLeft: '.71rem', padding: '5px 0 5px 3px' }}
                  />
                </div>
                <AddMenuFooter spacing="space-between">
                  <ButtonSlim
                    type="submit"
                    disabled={!newGroupName}
                    onClick={handleSubmit}
                    style={{ width: '100px' }}
                  >
                    SAVE
                  </ButtonSlim>
                  <ButtonSlim
                    type="submit"
                    onClick={handleClose}
                    style={{ width: '100px', background: 'none', color: '#0b38db' }}
                  >
                    CANCEL
                  </ButtonSlim>
                </AddMenuFooter>
              </form>
            </AddMenu>
          </ClickAwayListener>
        </div>
      </Zoom>
    </div>
  );
};

export { AddGroupPopup };
