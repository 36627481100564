import { Notification } from '@Calix-Commerce/design-system/components';
import { useEffect } from 'react';

type StatusMessage = {
  [k in 'delete' | 'archive' | 'unarchive' | 'export']: {
    successMessage: string;
    errorMessage: string;
  };
};

const statusMessage: StatusMessage = {
  archive: {
    successMessage: 'Quote archived with success',
    errorMessage: 'Something went wrong in archiving the quote',
  },
  delete: {
    successMessage: 'Quote deleted with success',
    errorMessage: 'Something went wrong in deleting the quote',
  },
  export: {
    successMessage: 'Quote exported with success',
    errorMessage: 'Something went wrong in exporting the quote',
  },
  unarchive: {
    successMessage: 'Quote unarchived with success',
    errorMessage: 'Something went wrong in unarchiving the quote',
  },
};

const getStatusMessage = ({
  action,
  type = 'error',
  message,
}: {
  action: 'delete' | 'archive' | 'unarchive' | 'export' | null;
  type?: 'success' | 'error';
  message?: string;
}) => {
  if (message) {
    return message;
  } else if (!action) {
    return 'Something went wrong. No action defined';
  } else if (type === 'success') {
    return statusMessage[action].successMessage;
  } else {
    return statusMessage[action].errorMessage;
  }
};

export const ActionStatus = ({
  type = 'error',
  show = false,
  action,
  message,
  onClose,
}: {
  action: 'delete' | 'archive' | 'unarchive' | 'export' | null;
  show?: boolean;
  type?: 'success' | 'error';
  message?: string;
  onClose: () => void;
}) => {
  useEffect(() => {
    const timer = setTimeout(onClose, 5000);
    return () => clearTimeout(timer);
  }, [show]);

  return show ? (
    <Notification type={type} onClose={onClose}>
      {getStatusMessage({ action, type, message })}
    </Notification>
  ) : (
    <></>
  );
};
