import styled from '@emotion/styled';
import { theme } from '@Calix-Commerce/design-system/globals';

export const QuoteName = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  text-align: left;
  font-weight: bold;
  width: 125px;
`;

export const PartName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0.3rem 0;
  text-align: left;
  font-weight: bold;
`;

export const PartNumber = styled.div`
  color: ${theme.colors.item};
  text-align: left;
`;

export const GroupName = styled.div`
  color: ${theme.colors.item};
  text-align: left;
  padding: 0.1rem;
  padding-left: 1rem;
`;
