import { getI18NText } from 'I18N';
import { isValidAddress } from 'components/forms/address/AddressForm';
import dayjs from 'dayjs';
import { getShipmentQuantityError, isAllShipmentLinesZero } from 'utils/helpers/checkout';
import * as yup from 'yup';

const addressHeaderId = 'shipping-page-header';
const shippingHeaderId = 'additional-shipping-info-header';

export const validateCheckoutShipping = (
  shippingData
): {
  isAllDataValid: boolean;
  shippingErrors: any;
  focusOnElement: string;
  isShipInstructionsMissing: boolean;
} => {
  let focusOnElement = '';
  const { shippingAddress, shippingInfo, shippingContact } = shippingData;
  const isValidShipAddress = isValidAddress(shippingAddress);
  const isValidShippingInfo = validateShippingInfo(shippingInfo);
  const isValidContactInfo = validateContactInfo(shippingContact);

  if (!isValidShipAddress.isValid) {
    focusOnElement = addressHeaderId;
  } else if (!isValidShippingInfo) {
    focusOnElement = shippingHeaderId;
  }

  const shippingErrors = {
    ...isValidShipAddress.errors,
    ...isValidContactInfo.errors,
  };

  return {
    isAllDataValid: isValidShipAddress.isValid && isValidShippingInfo && isValidContactInfo.isValid,
    isShipInstructionsMissing: isShippingInstructionsMissing(shippingInfo),
    focusOnElement,
    shippingErrors,
  };
};

const isShippingInstructionsMissing = (shippingInfo) => {
  return !yup
    .object()
    .shape({
      packingInstructions: yup.string().required(),
      shippingInstructions: yup.string().required(),
    })
    .isValidSync(shippingInfo);
};

const validateShippingInfo = (shippingInfo): boolean => {
  const tomorrow = dayjs(new Date()).add(1, 'day').format('MM/DD/YYYY');
  const shipmentSchedule = (shippingInfo || {}).shipmentSchedule || [];
  const isZeroQuantityOrWrongDate = shipmentSchedule.some(
    (shipment) => isAllShipmentLinesZero(shipment) || dayjs(shipment.shipDate).isBefore(tomorrow)
  );
  const shipmentQuantityError = getShipmentQuantityError(shipmentSchedule);
  const duplicateShipDatePresent =
    [...new Set(shipmentSchedule.map((shipment) => shipment.shipDate))].length !==
    shipmentSchedule.length;

  return (
    !isZeroQuantityOrWrongDate &&
    Object.keys(shipmentQuantityError).length === 0 &&
    !duplicateShipDatePresent
  );
};

const validateContactInfo = (
  contactInfo
): {
  isValid: boolean;
  errors: {
    firstName?: string;
    lastName?: string;
    email?: string;
    confirmEmail?: string;
    phoneNumber?: {
      countryCode?: string;
      areaCode?: string;
      number?: string;
    };
  };
} => {
  try {
    yup
      .object()
      .shape({
        firstName: yup.string().required(getI18NText('FIRST_NAME_ERROR_MESSAGE')),
        lastName: yup.string().required(getI18NText('LAST_NAME_ERROR_MESSAGE')),
        email: yup
          .string()
          .required(getI18NText('EMAIL_ERROR_MESSAGE'))
          .email('Please enter a proper email address.'),
        confirmEmail: yup
          .string()
          .required(getI18NText('CONFIRM_EMAIL_ERROR_MESSAGE'))
          .equals([contactInfo?.email], "Email doesn't match."),
        phoneNumber: yup.object().shape({
          countryCode: yup
            .string()
            .required(getI18NText('PHONE_COUNTRY_CODE_ERROR_MESSAGE'))
            .notOneOf(['country']),
          areaCode: yup.string().required(getI18NText('PHONE_AREA_CODE_ERROR_MESSAGE')),
          number: yup.string().required(getI18NText('PHONE_NUMBER_ERROR_MESSAGE')),
        }),
      })
      .validateSync(
        {
          ...contactInfo,
          confirmEmail: contactInfo?.emailConfirm,
          phoneNumber: {
            countryCode: contactInfo?.phoneCountry,
            areaCode: contactInfo?.phoneArea,
            number: contactInfo?.phoneNumber,
          },
        },
        {
          abortEarly: false,
        }
      );

    return {
      isValid: true,
      errors: {},
    };
  } catch (errors) {
    return {
      isValid: false,
      //@ts-ignore
      errors: errors.inner.reduce((allErrors, currentError) => {
        const newErrors = {
          ...allErrors,
          phoneNumber: allErrors?.phoneNumber || {},
        };

        if (currentError.path.includes('.')) {
          const errorKeys = currentError.path.split('.');
          newErrors[errorKeys[0]][errorKeys[1]] = currentError.message;
        } else {
          newErrors[currentError.path] = currentError.message;
        }

        return newErrors;
      }, {}),
    };
  }
};
