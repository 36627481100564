import { ReactElement } from 'react';
import { ModalProps, Modal } from '@Calix-Commerce/design-system/components';

export type LockedQuoteModalProps = Omit<Omit<ModalProps, 'title'>, 'content'> & {
  onClose: () => void;
  onConfirmation: () => void;
};

const LockedQuoteModal = ({
  onClose,
  onConfirmation,
  ...props
}: LockedQuoteModalProps): ReactElement => {
  return (
    <Modal
      title="Locked Quote"
      content="This quote is currently in use by another user"
      confirmButtonText="CLONE QUOTE"
      cancelButtonText="CLOSE"
      onConfirmation={onConfirmation}
      onDismiss={onClose}
      onCancel={onClose}
      {...props}
    />
  );
};

export { LockedQuoteModal };
