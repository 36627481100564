import { PrimaryTable as BaseTable } from '@Calix-Commerce/design-system/components';
import { Flex } from '@Calix-Commerce/design-system/layout';
import { QuoteGroupDetail } from 'types';
import { QuoteItem } from './TableRows';
import { TotalRow } from './TotalRow';
import { QuoteDetailGroupName } from './styledComponents';

export const QuoteDetailGroup = ({
  groupData,
  isQuoteOrdered,
}: {
  groupData: QuoteGroupDetail;
  allowRemovalOnReadOnly?: boolean;
  isQuoteOrdered?: boolean;
}) => {
  const { items, id, displaySubTotal, groupName, subTotalText } = groupData;

  return (
    <>
      <BaseTable>
        <thead>
          <tr>
            <th>
              <Flex alignment="center">
                <QuoteDetailGroupName>{groupName}</QuoteDetailGroupName>
              </Flex>
            </th>
            <th colSpan={4}></th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            return (
              <QuoteItem
                key={item.sequenceNumber + item.partNumber + groupData.id + index}
                item={item}
                index={index}
                groupId={id}
                isQuoteOrdered={isQuoteOrdered}
              />
            );
          })}
        </tbody>
      </BaseTable>
      <TotalRow
        backgroundColor="#e0e0e0"
        totalText={subTotalText || 'Sub-Total'}
        price={displaySubTotal}
      />
    </>
  );
};
