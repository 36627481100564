import styled from '@emotion/styled';
import MuiButton from '@mui/material/Button';

const ButtonBase = styled(MuiButton)`
  transition: none;
  padding: 0 1rem;
  font-size: 1rem;
  text-transform: none;
  white-space: nowrap;
  transition: all ease-in-out 0.2s;

  &,
  &.MuiButton-root.Mui-disabled {
    color: #fff;
  }

  &.MuiButton-root.Mui-disabled {
    opacity: 0.65;
  }

  .MuiButton-label {
    font-family: 'Roboto', sans-serif;
  }
`;

export const ButtonLink = styled(ButtonBase)`
  background: transparent;

  &,
  &.MuiButton-root.Mui-disabled {
    color: #0b38db;
  }

  &:hover {
    background: transparent;
    text-decoration: underline;
  }
`;
