import styled from '@emotion/styled';

import { FormGroup, InputIcon } from '@Calix-Commerce/design-system/components';

export const InputWrapper = styled(InputIcon)`
  input {
    border-radius: 0;

    &:placeholder-shown {
      font-style: italic;
    }
  }
`;

export const FilterFormGroup = styled(FormGroup)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  label {
    font-weight: bold;
    margin-right: 1rem;
  }
`;
