import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';

import { calixLogoSrc } from '@Calix-Commerce/design-system/assets';
import {
  Header as BaseHeader,
  HeaderContainer,
  HeaderTitle,
} from '@Calix-Commerce/design-system/components';

import { RepresentativeMenu, MainMenu } from './subcomponents';
import { ReactElement } from 'react';

const Title = styled(HeaderTitle)`
  font-size: 1.29rem;
`;

const Header = (): ReactElement => {
  const { pathname } = useLocation();

  const showMenus = pathname !== '/accounts';

  return (
    <BaseHeader>
      <HeaderContainer>
        <img src={calixLogoSrc} alt="Calix Logo" />
        <Title>MY CALIX STORE</Title>
        {showMenus && (
          <>
            <RepresentativeMenu />
            <MainMenu />
          </>
        )}
      </HeaderContainer>
    </BaseHeader>
  );
};

export { Header };
