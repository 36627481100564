import { useState, useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useApolloClient, useQuery } from '@apollo/client';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { AccountBlock } from '@Calix-Commerce/design-system/components';
import { Container } from '@Calix-Commerce/design-system/layout';

import { authenticationState, selectedAccountState, loadingState } from 'state/atoms';

import { StartNewQuote, SearchFilters, ResultsTable } from './subcomponents';
import { ACTIVE_FILTER, CREATED_BY_ALL_FILTER, applyFilters } from './filters';
import { useQuoteController } from 'utils/hooks';
import { QUOTES_PER_REQUEST_LIMIT } from 'utils/constants';
import { GET_QUOTE_LIST } from 'connectors/queries/quote';
import { Quote } from 'types';
import { withQuoteLoad } from 'hocs';

export interface SearchFilterValues extends FieldValues {
  searchTerm: string;
  createdByFilter: string[];
  tagsFilter: string[];
}

const QuotesPage = () => {
  const { fullName } = useRecoilValue(authenticationState);
  const { accountId, accountName, currencyCode } = useRecoilValue(selectedAccountState);
  const setLoading = useSetRecoilState(loadingState);
  const { createNewQuote, createFirstQuote } = useQuoteController();

  const [results, setResults] = useState<Quote[]>([]);
  const [filteredResults, setFilteredResults] = useState<Quote[]>([]);
  const [tabFilter, setTabFilter] = useState<'active' | 'archived'>(ACTIVE_FILTER);
  const [isBackgroundLoading, setIsBackgroundLoading] = useState(true);
  const apolloClient = useApolloClient();

  const formController = useForm<SearchFilterValues>({
    mode: 'onChange',
    defaultValues: {
      searchTerm: '',
      createdByFilter: [CREATED_BY_ALL_FILTER],
      tagsFilter: [],
    },
  });
  const { searchTerm, createdByFilter, tagsFilter } = formController.watch();

  const { data, refetch } = useQuery(GET_QUOTE_LIST, {
    skip: !accountId,
    variables: { accountId, limit: QUOTES_PER_REQUEST_LIMIT, offset: 0 },
    errorPolicy: 'ignore',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const newResults = data?.getAccount.listQuotes.quotes || [];
      const allResults = [...results, ...newResults];

      if (!allResults.length) {
        createFirstQuote();
      }

      setResults(allResults.filter((quote) => quote));

      if (data?.getAccount.listQuotes.hasMore) {
        refetch({ offset: allResults.length });
      } else {
        setIsBackgroundLoading(false);
      }
    },
  });

  useEffect(() => {
    setLoading(isBackgroundLoading && !data);
  }, [data, isBackgroundLoading, setLoading]);

  useEffect(() => {
    const filteredResults = applyFilters({
      items: results,
      term: searchTerm,
      createdByFilter,
      tagsFilter,
    });
    setFilteredResults(filteredResults);
  }, [results, searchTerm, createdByFilter, tagsFilter]);

  const clearAllFilters = () => {
    setTabFilter(ACTIVE_FILTER);
    formController.reset();
  };

  const loadQuoteListFromCache = (quoteList: Quote[] = []) => {
    const {
      getAccount: {
        listQuotes: { hasMore, quotes = [] },
      },
    } = apolloClient.readQuery({
      query: GET_QUOTE_LIST,
      variables: { accountId, limit: QUOTES_PER_REQUEST_LIMIT, offset: 0 },
    });

    if (hasMore) {
      return loadQuoteListFromCache([...quoteList, ...quotes]);
    }
    return [...quoteList, ...quotes];
  };

  const reloadQuoteList = () => {
    setLoading(true);
    setResults(loadQuoteListFromCache());
    setLoading(false);
  };

  // Used to control when the filter results transition animation should happen
  const resultsTransitionKey = `${searchTerm || 'no-search-term'}-${createdByFilter.join('-') || 'no-created-by'}-${tagsFilter.join('-') || 'no-tags'}-${tabFilter}`;

  return (
    <Container>
      <AccountBlock
        appName="Quote Manager"
        userName={fullName || ''}
        accountName={accountName}
        currency={currencyCode}
      />
      <div style={{ maxWidth: '550px' }}>
        <StartNewQuote onStartNewQuote={createNewQuote} />
        <SearchFilters formController={formController} items={results} />
      </div>
      <ResultsTable
        allItems={results}
        filteredItems={filteredResults}
        loading={isBackgroundLoading}
        tabFilter={tabFilter}
        onTabSelect={setTabFilter}
        clearAllFilters={clearAllFilters}
        resultsTransitionKey={resultsTransitionKey}
        onActionCompleted={reloadQuoteList}
      />
    </Container>
  );
};

const Quotes = withQuoteLoad(QuotesPage);

export { Quotes };
