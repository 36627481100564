import { getI18NText } from 'I18N/I18N';
import { ContactContainer, ShipmentContactInfoParagraph } from './styledComponents';
import { ContactInfoForm } from 'components/forms/contact/ContactInfoForm';

export const AdditionalShippingContactInfo = ({
  contactInfo,
  onContactInfoChange,
  errors,
  setError,
}) => {
  return (
    <ContactContainer>
      <ShipmentContactInfoParagraph>
        {getI18NText('SHIPMENT_CONTACT_INFO')}
        <br></br>
        <b>Note:</b>&nbsp;
        {getI18NText('SHIPMENT_CONTACT_INFO_1')}
      </ShipmentContactInfoParagraph>
      <ContactInfoForm
        handleContactChange={onContactInfoChange}
        contactInfo={contactInfo}
        errors={errors}
        setError={setError}
      />
    </ContactContainer>
  );
};
