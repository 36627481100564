import { FileType } from 'types/FileType';

const base64ToArrayBuffer = (base64) => {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};
const saveByteArray = (reportName, byte, type) => {
  const blob = new Blob([byte], { type });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = reportName;
  link.click();
};

export const downloadFile = ({ fileName, fileData, type }) => {
  const appType = type === FileType.XLS ? 'application/xls' : 'application/pdf';
  const sampleArr = base64ToArrayBuffer(fileData);
  saveByteArray(fileName, sampleArr, appType);
};
