import { useEffect } from 'react';
import { getSavedCheckoutData } from 'utils/helpers/checkout';
import { useAppNavigation } from 'utils/hooks';
import { useParams } from 'react-router-dom';
import { validateCheckoutShipping } from 'pages/CheckoutShipping';

export const withCheckoutShippingConfigured = (CheckoutPage: React.ComponentType) => {
  const WrappedComponent = () => {
    const { transactionId } = useParams();
    const { redirectToCheckoutShipping } = useAppNavigation();
    const { shippingAddress, shippingInfo, shippingContact } = getSavedCheckoutData(transactionId);

    useEffect(() => {
      const { isAllDataValid } = validateCheckoutShipping({
        shippingAddress,
        shippingInfo,
        shippingContact,
      });

      if (!isAllDataValid) {
        redirectToCheckoutShipping();
      }
    }, [shippingAddress, shippingInfo, shippingContact, redirectToCheckoutShipping]);

    return <CheckoutPage />;
  };

  return WrappedComponent;
};
