import { gql } from '@apollo/client';
import { addressRecord } from './../fragments';
import { DELETE_ADDRESS_OPERATION, CREATE_ADDRESS_OPERATION } from 'utils/constants';

export const DELETE_ADDRESS = gql`
  mutation ${DELETE_ADDRESS_OPERATION}($addressId: [ID!]!) {
    deleteAddress(input: $addressId)
  }
`;

export const CREATE_ADDRESS = gql`
  mutation ${CREATE_ADDRESS_OPERATION}($address: [AddressRecordInput!]!) {
    createAddress(input: $address) {
      ...addressRecord
    }
  }

  ${addressRecord}
`;
