import { useAppNavigation, useQuoteController } from 'utils/hooks';
import { Modal } from '@Calix-Commerce/design-system/components';
import { ReactElement } from 'react';
import { ModalProps } from '@Calix-Commerce/design-system/components';

export type StartQuoteModalProps = Omit<Omit<ModalProps, 'title'>, 'content'> & {
  onClose: () => void;
};

const StartQuoteModal = ({ onClose, ...props }: StartQuoteModalProps): ReactElement => {
  const { redirectToPartSearchForNew } = useAppNavigation();
  const { createNewQuote } = useQuoteController();

  const handleModalConfirmation = (event) => {
    event.stopPropagation();

    createNewQuote();
    redirectToPartSearchForNew();
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <Modal
      title="Start New Quote"
      content="Any unsaved data will be lost. Are you sure you want to proceed?"
      flag="Warning"
      onConfirmation={handleModalConfirmation}
      onDismiss={onClose}
      onCancel={onClose}
      {...props}
    />
  );
};

export { StartQuoteModal };
