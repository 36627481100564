import { ButtonRounded, DropdownSecondary } from '@Calix-Commerce/design-system/components';
import styled from '@emotion/styled';

export const MandatorySymbol = styled.span`
  color: #a94442;
`;

export const ItemName = styled.label`
  width: 25%;
  font-weight: bold;

  @media (max-width: 990px) {
    padding-left: 0;
  }
`;

export const ItemRow = styled.div`
  display: flex;
  flex-flow: column;
  align-items: start;
  margin-bottom: 15px;

  input {
    width: 75%;
  }

  @media (max-width: 990px) {
    flex-flow: column;
    align-items: start;
  }
`;

export const InputTextWarning = styled.p`
  color: #a94442;
  padding: 0 15px 5px 0px;
  margin-left: calc(25% + 10px);

  @media (max-width: 990px) {
    padding-left: 0;
    margin-left: 0;
  }
`;

export const Item = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  .read-only-text {
    margin-left: 10px;
  }

  @media (max-width: 990px) {
    flex-flow: column;
    align-items: start;
  }
`;

export const FormDropdownSecondary = styled(DropdownSecondary)`
  width: 75%;
  margin-left: 10px;
`;

export const DivWithEllipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const FormRoundedButton = styled(ButtonRounded)`
  height: 42px;
  max-width: 240px;
  margin-left: 15px;
  margin-top: 2px;
  transition: margin 0s;

  @media (max-width: 990px) {
    margin: 0;
  }
`;
