import { useState, ReactElement, SyntheticEvent } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';

import {
  MainMenu as BaseMenu,
  MainMenuDropdown,
  MainMenuAccount,
  MainMenuOption,
  Modal,
} from '@Calix-Commerce/design-system/components';
import { theme } from '@Calix-Commerce/design-system/globals';
import { orderIconSrc, rmaIconSrc, quoteIconSrc } from '@Calix-Commerce/design-system/assets';

import { StartQuoteModal } from 'components/modals';
import {
  authenticationState,
  currentQuoteState,
  currentQuoteValidationState,
  selectedAccountState,
} from 'state/atoms';
import { CALIX_EMPLOYEE, VAR } from 'connectors/userTypes';
import {
  RMA_MANAGER_URL,
  RMA_NEW_CASE_DETAILS_URL,
  RMA_NEW_CASE_SEARCH_URL,
  ORDER_MANAGER_URL,
  NO_QUOTE_NAME_LABEL,
} from 'utils/constants';
import { useAppNavigation, useQuoteController, useUserPermissions } from 'utils/hooks';
import { Feedback } from './Feedback';
import { ItemList } from './ItemList';
import { QuoteName } from './styledComponents';
import { ReturnToAccountModal } from 'components/modals/ReturnToAccountModal';
import { BASE_NEW_QUOTE_ROUTE, ROUTE_NAMES } from 'utils/hooks/useAppNavigation/PagePath';

const MainMenu = (): ReactElement => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNewQuoteModalOpen, setIsNewQuoteModalOpen] = useState(false);
  const [isAccountRedirectOpen, setIsAccountRedirectOpen] = useState(false);
  const [noActiveQuoteModal, setNoActiveQuoteModal] = useState(false);
  const [unsavedChangesModal, setUnsavedChangesModal] = useState(false);
  const [redirectPage, setRedirectPage] = useState<ROUTE_NAMES>(ROUTE_NAMES.NEW_QUOTE_SEARCH);
  const navigate = useNavigate();
  const {
    isCheckout,
    isOneOfPages,
    redirectToAccountSearch,
    redirectToPartSearchForNew,
    redirectToQuoteDetailsForNew,
    redirectToQuoteDetails,
  } = useAppNavigation();

  const {
    getQuoteTotalWithExtendedWarrantyPriceLabel,
    getQuoteTotalWithoutExtendedWarrantyPriceLabel,
    createNewQuote,
  } = useQuoteController();

  const { quoteName, transactionId, isOrdered } = useRecoilValue(currentQuoteState);

  const quoteValidation = useRecoilValue(currentQuoteValidationState);
  const { userType } = useRecoilValue(authenticationState);
  const { accountName } = useRecoilValue(selectedAccountState);
  const { hasRmaAccess, hasOrderAccess } = useUserPermissions();
  const showSettings = [CALIX_EMPLOYEE, VAR].some((type) => userType.includes(type));
  const isManagerPage = isOneOfPages([ROUTE_NAMES.QUOTES]);
  const isDetailsPage = isOneOfPages([ROUTE_NAMES.NEW_QUOTE_DETAILS, ROUTE_NAMES.QUOTE_DETAILS]);
  const isCheckoutPostWarranty = isOneOfPages([
    ROUTE_NAMES.CHECKOUT_SHIPPING,
    ROUTE_NAMES.CHECKOUT_BILLING,
    ROUTE_NAMES.CHECKOUT_REVIEW,
    ROUTE_NAMES.CHECKOUT_CONFIRMATION,
  ]);

  const priceDisplay =
    isOrdered || isCheckoutPostWarranty
      ? getQuoteTotalWithExtendedWarrantyPriceLabel()
      : getQuoteTotalWithoutExtendedWarrantyPriceLabel();

  const handleNewQuoteClick = (e?: SyntheticEvent<Element, Event>) => {
    setIsDropdownOpen(false);
    e?.stopPropagation();

    if (quoteValidation.isModified) {
      setIsNewQuoteModalOpen(true);
    } else if (isCheckout) {
      setUnsavedChangesModal(true);
      setRedirectPage(ROUTE_NAMES.NEW_QUOTE_SEARCH);
      return;
    } else {
      // Ensure we clean-up the quote state before we redirect
      createNewQuote();
      redirectToPartSearchForNew();
    }
  };

  const handleQuoteDetailsRedirectClick = (e?: SyntheticEvent<Element, Event>) => {
    setIsDropdownOpen(false);
    e?.stopPropagation();

    if (!quoteName) {
      setNoActiveQuoteModal(true);
      return;
    } else if (isCheckout) {
      setUnsavedChangesModal(true);
      setRedirectPage(ROUTE_NAMES.QUOTE_DETAILS);
      return;
    }

    if (transactionId) {
      redirectToQuoteDetails(transactionId);
    } else {
      redirectToQuoteDetailsForNew();
    }
  };

  const handleAccountRedirect = () => {
    if (quoteValidation.isModified) {
      setIsAccountRedirectOpen(true);
    } else {
      redirectToAccountSearch();
    }
  };

  const handleNewQuoteSearchRedirect = (e?: SyntheticEvent<Element, Event>) => {
    setIsDropdownOpen(false);
    setNoActiveQuoteModal(false);
    setUnsavedChangesModal(false);
    createNewQuote();
    redirectToPartSearchForNew();

    e?.stopPropagation();
  };

  const handleQuoteDetailsRedirect = (e?: SyntheticEvent<Element, Event>) => {
    setIsDropdownOpen(false);
    setUnsavedChangesModal(false);
    if (transactionId) {
      redirectToQuoteDetails(transactionId);
    } else {
      redirectToQuoteDetailsForNew();
    }

    e?.stopPropagation();
  };

  const handleCancelUnsavedChanges = (e?: SyntheticEvent<Element, Event>) => {
    setIsDropdownOpen(false);
    setUnsavedChangesModal(false);
    e?.stopPropagation();
  };

  const pageRedirectionHandlers = {
    [ROUTE_NAMES.NEW_QUOTE_SEARCH]: handleNewQuoteSearchRedirect,
    [ROUTE_NAMES.QUOTE_DETAILS]: handleQuoteDetailsRedirect,
  };

  return (
    <BaseMenu
      isOpen={isDropdownOpen}
      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      onClickOutside={() => setIsDropdownOpen(false)}
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
    >
      <div>Current Quote</div>
      <QuoteName>{quoteName || NO_QUOTE_NAME_LABEL}</QuoteName>
      <div style={{ marginTop: '0.4rem' }}>{priceDisplay}</div>
      <MainMenuDropdown
        propagateClick
        isOpen={isDropdownOpen}
        position="right"
        style={{ left: '-75%', zIndex: 2000 }}
      >
        {showSettings && <SettingsIcon onClick={handleAccountRedirect} />}
        <MainMenuAccount showSettings={showSettings} style={{ textAlign: 'left' }}>
          {accountName}
        </MainMenuAccount>
        <MainMenuOption
          selected={Boolean(isManagerPage || isDetailsPage)}
          iconSrc={quoteIconSrc}
          text="Quotes"
          onClick={() => navigate(BASE_NEW_QUOTE_ROUTE, { state: { preventRedirect: true } })}
          onEyeClick={handleQuoteDetailsRedirectClick}
          onAddClick={handleNewQuoteClick}
          eyeIconProps={{
            style: {
              color: isDetailsPage ? theme.colors.secondary : theme.colors.action,
            },
          }}
        />
        {hasRmaAccess && (
          <MainMenuOption
            iconSrc={rmaIconSrc}
            text="RMAs"
            onClick={() => window.location.assign(RMA_MANAGER_URL)}
            onEyeClick={() => window.location.assign(RMA_NEW_CASE_DETAILS_URL)}
            onAddClick={() => window.location.assign(RMA_NEW_CASE_SEARCH_URL)}
          />
        )}
        {hasOrderAccess && (
          <MainMenuOption
            iconSrc={orderIconSrc}
            text="Orders"
            onClick={() => window.location.assign(ORDER_MANAGER_URL)}
          />
        )}
        {isCheckout || isOrdered ? null : <ItemList />}
        <Feedback />
      </MainMenuDropdown>
      <StartQuoteModal isOpen={isNewQuoteModalOpen} onClose={() => setIsNewQuoteModalOpen(false)} />
      <ReturnToAccountModal
        onClose={() => setIsAccountRedirectOpen(false)}
        isOpen={isAccountRedirectOpen}
        onConfirmation={redirectToAccountSearch}
      />
      <Modal
        isOpen={unsavedChangesModal}
        title="You have unsaved changes"
        content="Are you sure you what to leave checkout?"
        cancelButtonText="CANCEL"
        confirmButtonText="YES"
        onConfirmation={pageRedirectionHandlers[redirectPage]}
        onCancel={handleCancelUnsavedChanges}
        onDismiss={handleCancelUnsavedChanges}
      />
      <Modal
        isOpen={noActiveQuoteModal}
        title="No Active Quote Found"
        content="You do not have an active quote to display. Would you like to start a new quote?"
        cancelButtonText="CANCEL"
        confirmButtonText="START NEW QUOTE"
        onConfirmation={handleNewQuoteSearchRedirect}
        onCancel={() => setNoActiveQuoteModal(false)}
        onDismiss={() => setNoActiveQuoteModal(false)}
      />
    </BaseMenu>
  );
};

export { MainMenu };
