import { PrimaryTableRow } from '@Calix-Commerce/design-system';
import { TableItem as BaseTableItem } from '@Calix-Commerce/design-system/components';
import styled from '@emotion/styled';

import { PrimaryTable } from 'componentsMigrated/PrimaryTable';

export const Table = styled(PrimaryTable)`
  margin-top: 0.92rem;

  tr td:last-child {
    padding-right: 0;
  }
`;

export const TableRow = styled(PrimaryTableRow)`
  td {
    padding: 1.42rem 1.78rem 1.42rem 0.57rem;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;

  svg {
    width: 1.8rem;
    position: relative;
    bottom: 0.3rem;
    margin-right: 1rem;
    font-size: 1.8rem;
    cursor: pointer;
    opacity: 0.6;
    color: #0b38db;
    stroke: #0b38db;

    &:hover {
      opacity: 0.9;
    }

    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
`;

export const FloatingMenuWrapper = styled.span`
  background: white;
  border: 1px solid blue;
  position: absolute;
  left: -30px;
  z-index: 11;
  top: 42px;
`;

export const FloatingMenuHeader = styled.div`
  padding: 8px 16px;
  text-align: center;
  border-bottom: 1px solid blue;
  font-weight: 600;
`;

export const FloatingMenuNote = styled.div`
  padding: 8px 16px;
  max-width: 220px;
  text-align: left;
`;

export const TableItem = styled(BaseTableItem)`
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  line-height: 1.75em;

  &:hover {
    color: #ff7601;
  }
`;

export const TableData = styled.div`
  word-break: normal;
  word-wrap: break-word;
  line-height: 1.75em;
`;

export const TableDataNoWrap = styled.div`
  white-space: nowrap;
  line-height: 1.75em;
`;
