import dayjs from 'dayjs';
import { DEFAULT_DATE_FORMAT_ISO, DEFAULT_DATE_FORMAT_UI } from 'utils/constants/date';

export const formatDate = (dateString: string, fallback: string = ''): string => {
  if (!dateString) {
    return fallback;
  }

  return dayjs(dateString, DEFAULT_DATE_FORMAT_ISO).format(DEFAULT_DATE_FORMAT_UI);
};

export const formatDateTime = (dateString: string, fallback: string = ''): string => {
  if (!dateString) {
    return fallback;
  }

  return new Date(dateString).toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    timeZoneName: 'short',
  });
};
