import { Container } from '@Calix-Commerce/design-system/layout';
import { getI18NText } from 'I18N';
import { useParams } from 'react-router-dom';
import { readCheckoutSavedData } from 'utils/helpers/checkout';
import { ReviewFormContainer } from '../styledComponents';
import { ReviewSubHeader } from './styledComponents';
import { Address } from 'types';
import dayjs from 'dayjs';
import { DEFAULT_DATE_FORMAT_UI } from 'utils/constants/date';

export const ShippingDetails = () => {
  const { transactionId } = useParams();
  const checkoutData = readCheckoutSavedData(transactionId);
  const shippingAddress = checkoutData.shippingAddress || ({} as Address);
  const shipmentSchedules = checkoutData.shippingInfo?.shipmentSchedule || [];
  const packagingInstructions = checkoutData.shippingInfo?.packingInstructions || '';
  const shippingInstructions = checkoutData.shippingInfo?.shippingInstructions || '';
  const shippingContact = checkoutData.shippingContact;
  const phone =
    shippingContact?.phoneNumber ||
    ({} as {
      countryCode: string;
      areaCode: string;
      number: string;
      extension: string;
    });

  return (
    <Container>
      <ReviewSubHeader>{getI18NText('SHIPPING')}</ReviewSubHeader>
      <ReviewFormContainer>
        <tbody>
          <tr>
            <td>{getI18NText('ADDRESS_1')}:</td>
            <td>{shippingAddress.address1}</td>
          </tr>
          <tr>
            <td>{getI18NText('ADDRESS_2')}:</td>
            <td>{shippingAddress.address2}</td>
          </tr>
          <tr>
            <td>{getI18NText('CITY')}:</td>
            <td>{shippingAddress.city}</td>
          </tr>
          <tr>
            <td>{getI18NText('STATE_PROVINCE_REGION')}:</td>
            <td>{shippingAddress.state}</td>
          </tr>
          <tr>
            <td>{getI18NText('POSTAL_CODE')}:</td>
            <td>{shippingAddress.zipcode}</td>
          </tr>
          <tr>
            <td>{getI18NText('COUNTRY')}:</td>
            <td>{shippingAddress.country}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <br></br>
            </td>
          </tr>
          {shipmentSchedules.length > 1 ? (
            <tr>
              <td colSpan={2}>
                <p className="review-ship-label">{getI18NText('SHIPMENT_SCHEDULE')}:</p>
                {shipmentSchedules.map((shipmentSchedule) => (
                  <div className="shipment-schedule-details row" key={shipmentSchedule.shipDate}>
                    <div className="shipment-group-header">
                      <span className="ship-date">
                        {dayjs(shipmentSchedule.shipDate).format(DEFAULT_DATE_FORMAT_UI)}
                      </span>
                      <span className="quantity">QTY</span>
                    </div>
                    {shipmentSchedule.lines
                      .filter((line) => line.quantity)
                      .map((line) => (
                        <div className="shipment-schedule-list" key={line.partNumber}>
                          <span className="part-name">{`${line.partNumber} - ${line.partName}`}</span>
                          <span className="quantity">{line.quantity}</span>
                        </div>
                      ))}
                  </div>
                ))}
              </td>
            </tr>
          ) : (
            <tr>
              <td>
                <p className="review-ship-label">{getI18NText('REQUESTED_SHIP_DATE')}:</p>
              </td>
              <td>
                <p className="review-ship-value">{shipmentSchedules[0]?.shipDate}</p>
              </td>
            </tr>
          )}
          <tr>
            <td colSpan={2}>
              <p className="review-ship-label">{getI18NText('PACKAGING_INSTRUCTIONS')}:</p>
              <p className="review-ship-value">{packagingInstructions}</p>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <p className="review-ship-label">{getI18NText('SHIPPING_INSTRUCTIONS')}:</p>
              <p className="review-ship-value">{shippingInstructions}</p>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <br></br>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <p className="review-ship-label">
                {getI18NText('ADDITIONAL_SHIPPING_CONTACT_INFO')}:
              </p>
              <p className="review-ship-value">{`${shippingContact?.firstName} ${shippingContact?.lastName}`}</p>
              <p className="review-ship-value">{`+${phone?.countryCode} (${phone?.areaCode}) ${phone?.number} ${phone?.extension ? `, extension ${phone.extension}` : ''}`}</p>
              <a className="review-ship-link" href={`mailto:${shippingContact?.email}`}>
                {shippingContact?.email}
              </a>
            </td>
          </tr>
        </tbody>
      </ReviewFormContainer>
    </Container>
  );
};
