import { gql } from '@apollo/client';

export const address = gql`
  fragment address on Address {
    name
    address1
    address2
    city
    state
    zipcode
    country
  }
`;

export const addressRecord = gql`
  fragment addressRecord on AddressRecord {
    name
    addressId
    accountId
    varAccountId
    type
    favorite
    address {
      ...address
    }
  }

  ${address}
`;

export const getAddresses = gql`
  fragment getAddresses on Account {
    getAddresses {
      ...addressRecord
    }
  }

  ${addressRecord}
`;
