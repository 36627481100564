import { TotalContainer, TotalLabel, TotalPrice } from './styledComponents';

const TotalRow = ({
  backgroundColor,
  totalText,
  price,
  textColor,
  showContactMessage,
}: {
  backgroundColor: string;
  totalText: string;
  price: string;
  textColor?: string;
  showContactMessage?: boolean;
}) => {
  return (
    <TotalContainer style={{ backgroundColor }}>
      <TotalLabel>
        <span style={{ color: textColor ? textColor : 'fff' }}>
          <b>{totalText}: </b>
        </span>
      </TotalLabel>
      <TotalPrice style={{ minWidth: '200px' }}>
        <span style={{ color: textColor ? textColor : 'fff', textAlign: 'end' }}>
          <b>{showContactMessage ? 'Contact your customer operations rep.' : price}</b>
        </span>
      </TotalPrice>
    </TotalContainer>
  );
};

export { TotalRow };
