import moment from 'moment';
import { CurrentQuoteState, LineItem, ProductData } from 'types';
import { QUOTE_ITEM_EXTENDED_WARRANTY_LINE } from 'utils/constants';
import { QuoteStatus } from 'types/QuoteStatus';
import { DEFAULT_DATE_FORMAT_ISO, DEFAULT_DATE_FORMAT_UI } from 'utils/constants/date';

export const isCheckoutAllowed = ({
  quote,
  isExpired,
  isWritable,
  isLockedByAnotherUser,
  hasActiveItems,
  hasCurrencyMismatch,
  hasOutdatedDiscounts,
  hasUnavailableItems,
}: {
  quote: CurrentQuoteState;
  isExpired: boolean;
  isWritable: boolean;
  isLockedByAnotherUser: boolean;
  hasActiveItems: boolean;
  hasCurrencyMismatch: boolean;
  hasOutdatedDiscounts: boolean;
  hasUnavailableItems: boolean;
}) => {
  if (isWritable) {
    return true;
  }

  return (
    quote.commerceComplete &&
    hasActiveItems &&
    !quote.isOrdered &&
    !isExpired &&
    !isLockedByAnotherUser &&
    !hasCurrencyMismatch &&
    !hasOutdatedDiscounts &&
    !hasUnavailableItems
  );
};

export const isQuoteExpired = ({ quote }: { quote: CurrentQuoteState }) => {
  const expirationDate = moment(quote.expirationDate, DEFAULT_DATE_FORMAT_ISO).format(
    DEFAULT_DATE_FORMAT_UI
  );

  return (
    !quote.isOrdered &&
    quote.quoteStatus === QuoteStatus.APPROVED &&
    isDateInThePast(expirationDate)
  );
};

export const hasOutdatedDiscount = ({
  quote,
  quoteItem,
  productList,
}: {
  quote: CurrentQuoteState;
  quoteItem: LineItem;
  productList: ProductData[];
}) => {
  const isQuotePending = quote.quoteStatus === QuoteStatus.PENDING;
  const isQuotePendingRevision = quote.quoteStatus === QuoteStatus.REVISION_PENDING;

  if (!isQuotePending && !isQuotePendingRevision) {
    return false;
  }

  const product = productList.find((item) => item.partNumber === quoteItem.partNumber);

  if (!product || product.priceLine === QUOTE_ITEM_EXTENDED_WARRANTY_LINE) {
    return false;
  }

  return product.sellPrice !== quoteItem.sellPrice;
};

const isDateInThePast = (date: string) => {
  let expired;
  // Wrap in try/catch to handle errors
  if (date) {
    try {
      expired = moment()
        .utc()
        .isAfter(moment(date + ' -07:00', 'MM/DD/YYYY HH:mm:ss Z').utc());
    } catch (e) {
      expired = false;
    }
  }
  return expired;
};

export const isLockedByCurrentUser = ({
  email,
  lockedBy,
  lockExpiry,
}: {
  email: string;
  lockedBy: string;
  lockExpiry: string;
}) => {
  return lockedBy === email && !isDateInThePast(lockExpiry);
};

export const isQuoteLockedByAnotherUser = ({
  email,
  lockedBy,
  lockExpiry,
}: {
  email: string;
  lockedBy: string;
  lockExpiry: string;
}) => {
  return lockedBy !== email && lockedBy !== '#unlocked#' && !isDateInThePast(lockExpiry);
};

export const isQuoteUnlocked = ({
  lockedBy,
  lockExpiry,
}: {
  lockedBy: string;
  lockExpiry: string;
}) => {
  return lockedBy === '#unlocked#' || isDateInThePast(lockExpiry);
};

export const hasWritePermission = (permissions: string[]) => {
  return permissions && permissions.includes('Write');
};
