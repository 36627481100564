import { MyCalixLink, TermsOfSaleLink, Title, ConfirmationContainer } from './styledComponents';
import { useAppNavigation, useUserPermissions } from 'utils/hooks';
import { FeedbackConfirmation } from './subcomponents/FeedbackConfirmation';
import { ORDER_MANAGER_URL } from 'utils/constants';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { storageCurrentQuoteSelector } from 'state/selectors';
import { withQuoteLoad } from 'hocs';
import {
  CHECKOUT_CONFIRMATION_MY_CALIX_LINK_ID,
  CHECKOUT_CONFIRMATION_TERMS_LINK_ID,
} from 'utils/constants/selectors';

const ConfirmationPage = () => {
  const termsLinkUrl = import.meta.env.VITE_CALIX_SALE_TERMS_URL;
  const { hasOrderAccess } = useUserPermissions();
  const { redirectToQuoteDetails } = useAppNavigation();
  const { transactionId, isOrdered } = useRecoilValue(storageCurrentQuoteSelector);

  useEffect(() => {
    if (!isOrdered) {
      redirectToQuoteDetails(transactionId);
    }
  }, [isOrdered, transactionId, redirectToQuoteDetails]);

  return (
    <ConfirmationContainer>
      <Title>Order Confirmation</Title>
      <p>
        Your order has been submitted. You will receive a confirmation email shortly with your sales
        order number.
      </p>
      {hasOrderAccess ? (
        <p>
          Your order will be available for review in the{' '}
          <MyCalixLink
            id={CHECKOUT_CONFIRMATION_MY_CALIX_LINK_ID}
            onClick={() => window.location.assign(ORDER_MANAGER_URL)}
          >
            My Calix Store Order Manager
          </MyCalixLink>{' '}
          within 30 minutes
        </p>
      ) : (
        <p>Your order will be available for review within 30 minutes</p>
      )}

      <p>
        See{' '}
        <TermsOfSaleLink
          id={CHECKOUT_CONFIRMATION_TERMS_LINK_ID}
          target="_blank"
          href={termsLinkUrl}
        >
          Terms of Sale
        </TermsOfSaleLink>
      </p>
      <FeedbackConfirmation />
    </ConfirmationContainer>
  );
};

const CheckoutConfirmation = withQuoteLoad(ConfirmationPage);

export { CheckoutConfirmation };
