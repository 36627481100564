import { DropdownOption } from '@Calix-Commerce/design-system/components';
import { CREATED_BY_ALL_FILTER } from 'pages/Quotes/filters';
import { Quote } from 'types';

// Generates options to be used on the filter dropdowns, based on a list of items

export const getCreatedByOptions = (items: Quote[]): DropdownOption[] => {
  const uniqueNames: { [k: string]: DropdownOption } = {};

  for (const item of items) {
    const name = item.preparedByName;

    uniqueNames[name] = { id: name, text: name };
  }

  return [{ id: CREATED_BY_ALL_FILTER, text: 'Created by all' }, ...Object.values(uniqueNames)];
};

export const getTagsOptions = (items: Quote[]): DropdownOption[] => {
  const uniqueTags: { [k: string]: DropdownOption } = {};

  for (const item of items) {
    const itemTags = item.tags || [];

    for (const tag of itemTags) {
      uniqueTags[tag] = { id: tag, text: tag };
    }
  }

  return Object.values(uniqueTags);
};
