import styled from '@emotion/styled';
import { Input, PrimaryTableRow } from '@Calix-Commerce/design-system/components';

export const QuickAddTableRow = styled(PrimaryTableRow)`
  vertical-align: middle;

  td {
    padding: 4px;

    &:first-of-type {
      padding-left: 15px;
    }
  }
`;

export const QuickAddTableColumn = styled.td`
  border-top: 1px solid rgb(160, 160, 160);
`;

export const QuickAddInput = styled(Input)`
  width: 300px;
  height: 28px;
  margin: 0 5px;
  border-radius: 0;

  &::-webkit-input-placeholder,
  &:-ms-input-placeholder,
  &::-moz-placeholder,
  &::placeholder {
    font-size: 1em;
    font-style: italic;
    color: #606060;
  }
`;

export const QuickAddLabel = styled.label`
  display: flex;
  alignitems: center;
`;

export const PartListItem = styled.li<{ emphasize?: boolean }>`
  color: ${({ emphasize }) => (emphasize ? '#0b38db' : '')};
  font-weight: ${({ emphasize }) => (emphasize ? '700' : '')};
  padding: 1.07rem;
  liststyle: none;
  fontsize: 1rem;

  &:hover {
    background: #e0e0e0;
  }
`;
