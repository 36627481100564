import { Currency, LineItem } from 'types';
import { CONTACT_COPS_LABEL } from 'utils/constants';
import { useFormatPrice } from 'utils/hooks';

const PriceDisplay = ({
  item,
  currencyCode,
  showTotalPrice = false,
  showPriceWarnings = true,
}: {
  item: LineItem;
  currencyCode?: Currency;
  showTotalPrice?: boolean;
  showPriceWarnings?: boolean;
}) => {
  const formatPrice = useFormatPrice(currencyCode);

  if (!item.available && showPriceWarnings) {
    return 'Not Available';
  } else if (item.discountType === null && showPriceWarnings) {
    return CONTACT_COPS_LABEL;
  } else {
    const totalPrice = item.totalPrice;
    let sellPrice = item.sellPrice;

    if (item.extendedWarrantyQuantity) {
      sellPrice = item.totalPrice / item.extendedWarrantyQuantity;
    }
    return showTotalPrice ? formatPrice(totalPrice) : formatPrice(sellPrice);
  }
};

export { PriceDisplay };
