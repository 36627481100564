import { CurrentQuoteValidationState } from 'types';
import { ErrorMessage } from 'types/Error';

export function validateAlterQuote({
  validationState,
}: {
  validationState: CurrentQuoteValidationState;
}) {
  const { isWritable, hasItemsWithQuantityZero, hasInvalidItemQuantity } = validationState;

  if (!isWritable) {
    throw new Error(ErrorMessage.READ_ONLY_QUOTE);
  }

  if (hasItemsWithQuantityZero) {
    throw new Error(ErrorMessage.HAS_ITEMS_WITH_QUANTITY_ZERO);
  }

  if (hasInvalidItemQuantity) {
    throw new Error(ErrorMessage.HAS_INVALID_ITEM_QUANTITY);
  }
}

export function validateCloneQuote({
  validationState,
}: {
  validationState: CurrentQuoteValidationState;
}) {
  const { isModified, hasCurrencyMismatch, hasOneTimeDiscounts } = validationState;

  if (isModified) {
    throw new Error(ErrorMessage.MODIFIED_QUOTE);
  }

  if (hasCurrencyMismatch) {
    throw new Error(ErrorMessage.HAS_CURRENCY_MISMATCH);
  }

  if (hasOneTimeDiscounts) {
    throw new Error(ErrorMessage.HAS_ONE_TIME_DISCOUNTS);
  }
}

export function validateCreateQuote({
  validationState,
}: {
  validationState: CurrentQuoteValidationState;
}) {
  const { hasItemsWithQuantityZero, hasInvalidItemQuantity } = validationState;

  if (hasItemsWithQuantityZero) {
    throw new Error(ErrorMessage.HAS_ITEMS_WITH_QUANTITY_ZERO);
  }

  if (hasInvalidItemQuantity) {
    throw new Error(ErrorMessage.HAS_INVALID_ITEM_QUANTITY);
  }
}
