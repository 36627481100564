import { InView } from 'react-intersection-observer';
import { Product } from './Product';
import { Title } from './styledComponents';
import { PartSearchResponse } from 'types/state/Part';

const SearchResults = ({
  products = [],
  hasSearched = false,
  isBackgroundLoading = false,
  onEndOfPage,
}: {
  products: PartSearchResponse[];
  hasSearched: boolean;
  isBackgroundLoading: boolean;
  onEndOfPage: () => void;
}) => {
  const renderNoResults = () =>
    hasSearched && !isBackgroundLoading ? (
      <div>Sorry, we couldn't find any products. Please try a different search.</div>
    ) : null;

  const renderProductList = () => {
    return (
      <>
        {!hasSearched ? (
          <Title>
            {products.length === 1
              ? 'Your Most Recently Ordered Item'
              : `Your ${products.length} Most Recently Ordered Items`}
          </Title>
        ) : (
          <Title>Search Results</Title>
        )}
        {products.map((product) => {
          return (
            <Product
              key={product.partNumber}
              isBackgroundLoading={isBackgroundLoading}
              productData={product}
            />
          );
        })}
        <InView as="div" rootMargin="1500px" onChange={(inView) => inView && onEndOfPage()} />
      </>
    );
  };

  return products.length ? renderProductList() : renderNoResults();
};

export { SearchResults };
