import { Input, Modal } from '@Calix-Commerce/design-system';
import { useEffect, useState } from 'react';

type ModalProps = {
  isOpen: boolean;
  onConfirmation: ({ newQuoteName, newQuoteDescription }) => void;
  onClose: () => void;
  initialQuoteName: string | null;
  initialQuoteDescription: string | null;
};

const ReorderTextInput = ({
  inputLabel,
  inputValue,
  onChange,
}: {
  inputLabel: string;
  inputValue: string;
  onChange: (newValue: string) => void;
}): JSX.Element => {
  return (
    <>
      <label style={{ fontWeight: 'bold' }}>{inputLabel}</label>
      <Input type="text" value={inputValue} onChange={(event) => onChange(event?.target.value)} />
    </>
  );
};

const ReorderModal = ({
  isOpen,
  onConfirmation,
  initialQuoteName,
  initialQuoteDescription,
  onClose,
  ...props
}: ModalProps) => {
  const [newQuoteName, setNewQuoteName] = useState('');
  const [newQuoteDescription, setNewQuoteDescription] = useState('');

  useEffect(() => {
    setNewQuoteName(initialQuoteName || '');
    setNewQuoteDescription(initialQuoteDescription || '');
  }, [initialQuoteDescription, initialQuoteName]);

  const ModalContent = () => {
    return (
      <>
        <p>
          If you would like to use a new name and/or description for this reorder please specify
          below.
        </p>
        {ReorderTextInput({
          inputLabel: 'New Quote Name',
          inputValue: newQuoteName,
          onChange: setNewQuoteName,
        })}
        {ReorderTextInput({
          inputLabel: 'New Description Name',
          inputValue: newQuoteDescription,
          onChange: setNewQuoteDescription,
        })}
      </>
    );
  };

  return (
    <Modal
      title="Update Quote Description"
      //@ts-expect-error Design-System Modal component only accepts strings as content
      content={ModalContent()}
      confirmButtonText="CONTINUE"
      isOpen={isOpen}
      onDismiss={onClose}
      onConfirmation={() => {
        onConfirmation({ newQuoteName, newQuoteDescription });
      }}
      {...props}
    />
  );
};

export { ReorderModal };
