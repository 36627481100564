import { ErrorMessage } from 'types/Error';

export const ERROR_MESSAGE_MAPPING = {
  [ErrorMessage.EMPTY_QUOTE]: 'Your cart is empty. Please add one or more items.',
  [ErrorMessage.HAS_CURRENCY_MISMATCH]: "The quote currency doesn't match the account currency.",
  [ErrorMessage.HAS_NON_QUOTABLE_ITEM]:
    "Please contact your customer operations representative to determine pricing for any items whose price appears in your cart as 'Contact C-Ops'",
  [ErrorMessage.HAS_NON_AVAILABLE_ITEM]:
    'You have one or more unavailable items in your cart. Please remove them.',
  [ErrorMessage.QUOTE_NOT_READY_FOR_CHECKOUT]: 'Quote is not ready to checkout.',
  [ErrorMessage.HAS_ITEMS_WITH_QUANTITY_ZERO]: 'Quote has items with quantity of zero',
  [ErrorMessage.HAS_INVALID_ITEM_QUANTITY]: 'Quote has items with an invalid quantity.',
  defaultError:
    'Something went wrong while moving to checkout. Please try again. If this issue persists, please contact your customer operations representative.',
};

export const getErrorMessage = (error: { message: string }) => {
  return ERROR_MESSAGE_MAPPING[error.message] || ERROR_MESSAGE_MAPPING.defaultError;
};
