import { TERM_URL_PARAM } from 'utils/constants';

export const buildSearchUrlQuery = ({ term = '' }) => {
  return `${TERM_URL_PARAM}=${term}`;
};

export const getSearchUrlParams = (query = '') => {
  const params = new URLSearchParams(query);

  return {
    term: params.get(TERM_URL_PARAM),
  };
};
