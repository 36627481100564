import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Modal } from '@Calix-Commerce/design-system';
import { infoModalState } from 'state/atoms';
import { ReactElement } from 'react';

const InfoModal = (): ReactElement => {
  const { open, config } = useRecoilValue(infoModalState);
  const resetModal = useResetRecoilState(infoModalState);

  return (
    <Modal
      isOpen={open}
      onConfirmation={resetModal}
      onDismiss={resetModal}
      position="top"
      {...config}
    />
  );
};

export { InfoModal };
