import { Group } from './Group';
import { TotalRow } from './TotalRow';
import {
  GROUP_DATA_TYPE,
  ITEM_GROUP_ID_DATA_TYPE,
  ITEM_ID_DATA_TYPE,
  handleGroupDrop,
  handleItemDrop,
} from './helpers';
import { QuoteDetailTableProps, QuoteGroupDetail, QuoteGroupItem } from 'types';

const QuoteDetailTable = ({
  quoteGroups,
  handleQuoteUpdate,
  handleRenameGroup,
  handleRemoveItem,
  handleReorderGroups,
  onRemoveGroup,
  onCloneGroup,
  searchResults,
  searchPartNumbers,
  handleAddPart,
  readonly,
  quoteTotalPrice,
  oneTimeDiscountPrice,
  allowRemovalOnReadOnly,
  isQuoteOrdered,
}: QuoteDetailTableProps) => {
  let dropZoneGroupId = '';

  const handleGroupOnDrag = (e: React.DragEvent, groupId: string) => {
    e.dataTransfer.setData(GROUP_DATA_TYPE, groupId);
  };

  const handleItemOnDrag = (e: React.DragEvent, itemId: string, groupId: string) => {
    e.stopPropagation();
    e.dataTransfer.setData(ITEM_ID_DATA_TYPE, itemId);
    e.dataTransfer.setData(ITEM_GROUP_ID_DATA_TYPE, groupId);
  };

  const handleDragOver = (e: React.DragEvent, groupId: string) => {
    e.preventDefault();
    dropZoneGroupId = groupId;
  };

  const handleOnDrop = (e: React.DragEvent) => {
    const draggedItemId = e.dataTransfer.getData(ITEM_ID_DATA_TYPE);
    const draggedGroupId = e.dataTransfer.getData(GROUP_DATA_TYPE);
    const groupId = e.dataTransfer.getData(ITEM_GROUP_ID_DATA_TYPE);

    if (draggedGroupId) {
      const groups = handleGroupDrop(quoteGroups, Number(draggedGroupId), Number(dropZoneGroupId));
      handleReorderGroups &&
        handleReorderGroups(
          groups.map((group: QuoteGroupDetail) => {
            return { name: group.groupName, id: group.id };
          })
        );
    } else {
      const newQuoteDetails = handleItemDrop(
        quoteGroups,
        draggedItemId,
        Number(groupId),
        Number(dropZoneGroupId)
      );
      handleQuoteUpdate && handleQuoteUpdate(newQuoteDetails);
    }
  };

  const handleRemoveGroup = (groupId: number) => {
    const removedGroup = quoteGroups.find(({ id }) => id === groupId);
    removedGroup && onRemoveGroup && onRemoveGroup(removedGroup);
  };

  const handleCloneGroup = (groupId: number) => {
    const clonedGroup = quoteGroups.find(({ id }) => id === groupId);
    clonedGroup && onCloneGroup && onCloneGroup(clonedGroup);
  };

  const handleItemUpdate = (groupId: number, newItem: QuoteGroupItem) => {
    const newQuoteDetails = quoteGroups.map((group) => {
      if (groupId === group.id) {
        return {
          ...group,
          items: group.items.map((item) => (newItem.id === item.id ? { ...newItem } : item)),
        };
      }

      return group;
    });

    handleQuoteUpdate && handleQuoteUpdate(newQuoteDetails);
  };

  return (
    <>
      {quoteGroups.map((quoteGroup) => {
        const groupSearchResults = searchResults?.find(({ id }) => id === quoteGroup.id);
        return (
          <Group
            key={quoteGroup.id + quoteGroup.groupName}
            handleOnDragOver={handleDragOver}
            handleOnDrop={handleOnDrop}
            handleOnGroupDrag={handleGroupOnDrag}
            handleItemOnDrag={handleItemOnDrag}
            handleItemUpdate={handleItemUpdate}
            handleAddPart={handleAddPart}
            handleRemoveGroup={handleRemoveGroup}
            handleCloneGroup={handleCloneGroup}
            handleRenameGroup={handleRenameGroup}
            handleRemoveItem={handleRemoveItem}
            groupData={quoteGroup}
            results={groupSearchResults?.results ?? []}
            searchPartNumbers={searchPartNumbers}
            readonly={readonly}
            allowRemovalOnReadOnly={allowRemovalOnReadOnly}
            isQuoteOrdered={isQuoteOrdered}
          />
        );
      })}
      {oneTimeDiscountPrice ? (
        <TotalRow
          backgroundColor="#0B38DB"
          textColor="#fff"
          totalText="One Time Discount"
          price={oneTimeDiscountPrice}
          showContactMessage={false}
        />
      ) : null}
      <TotalRow
        backgroundColor="#0B38DB"
        textColor="#fff"
        totalText="Total"
        price={quoteTotalPrice}
        showContactMessage={false}
      />
    </>
  );
};

export { QuoteDetailTable };
