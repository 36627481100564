import styled from '@emotion/styled';
import { ButtonRounded } from '@Calix-Commerce/design-system/components';
import { ButtonLink } from 'componentsMigrated/ButtonLink';
import { Flex } from '@Calix-Commerce/design-system/layout';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

export const Title = styled.h1`
  font-size: 2.6rem;
  font-weight: normal;
  margin: 0 0 1.43rem;
`;

export const TotalPrice = styled.div`
  font-size: 1.75rem;
  font-weight: 700;
  color: #0b38db;
  text-align: right;
`;

export const GroupNameInput = styled.input`
  font-weight: bold;
  font-size: 1.15em;
  background: none;
  color: #fff;
  border: none;
  width: 60%;

  &:focus {
    background: #fff;
    color: #000;
  }
`;

export const quantityInputStyles = {
  width: '50%',
  margin: '5px 0',
};

export const quantityTrashStyles = {
  height: '24px',
  alignSelf: 'center',
  cursor: 'pointer',
};

export const ButtonWrapper = styled(Flex)`
  margin-top: 20px;
  gap: 16px;
`;

export const NotificationWrapper = styled.div`
  margin: 15px 0;
`;

export const IconActionWrapper = styled.button`
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'default')};
  opacity: ${({ disabled }) => (!disabled ? '100%' : '.65')};

  color: #0b38db;
  display: flex;
  background: none;
  border: none;
  align-items: center;

  svg {
    height: 30px;
    fill: #0b38db;
    margin-right: 5px;
  }

  &:hover {
    color: ${({ disabled }) => (!disabled ? '#ff7600' : '#0b38db')};

    svg {
      fill: ${({ disabled }) => (!disabled ? '#ff7600' : '#0b38db')};
    }

    .add-new-group {
      stroke: ${({ disabled }) => (!disabled ? '#ff7600' : '#0b38db')};
    }
  }
`;

export const CheckoutButton = styled(ButtonRounded)`
  margin-right: 0;
  margin-left: auto;
`;

export const ProductDetailsButton = styled(ButtonLink)`
  padding: 0;

  .MuiButton-label {
    color: #4ab1b4;
    font-size: 1.43rem;
  }

  &:hover {
    text-decoration: none;
  }
`;
export const BomListTable = styled.table`
  width: 100%;
  margin-top: 0.5rem;
  border-bottom: 1px solid #000;
  border-collapse: collapse;
  text-align: left;

  thead {
    border-bottom: 1px solid #000;
  }

  th {
    color: #000;
    font-weight: normal;
  }

  .whiteBackground {
    background-color: #fff;
  }

  .darkBackground {
    background-color: #f5f5f5;
  }

  td {
    padding: 1.25rem;
    padding-left: 0;
  }
`;

export const IconTableHeader = styled.th`
  button {
    border: none;
    background: none;
  }

  svg {
    height: 24px;
    width: 39px;
    cursor: pointer;
    fill: #fff;
  }
`;

export const AddMenu = styled.div`
  border: 1px solid #a0a0a0;
  background: #fff;
  width: 300px;
`;

export const AddMenuHeader = styled(Flex)`
  padding: 0.71rem;
  background: #f0f0f0;
  width: 100%;

  button {
    padding: 0;
    min-width: auto;
    margin-right: 0.3rem;
  }
`;

export const AddMenuFooter = styled(Flex)`
  padding: 0.71rem;
  width: 100%;
`;

export const QuoteDetailGroupName = styled.span`
  font-weight: bold;
  font-size: 1.15em;
`;

export const TrashIconButton = styled.button`
  background: none;
  border: none;
  &:hover {
    svg {
      fill: #ff7600;
    }
  }
`;

export const QuoteGroupDragicon = styled(DragIndicatorIcon)`
  cursor: move !important;
`;

export const QuoteQuantityText = styled.span`
  width: 55px;
`;
