import { gql } from '@apollo/client';

export const ACCOUNT_DATA_FRAGMENT = gql`
  fragment accountData on Account {
    accountId
    name
    priceType
    businessCountry
    commerceComplete
    defaultCurrencyCode
  }
`;

export const ACCOUNT_LEAD_DATA_FRAGMENT = gql`
  fragment accountLeadData on Account {
    accountLead {
      id
      name
      email
      userImageUrl
      workPhone
      mobilePhone
    }
  }
`;
