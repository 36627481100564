import { useState, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import {
  Container,
  EmojiList,
  EmojiText,
  FeedbackComment,
  FeedbackConfirmationHeading,
} from './styledComponents';
import { ButtonRounded } from '@Calix-Commerce/design-system/components';
import { Flex } from '@Calix-Commerce/design-system/layout';

import { SEND_FEEDBACK } from 'connectors/mutations/feedback';
import { FEEDBACK_EMOJI_LIST, FEEDBACK_TEXT_LIST, FEEDBACK_SESSION_KEY } from 'utils/constants';

import { useRecoilState } from 'recoil';
import { feedbackCollectedState } from 'state/atoms';

const FeedbackConfirmation = () => {
  const [feedbackValue, feedbackSetValue] = useRecoilState(feedbackCollectedState);
  const [emojiTextHovered, setEmojiText] = useState('');
  const [isEmojiSelected, setIsEmojiSelected] = useState(false);
  const [emojiRatingSelected, SetEmojiRatingSelected] = useState(null);
  const [feedbackComment, setFeedbackComment] = useState('');
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);

  const navigate = useNavigate();
  const [sendFeedback] = useMutation(SEND_FEEDBACK, {
    errorPolicy: 'ignore',
  });

  const handleFeedbackSubmit = () => {
    feedbackSetValue({ isFeedbackCollected: true });
    sessionStorage.setItem(FEEDBACK_SESSION_KEY, String(true));

    sendFeedback({
      variables: {
        feedbackInput: {
          rating: emojiRatingSelected,
          comment: feedbackComment,
          context: 'QuoteManager',
        },
      },
    });
    setIsFeedbackSent(true);

    setTimeout(() => {
      navigate('/quote-manager/quotes');
    }, 1500);
  };

  const handleVisibleContentFeedback = () => {
    return (
      <>
        <FeedbackConfirmationHeading> How did we do ? </FeedbackConfirmationHeading>
        <Container>
          <Flex>
            <EmojiList>{mapEmojiList()}</EmojiList>
            {emojiTextHovered !== '' && <EmojiText>{emojiTextHovered}</EmojiText>}
          </Flex>
          <FeedbackComment
            placeholder="Additional Comments?"
            value={feedbackComment}
            onChange={(e) => setFeedbackComment(e.target.value)}
          />
        </Container>
      </>
    );
  };

  const handleButtonClose = () => {
    let onClick = () => handleFeedbackSubmit();
    let buttonMsg = 'SEND & CLOSE';

    if (emojiRatingSelected === null) {
      onClick = () => navigate('/quote-manager/quotes');
      buttonMsg = 'CLOSE';
    }

    return (
      <ButtonRounded onClick={onClick} style={{ marginTop: '40px' }}>
        {buttonMsg}
      </ButtonRounded>
    );
  };

  const mapEmojiList = () => {
    return FEEDBACK_EMOJI_LIST.map((emoji, idx) => {
      let classNameEmoji = '';
      let onCLickEmoji = () => selectEmoji(idx);

      if (emojiRatingSelected !== null) {
        classNameEmoji = emojiRatingSelected === idx + 1 ? 'show-emoji' : 'hide-emoji';
        onCLickEmoji = () => unselectEmoji();
      }

      return (
        <li
          className={classNameEmoji}
          onClick={onCLickEmoji}
          onMouseOver={() => showEmojiDescription(idx)}
          onMouseLeave={() => hideEmojiDescription()}
        >
          {emoji}
        </li>
      );
    });
  };

  const selectEmoji = (idx) => {
    setIsEmojiSelected(!isEmojiSelected);
    SetEmojiRatingSelected(idx + 1);
  };

  const unselectEmoji = () => {
    SetEmojiRatingSelected(null);
  };

  const showEmojiDescription = (emojiSelected) => {
    FEEDBACK_TEXT_LIST.filter((_, idx) => idx === emojiSelected).find((emojiText) => {
      return setEmojiText(emojiText);
    });
  };

  const hideEmojiDescription = useCallback(() => {
    setEmojiText('');
  }, []);

  return (
    <>
      {feedbackValue.isFeedbackCollected != true && handleVisibleContentFeedback()}

      {isFeedbackSent ? <p>Thanks for your feedback</p> : handleButtonClose()}
    </>
  );
};

export { FeedbackConfirmation };
