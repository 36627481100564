import styled from '@emotion/styled';

import { FormLabel as BaseFormLabel, InputIcon } from '@Calix-Commerce/design-system/components';

export const FormLabel = styled(BaseFormLabel)`
  font-size: 1.15rem;
  margin-bottom: 0.8rem;
`;

export const InputWrapper = styled(InputIcon)`
  max-width: 510px;

  input {
    border-radius: 0;

    &:placeholder-shown {
      font-style: italic;
    }
  }
`;
