import { getAuthData } from 'connectors/storage';
import { CALIX_EMPLOYEE, VAR } from 'connectors/userTypes';

import { authenticationState, feedbackCollectedState } from './atoms';
import {
  CURRENT_ACCOUNT_LOCAL_STORAGE,
  CURRENT_QUOTE_LOCAL_STORAGE,
  FEEDBACK_SESSION_KEY,
} from 'utils/constants';

import { storageSelectedAccountSelector, storageCurrentQuoteSelector } from './selectors';
import { defaultCurrentQuoteState } from './defaults';
import { CurrentQuoteState } from 'types';

export const initializeState = ({ set }) => {
  const authData = getAuthData();
  set(authenticationState, authData);

  const getStorageFeedbackValue = Boolean(sessionStorage.getItem(FEEDBACK_SESSION_KEY));
  set(feedbackCollectedState, { isFeedbackCollected: getStorageFeedbackValue });

  const currentAccount = localStorage.getItem(CURRENT_ACCOUNT_LOCAL_STORAGE);

  if (authData?.jwt?.userType === CALIX_EMPLOYEE || authData?.jwt?.userType === VAR) {
    const storageAccountData = currentAccount && JSON.parse(currentAccount);
    set(storageSelectedAccountSelector, storageAccountData || {});
  }

  const currentQuote = localStorage.getItem(CURRENT_QUOTE_LOCAL_STORAGE);

  const storageQuoteData: CurrentQuoteState =
    currentQuote && authData.isAuthenticated ? JSON.parse(currentQuote) : {};

  set(storageCurrentQuoteSelector, storageQuoteData || defaultCurrentQuoteState);
};
