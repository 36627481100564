import { getI18NText } from 'I18N';
import { useParams } from 'react-router-dom';
import { ReviewFormContainer } from '../styledComponents';
import { ReviewContainer, ReviewSubHeader } from './styledComponents';
import { downloadPoFile, readCheckoutSavedData } from 'utils/helpers/checkout';
import { Address } from 'types';

export const BillingDetails = () => {
  const { transactionId } = useParams();
  const checkoutData = readCheckoutSavedData(transactionId);
  const billingAddress = checkoutData.billingAddress || ({} as Address);

  return (
    <ReviewContainer>
      <ReviewSubHeader>{getI18NText('BILLING')}</ReviewSubHeader>
      <ReviewFormContainer>
        <tbody>
          <tr>
            <td>{getI18NText('ADDRESS_1')}:</td>
            <td>{billingAddress.address1}</td>
          </tr>
          <tr>
            <td>{getI18NText('ADDRESS_2')}:</td>
            <td>{billingAddress.address2}</td>
          </tr>
          <tr>
            <td>{getI18NText('CITY')}:</td>
            <td>{billingAddress.city}</td>
          </tr>
          <tr>
            <td>{getI18NText('STATE_PROVINCE_REGION')}:</td>
            <td>{billingAddress.state}</td>
          </tr>
          <tr>
            <td>{getI18NText('POSTAL_CODE')}:</td>
            <td>{billingAddress.zipcode}</td>
          </tr>
          <tr>
            <td>{getI18NText('COUNTRY')}:</td>
            <td>{billingAddress.country}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <br></br>
            </td>
          </tr>
          <tr>
            <td>{getI18NText('LEASING_AGENT')}:</td>
            <td>{checkoutData.lesingAgent ? getI18NText('YES') : getI18NText('NO')}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <br></br>
            </td>
          </tr>
          <tr>
            <td>{getI18NText('PO_NUMBER')}:</td>
            <td>{checkoutData.poNumber}</td>
          </tr>
          {checkoutData.poFile && (
            <tr>
              <td>{getI18NText('ATTACHMENTS')}:</td>
              <td>
                <a
                  className="review-ship-link"
                  onClick={() => {
                    downloadPoFile(
                      'pdf',
                      checkoutData.poFile.fileData,
                      checkoutData.poFile.fileName
                    );
                  }}
                >
                  {checkoutData.poFile.fileName}
                </a>
              </td>
            </tr>
          )}
        </tbody>
      </ReviewFormContainer>
    </ReviewContainer>
  );
};
