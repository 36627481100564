import { ReactElement, ReactNode, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import Cookie from 'js-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from '@Calix-Commerce/design-system/components';

import { authenticationState } from 'state/atoms';
import {
  CURRENT_ACCOUNT_LOCAL_STORAGE,
  CURRENT_QUOTE_LOCAL_STORAGE,
  USER_REQUEST_PATH_COOKIE,
} from 'utils/constants';
import { useExternalRedirect, useUserPermissions } from 'utils/hooks';

import { notAuthenticatedConfig, emptyAccessConfig, noAccessConfig } from './modalConfigs';
import { useSessionController } from './useSessionController';
import { useAccountLoad } from './useAccountLoad';
import { AuthenticationState } from 'types';
import { storageSelectedAccountSelector } from 'state/selectors';

const getModalConfig = ({
  isAuthenticated,
  eCommerceAccess,
  commerceComplete,
}: {
  commerceComplete: boolean;
} & AuthenticationState) => {
  if (!isAuthenticated) {
    return notAuthenticatedConfig;
  } else if (!eCommerceAccess.length || !commerceComplete) {
    return emptyAccessConfig;
  } else {
    return noAccessConfig;
  }
};

const AuthOverlay = ({ children }: { children: ReactNode }): ReactElement | null => {
  const navigate = useNavigate();
  const authentication = useRecoilValue(authenticationState);
  const selectedAccount = useRecoilValue(storageSelectedAccountSelector);
  const { pathname } = useLocation();
  const { hasQuoteAccess } = useUserPermissions();
  const { eCommerceAccess } = authentication;

  useSessionController();

  const { loading: isLoadingAccount, commerceComplete } = useAccountLoad();
  const { redirectFromPermissions } = useExternalRedirect();

  const isAccountSearchRoute = pathname === '/accounts' || pathname === '/account-search';

  // Allow access to /accounts page even if the user has no Quote access
  const canAccessPage = (eCommerceAccess.length && isAccountSearchRoute) || hasQuoteAccess;

  useEffect(() => {
    Cookie.remove(USER_REQUEST_PATH_COOKIE);

    if (!canAccessPage) {
      // Tries to redirect the user to other apps based on their permissions
      redirectFromPermissions();
    } else if (!selectedAccount?.accountId && isAccountSearchRoute) {
      // Redirects to account search if user has no account selected
      navigate('/accounts', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!authentication || !authentication.isAuthenticated) {
    localStorage.removeItem(CURRENT_QUOTE_LOCAL_STORAGE);
    localStorage.removeItem(CURRENT_ACCOUNT_LOCAL_STORAGE);
  }

  const renderApp = (): ReactElement => {
    return canAccessPage && commerceComplete ? (
      <>{children}</>
    ) : (
      <Modal isOpen position="top" {...getModalConfig({ ...authentication, commerceComplete })} />
    );
  };

  return !isLoadingAccount ? renderApp() : null;
};

export { AuthOverlay };
