import { GetRecoilValue, selector } from 'recoil';

import { END_CUSTOMER } from 'connectors/userTypes';
import {
  isCheckoutAllowed,
  hasWritePermission,
  isLockedByCurrentUser,
  isQuoteLockedByAnotherUser,
  isQuoteExpired,
} from 'utils/helpers/quoteValidation';

import {
  authenticationState,
  currentQuoteState,
  selectedAccountState,
  currentQuoteValidationState,
  loggedInAccountState,
} from './atoms';
import { CurrentQuoteState, ItemStatus } from 'types';
import { defaultCurrentQuoteValidationState } from './defaults';
import {
  hasCurrencyMismatchBetweenQuoteAndAccount,
  itemsHaveInvalidItemQuantity,
} from 'utils/helpers/quote';
import { CURRENT_ACCOUNT_LOCAL_STORAGE, CURRENT_QUOTE_LOCAL_STORAGE } from 'utils/constants';
import { QuoteStatus } from 'types/QuoteStatus';

// Defines the home url based on the user type
export const homeUrlSelector = selector({
  key: 'homeUrlSelector',
  get: ({ get }) => {
    const { userType } = get(authenticationState);

    return userType === END_CUSTOMER ? '/quote-manager/quotes' : '/accounts';
  },
});

export const storageCurrentQuoteSelector = selector({
  key: 'currentQuoteSelector',
  get: ({ get }: { get: GetRecoilValue }) => get(currentQuoteState),
  set: ({ get, set }, newQuoteState) => {
    const quoteValidation = get(currentQuoteValidationState);
    const account = get(selectedAccountState);
    const authenticationInformation = get(authenticationState);
    const { email } = authenticationInformation;
    const loggedInAccount = get(loggedInAccountState);

    const quoteData = newQuoteState as CurrentQuoteState;

    if (!quoteData || !Object.keys(quoteData).length) {
      set(currentQuoteValidationState, defaultCurrentQuoteValidationState);
      return;
    }

    const { transactionId, lockedBy, lockExpiry, items = [], modified } = quoteData || {};

    const isNewQuote = transactionId === '';

    // Some items like the Warranty Configurator don't have a part number and can be ignored
    const activeItems = items.filter(
      (item) => item.partNumber && item.status !== ItemStatus.REMOVED
    );

    const hasOutdatedDiscounts = activeItems.some((item) => item.discountOutdated);
    const hasUnavailableItems = activeItems.some(
      (item) => !item.available && item.available !== undefined
    );
    const isLockedByAnotherUser =
      isQuoteLockedByAnotherUser({ lockedBy, lockExpiry, email }) && !isNewQuote;
    const hasOneTimeDiscounts =
      quoteData.calculatedOneTimeDiscount > 0 || activeItems.some((item) => item.oneTimeDiscount);
    const hasItemsWithQuantityZero = activeItems.some((item) => item.quantity === 0);
    const hasInvalidItemQuantity = itemsHaveInvalidItemQuantity(activeItems);
    const hasWarrantyEligibleItems = activeItems.some((item) => item.extendedWarrantyEligible);
    const isEmpty = !activeItems.some((item) => item.status !== ItemStatus.REMOVED);
    const hasCurrencyMismatch = hasCurrencyMismatchBetweenQuoteAndAccount({
      authenticationInformation,
      selectedAccount: account,
      loggedInAccount,
      currentQuote: quoteData,
    });
    const hasEcommercePermission =
      hasWritePermission(quoteData.eCommercePermissions) || quoteData.isOrdered || isNewQuote;

    const allItemsQuotable = !activeItems.some((lineItem) => !lineItem.discountType);
    const isExpired = isQuoteExpired({ quote: quoteData });
    const isWritable =
      hasEcommercePermission &&
      !(
        hasOutdatedDiscounts ||
        hasUnavailableItems ||
        isLockedByAnotherUser ||
        hasCurrencyMismatch
      );

    set(currentQuoteValidationState, {
      ...quoteValidation,
      allItemsQuotable,
      isApproved: quoteData.quoteStatus === QuoteStatus.APPROVED,
      hasWritePermission: hasEcommercePermission,
      isExpired,
      isCheckoutAllowed: isCheckoutAllowed({
        quote: quoteData,
        isExpired,
        isWritable,
        isLockedByAnotherUser,
        hasCurrencyMismatch,
        hasOutdatedDiscounts,
        hasUnavailableItems,
        hasActiveItems: !isEmpty,
      }),
      isWritable,
      hasOutdatedDiscounts,
      hasUnavailableItems,
      hasOneTimeDiscounts,
      hasInvalidItemQuantity,
      hasItemsWithQuantityZero,
      hasWarrantyEligibleItems,
      hasCurrencyMismatch,
      isLockedByAnotherUser,
      isEmpty,
      isLockedByCurrentUser: isLockedByCurrentUser({ email, lockedBy, lockExpiry }),
      isModified: modified,
    });

    // Set quote state on local storage and Recoil
    localStorage.setItem(CURRENT_QUOTE_LOCAL_STORAGE, JSON.stringify(newQuoteState));
    set(currentQuoteState, newQuoteState);
  },
});

export const storageSelectedAccountSelector = selector({
  key: 'selectedAccountSelector',
  get: ({ get }) => get(selectedAccountState),
  set: ({ set }, newValue) => {
    localStorage.setItem(CURRENT_ACCOUNT_LOCAL_STORAGE, JSON.stringify(newValue));
    set(selectedAccountState, newValue);
  },
});
