import { useCallback, useMemo } from 'react';
import map from 'lodash/map';
import compact from 'lodash/compact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { FormGroup, DropdownOption } from '@Calix-Commerce/design-system/components';
import { Flex } from '@Calix-Commerce/design-system/layout';
import { getCreatedByOptions, getTagsOptions } from './options';
import { InputWrapper, FilterFormGroup } from './styledComponents';
import { FormLabel } from 'componentsMigrated/FormLabel';
import { UseFormReturn } from 'react-hook-form';
import { SearchFilterValues } from 'pages/Quotes/Quotes';
import { Quote } from 'types';
import { DropdownPrimaryMulti } from 'components';
import {
  QUOTE_SEARCH_INPUT_ID,
  QUOTE_SEARCH_SUBMIT_ID,
  QUOTE_SEARCH_CREATED_BY_FILTER_ID,
  QUOTE_SEARCH_TAGS_FILTER_ID,
} from 'utils/constants/selectors';
import { CREATED_BY_ALL_FILTER } from 'pages/Quotes/filters';

const SearchIcon = () => <FontAwesomeIcon icon={faSearch} size="lg" />;

const SearchFilters = ({
  formController,
  items = [],
}: {
  formController: UseFormReturn<SearchFilterValues>;
  items: Quote[];
}) => {
  const { register, control } = formController;

  const createdByOptions = useMemo(() => getCreatedByOptions(items), [items]);
  const tagsOptions = useMemo(() => getTagsOptions(items), [items]);

  const filterLabel = (selectedOptions: DropdownOption[]) => {
    const options = compact(selectedOptions);

    if (options.length > 3) {
      return `${selectedOptions.length} selected`;
    } else {
      const tagNames = map(options, 'text');
      return tagNames.join(', ');
    }
  };

  const filterCreatedByOptions = useCallback((options: DropdownOption[]) => {
    if (!options.length) {
      return [CREATED_BY_ALL_FILTER];
    }

    const lastSelectedOptionId = options[options.length - 1].id;

    if (lastSelectedOptionId === CREATED_BY_ALL_FILTER) {
      return [CREATED_BY_ALL_FILTER];
    }

    return options
      .filter((option) => option.id !== CREATED_BY_ALL_FILTER)
      .map((option) => option.id);
  }, []);

  return (
    <>
      <FormGroup>
        <InputWrapper
          Icon={<SearchIcon />}
          inputProps={{
            id: QUOTE_SEARCH_INPUT_ID,
            placeholder: 'Search by name, ID, or description',
            autoComplete: 'off',
            ...register('searchTerm'),
          }}
          buttonProps={{ id: QUOTE_SEARCH_SUBMIT_ID }}
        />
      </FormGroup>
      <Flex alignment="center" spacing="space-between">
        <FilterFormGroup>
          <FormLabel htmlFor={QUOTE_SEARCH_CREATED_BY_FILTER_ID}>Created by:</FormLabel>
          <DropdownPrimaryMulti
            name="createdByFilter"
            control={control}
            options={createdByOptions}
            defaultText="Filter by Created users"
            noOptionsMessage="No Users available"
            showFilter={!!createdByOptions.length}
            showResetOptions={true}
            formatLabel={filterLabel}
            id={QUOTE_SEARCH_CREATED_BY_FILTER_ID}
            style={{ width: '215px' }}
            defaultSelectedOptions={[CREATED_BY_ALL_FILTER]}
            filterSelectedOptions={filterCreatedByOptions}
          />
        </FilterFormGroup>
        <FilterFormGroup>
          <DropdownPrimaryMulti
            name="tagsFilter"
            control={control}
            options={tagsOptions}
            defaultText="Filter by Tags"
            noOptionsMessage="No tags available"
            showFilter={!!tagsOptions.length}
            showResetOptions={true}
            id={QUOTE_SEARCH_TAGS_FILTER_ID}
            formatLabel={filterLabel}
            style={{ width: '190px' }}
          />
        </FilterFormGroup>
      </Flex>
    </>
  );
};

export { SearchFilters };
