import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  Breadcrumb as BaseBreadcrumb,
  BreadcrumbHomeIcon,
} from '@Calix-Commerce/design-system/components';
import { homeUrlSelector } from 'state/selectors';
import { ReactElement } from 'react';
import { useAppNavigation } from 'utils/hooks';

const Breadcrumb = (): ReactElement | null => {
  const navigate = useNavigate();
  const location = useLocation();
  const homeUrl = useRecoilValue(homeUrlSelector);
  const { isCheckout } = useAppNavigation();

  if (isCheckout) {
    return null;
  }

  const showHomeIcon = location.pathname !== homeUrl;

  return (
    <BaseBreadcrumb>
      {showHomeIcon ? <BreadcrumbHomeIcon onClick={() => navigate(homeUrl)} /> : null}
    </BaseBreadcrumb>
  );
};

export { Breadcrumb };
