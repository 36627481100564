import {
  Quote,
  CurrentQuoteState,
  LineItem,
  ItemStatus,
  QuoteInput,
  AddressInput,
  PhoneInput,
  QuoteInformation,
  QuoteGroup as CurrentQuoteQuoteGroup,
  QuoteGroupInput,
  Account,
  AuthenticationState,
  QuoteGroupDetail,
} from 'types';
import {
  CONTACT_COPS_LABEL,
  NOT_AVAILABLE_LABEL,
  QUOTE_ITEM_EXTENDED_WARRANTY_LINE,
  UNASSIGNED_GROUP_ID,
  UNASSIGNED_GROUP_LABEL,
} from 'utils/constants';
import uniq from 'lodash/uniq';
import { VAR } from 'connectors/userTypes';

export const getQuoteId = (quote: Quote) => {
  const { projectId, option, revisionNumber } = quote;

  return `${projectId}${option}-${revisionNumber}`;
};

export const getQuoteName = () => {
  return `Quote${new Date().getTime()}`;
};

// Get items grouped by group name
export const getItemsByGroup = (items: LineItem[] = []) => {
  return items.reduce(
    (groups, item) => {
      if (item.status === ItemStatus.REMOVED) {
        return groups;
      }

      const groupName = item.groupName || UNASSIGNED_GROUP_LABEL;
      const groupItems: LineItem[] = groups[groupName] || [];

      return { ...groups, [groupName]: [...groupItems, item] };
    },
    { [UNASSIGNED_GROUP_LABEL]: [] as LineItem[] } as { [k: string]: LineItem[] }
  );
};

export const getQuoteGroupMapping = (
  currentQuote: CurrentQuoteState,
  formatPrice: (input) => string,
  showWarrantyItems?: boolean
): QuoteGroupDetail[] => {
  const includeWarrantyItems =
    (currentQuote.isOrdered || showWarrantyItems) &&
    currentQuote.items.some(({ extendedWarrantyEligible }) => extendedWarrantyEligible);

  const activeItems = currentQuote.items.filter((item) => item.status !== ItemStatus.REMOVED);

  const groups = includeWarrantyItems
    ? ([
        ...currentQuote.quoteGroups,
        { id: 'extendedWarranty', name: 'Extended Warranty', isWarranty: true },
      ] as CurrentQuoteQuoteGroup[])
    : currentQuote.quoteGroups;

  return groups.map((quoteGroup) => {
    //@ts-expect-error isWarranty is temporary boolean to identify isWarranty
    const { isWarranty, ...group } = quoteGroup;
    const items = isWarranty
      ? activeItems.filter((item) => item.priceLine === QUOTE_ITEM_EXTENDED_WARRANTY_LINE)
      : activeItems.filter(
          (item) =>
            item.groupNumber === group.id &&
            item.partNumber &&
            item.priceLine !== QUOTE_ITEM_EXTENDED_WARRANTY_LINE
        );

    if (items.length === 0) {
      return {
        id: group.id,
        groupName: group.name,
        isUnassigned: group.id === UNASSIGNED_GROUP_ID,
        displaySubTotal: formatPrice(0),
        items: [],
      };
    }

    const groupItems = items.map((item) => {
      const priceWarnings: string[] = [];
      const itemNotes: string[] = [];
      const itemWarnings: string[] = [];

      if (item.oneTimeDiscount) {
        priceWarnings.push('Deal Specific Price Not Ongoing');
      }

      if (item.customSku) {
        itemNotes.push('This item is a custom SKU');
      }

      if (item.extendedWarrantyEligible) {
        itemNotes.push('This item is extended warranty eligible');
      }

      if (item.hasWarrantyEligibleItems) {
        itemNotes.push(
          'This package contains one or more items that are extended warranty eligible'
        );
      }

      if (!item.available && !currentQuote.isOrdered) {
        itemWarnings.push(
          'This part is no longer available. Please remove it from your quote to continue.'
        );
      }

      return {
        ...item,
        id: String(item.sequenceNumber + currentQuote.quoteNumber),
        imgSource: item?.images?.at(0)?.imageAbsoluteUrl || '',
        packageBom: item?.packageBom?.length ? item.packageBom : [],
        isWarrantyEligible: false,
        hasWarrantyEligibleItems: false,
        priceWarnings,
        itemNotes,
        itemWarnings,
      };
    });

    const subTotal = groupItems.reduce((total, item) => {
      return total + (item.totalPrice ? item.totalPrice : 0);
    }, 0);

    let displaySubTotal = formatPrice(subTotal);

    const contactCOps =
      groupItems.some(({ discountType }) => discountType === null) && !currentQuote.isOrdered;
    const someItemUnavailable =
      groupItems.some(({ available }) => !available) && !currentQuote.isOrdered;
    if (contactCOps) {
      displaySubTotal = CONTACT_COPS_LABEL;
    } else if (someItemUnavailable) {
      displaySubTotal = NOT_AVAILABLE_LABEL;
    }

    return {
      id: group.id,
      groupName: isWarranty ? 'Extended Warranty Summary' : group.name,
      isUnassigned: group.id === UNASSIGNED_GROUP_ID,
      items: groupItems,
      subTotalText: isWarranty ? 'Extended Warranty Total' : 'Sub-Total',
      displaySubTotal,
    };
  });
};

export const itemsHaveInvalidItemQuantity = (items: LineItem[]) => {
  const availableItems = items.filter((item) => ItemStatus.REMOVED !== item.status);
  const partNumbers = uniq(availableItems.map(({ partNumber }) => partNumber));

  return partNumbers.some((partNumber) => {
    const itemData = availableItems.find((item) => item.partNumber === partNumber);
    const maximumOrderQuantity = itemData?.maximumOrderQuantity;
    const minimumOrderQuantity = itemData?.minimumOrderQuantity;

    const itemQuantity = availableItems.reduce((total, item) => {
      return item.partNumber === partNumber ? total + item.quantity : total;
    }, 0);

    return (
      (maximumOrderQuantity !== null && itemQuantity > Number(maximumOrderQuantity)) ||
      (minimumOrderQuantity !== null && itemQuantity < Number(minimumOrderQuantity))
    );
  });
};

export const getInvalidQtyItems = (items: LineItem[]) => {
  const availableItems = items.filter((item) => ItemStatus.REMOVED !== item.status);
  const partNumbers = uniq(availableItems.map(({ partNumber }) => partNumber));
  let invalidItems: LineItem[] = [];

  partNumbers.forEach((partNumber) => {
    const itemsWithPartNumber = availableItems.filter((item) => item.partNumber === partNumber);
    const maximumOrderQuantity = itemsWithPartNumber.at(0)?.maximumOrderQuantity;
    const minimumOrderQuantity = itemsWithPartNumber.at(0)?.minimumOrderQuantity;

    const itemQuantity = itemsWithPartNumber.reduce((total, item) => {
      return total + item.quantity;
    }, 0);

    if (
      (maximumOrderQuantity !== null && itemQuantity > Number(maximumOrderQuantity)) ||
      (minimumOrderQuantity !== null && itemQuantity < Number(minimumOrderQuantity))
    ) {
      invalidItems = [...invalidItems, ...itemsWithPartNumber];
    }
  });

  return invalidItems;
};

export const mapLineItems = ({
  documentNumber,
  sequenceNumber,
  quantity,
  groupNumber,
  partNumber,
  priceLine,
}) => {
  const lineItem = {
    sequenceNumber,
    documentNumber,
    quantity,
    groupNumber,
    partNumber: partNumber || '',
  };

  if (priceLine === QUOTE_ITEM_EXTENDED_WARRANTY_LINE) {
    delete lineItem.quantity;
  }

  return lineItem;
};

export const mapAddress = ({
  name = '',
  address1 = '',
  address2 = '',
  city = '',
  country = '',
  state = '',
  zipcode = '',
}) => ({ name, address1, address2, city, country, state, zipcode }) as AddressInput;

export const mapPhone = ({
  // Form Format
  phoneCountry = null,
  phoneArea = null,
  phoneNumber = null,
  phoneExtension = null,

  // API Format fallback
  area = null,
  country = null,
  extension = null,
  number = null,
}) =>
  ({
    countryCode: phoneCountry || country,
    areaCode: phoneArea || area,
    number: phoneNumber || number,
    extension: phoneExtension || extension,
  }) as PhoneInput;

export const formatPhoneNumber = (phoneInformation: PhoneInput) => {
  return (
    '+' +
    phoneInformation.countryCode +
    ' (' +
    phoneInformation.areaCode +
    ') ' +
    phoneInformation.number +
    (phoneInformation.extension ? ', extension ' + phoneInformation.extension : '')
  );
};

const isWarrantyItem = (quoteItem: LineItem) => {
  const acceptableModelNames = ['Warranty', 'Post Quote Config External'];
  return quoteItem.modelName && acceptableModelNames.indexOf(quoteItem.modelName) > -1;
};

export const hasCurrencyMismatchBetweenQuoteAndAccount = ({
  authenticationInformation,
  selectedAccount,
  loggedInAccount,
  currentQuote,
}: {
  authenticationInformation: AuthenticationState;
  selectedAccount: Account;
  loggedInAccount: Account;
  currentQuote: CurrentQuoteState;
}) => {
  if (authenticationInformation.userType === VAR) {
    return loggedInAccount.currencyCode !== currentQuote.currencyCode;
  }

  return selectedAccount.currencyCode !== currentQuote.currencyCode;
};

export const getQuoteInputFromQuote = (quoteData: QuoteInformation) => {
  const mappedQuote: QuoteInput = {};

  mappedQuote.currencyCode = quoteData.currencyCode;
  mappedQuote.quoteName = quoteData.quoteName;
  mappedQuote.quoteDescription = quoteData.quoteDescription;

  if (typeof quoteData.archived !== 'undefined') {
    mappedQuote.archived = quoteData.archived;
  }

  if (typeof quoteData.lockedBy !== 'undefined') {
    mappedQuote.lockedBy = quoteData.lockedBy;
  }

  if (typeof quoteData.unlock !== 'undefined') {
    mappedQuote.unlock = false;
  }

  if (typeof quoteData.submitOrder !== 'undefined' && quoteData.submitOrder) {
    mappedQuote.submitOrder = quoteData.submitOrder;
  }

  // if (Array.isArray(quoteData.confirmationRecipients)) {
  //   mappedQuote.confirmationRecipients = quoteData.confirmationRecipients;
  // }

  // mappedquoteData.quote.lockExpiry = quoteData.lockExpiry;
  if (Array.isArray(quoteData.tags)) {
    mappedQuote.tags = quoteData.tags;
  }

  mappedQuote.quoteGroups = quoteData.quoteGroups
    .filter(({ id }) => (typeof id === 'string' ? parseInt(id) > 1 : id > 1))
    .map(({ name }) => {
      return { name };
    });

  mappedQuote.quoteGroups = [
    ...mappedQuote.quoteGroups,
    ...Array<QuoteGroupInput>(40 - mappedQuote.quoteGroups.length).fill({ name: null }),
  ];

  const quoteGroupsIndex = mappedQuote.quoteGroups.reduce(
    (indexObject, group, index) => ({ ...indexObject, ...{ [group.name || index]: index + 1 } }),
    {}
  );
  mappedQuote.lineItems = [];

  if (quoteData.items && quoteData.items.length) {
    let sequenceNumber = 1;
    const itemsWithAdjustedSequence = quoteData.items
      .filter(({ status }) => quoteData.transactionId || status !== ItemStatus.REMOVED)
      .map((item) => ({
        ...item,
        status: item.status ?? ItemStatus.MODIFIED,
        groupNumber: quoteGroupsIndex[item.groupName],
        sequenceNumber:
          item.status !== ItemStatus.REMOVED && !isWarrantyItem(item)
            ? sequenceNumber++
            : item.sequenceNumber,
      }))
      .map((item) =>
        //Add sequence number to warranty header
        //Warranty header doesn't have partNumber
        ({
          ...item,
          status: item.status ?? ItemStatus.MODIFIED,
          sequenceNumber:
            item.status !== ItemStatus.REMOVED && isWarrantyItem(item) && !item.partNumber
              ? sequenceNumber++
              : item.sequenceNumber,
        })
      )
      .map((item) =>
        //Add sequence number to extended warranty items
        ({
          ...item,
          status: item.status ?? ItemStatus.MODIFIED,
          sequenceNumber:
            item.status !== ItemStatus.REMOVED && isWarrantyItem(item) && item.partNumber
              ? sequenceNumber++
              : item.sequenceNumber,
        })
      );

    const createLineItems = itemsWithAdjustedSequence
      .filter((item) => item.status === ItemStatus.CREATE)
      .map((item) => ({ ...mapLineItems(item), action: 'create' }));
    const updateLineItems = itemsWithAdjustedSequence
      .filter((item) => item.status === ItemStatus.MODIFIED)
      .map((item) => ({ ...mapLineItems(item), action: 'update' }));
    const deleteLineItems = itemsWithAdjustedSequence
      .filter((item) => item.status === ItemStatus.REMOVED)
      .map((item) => ({
        documentNumber: item.documentNumber!,
        partNumber: item.partNumber,
        action: 'delete',
      }));

    mappedQuote.lineItems = [...createLineItems, ...updateLineItems, ...deleteLineItems];
  }

  if (quoteData?.shippingAddress?.address1) {
    mappedQuote.shippingAddress = { ...quoteData.shippingAddress, province: '' };
  }
  mappedQuote.packingInstructions = quoteData.packingInstructions || undefined;
  mappedQuote.shippingInstructions = quoteData.shippingInstructions || undefined;
  mappedQuote.multipleShipments = quoteData.multipleShipments || undefined;
  mappedQuote.shipmentSchedule = quoteData.shipmentSchedule || undefined;
  mappedQuote.requestedShippingType = quoteData.requestedShippingType || undefined;
  mappedQuote.requestedShippingDate = quoteData.requestedShippingDate || undefined;

  mappedQuote.shippingContactFirstName = quoteData.shippingContactFirstName || undefined;
  mappedQuote.shippingContactLastName = quoteData.shippingContactLastName || undefined;

  mappedQuote.shippingContactPhone = quoteData.shippingContactPhone;
  mappedQuote.shippingContactEmail = quoteData.shippingContactEmail || undefined;

  if (quoteData?.billingAddress?.address1) {
    mappedQuote.billingAddress = { ...quoteData.billingAddress, province: '' };
  }

  mappedQuote.lesingAgent = quoteData.lesingAgent;
  mappedQuote.taxExempt = quoteData.taxExempt || undefined;
  mappedQuote.poNumber = quoteData.poNumber || undefined;

  return mappedQuote;
};

export const mapCurrentQuoteStateFromResponse = (quoteResponse, formatPrice): CurrentQuoteState => {
  const { lineGroups, ...responseData } = quoteResponse;

  let quoteGroups = (
    responseData?.quoteGroups?.filter(({ name }) => name !== UNASSIGNED_GROUP_LABEL && name) || []
  )
    .reverse()
    .map(({ name }, index) => {
      return { name, id: index + 2 };
    })
    .reverse();

  //order of groups is the order it is displayed in
  quoteGroups = [...quoteGroups, { name: UNASSIGNED_GROUP_LABEL, id: UNASSIGNED_GROUP_ID }];

  const items =
    lineGroups?.reduce((items: LineItem[], group) => {
      return [
        ...items,
        ...(group?.lineItems?.map((item) => {
          const groupInfo =
            group.groupId === null
              ? { name: UNASSIGNED_GROUP_LABEL, id: UNASSIGNED_GROUP_ID }
              : quoteGroups.find(({ name }) => name === item.groupName);

          return {
            ...item,
            groupName: groupInfo?.name,
            groupNumber: groupInfo?.id,
            sellPrice: Number(item.salePrice),
            itemPriceLabel: formatPrice(item.totalPrice),
            description: item.partDescription,
          };
        }) || []),
      ];
    }, []) || [];

  return {
    ...responseData,
    items,
    quoteGroups,
    modified: false,
  };
};
