import styled from '@emotion/styled';

export const InfoStyled = styled.div`
  border-top: 1px solid #ccc;
  display: flex;
  margin: 0 1rem 2rem;

  .shipment-schedule-details {
    .shipment-group-header {
      border-bottom: 1px solid #ccc;
      width: 100%;

      span {
        margin: 0;
      }

      .ship-date {
        width: 84%;
      }

      .quantity {
        width: 16%;
        font-weight: normal;
        text-align: right;
      }
    }

    .shipment-schedule-list {
      width: 100%;

      span {
        margin: 0 0 10px 0;
      }

      .part-name {
        width: 84%;
        font-weight: normal;
      }

      .quantity {
        width: 16%;
        font-weight: normal;
        text-align: right;
      }
    }
  }
`;

export const Block = styled.div`
  border-right: 1px solid #ccc;
  padding: 0.5rem 1.5rem 3rem;
  width: 50%;

  &:last-child {
    border-right: none;
  }
`;

export const Title = styled.h2`
  font-size: 1.75rem;
  color: #0b38db;
  font-weight: 700;
  display: inline-block;
  width: 100%;
`;

export const Item = styled.div<{ topMargin?: boolean }>`
  display: flex;

  span {
    display: inline-block;
    margin-bottom: 0.4rem;
    word-break: break-word;
    margin-top: ${({ topMargin }) => (topMargin ? '1.5rem' : '')};
  }

  span:first-of-type {
    font-weight: bold;
    min-width: 50%;
  }

  .review-link {
    color: #195d8d;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const AdditionalShippingContact = styled(Item)`
  flex-direction: column;

  span {
    margin-top: 0;
  }

  span:first-of-type {
    margin-top: ${({ topMargin }) => (topMargin ? '1.5rem' : '')};
  }

  a:link {
    color: #195d8d;
  }
  p {
    margin: 0;
  }
`;

export const Header = styled.h2`
  padding: 0 0 0 15px;
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
`;
