import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  BASE_NEW_QUOTE_ROUTE,
  BASE_SAVED_QUOTE_ROUTE,
  CHECKOUT_BILLING_PAGE,
  CHECKOUT_CONFIRMATION_PAGE,
  CHECKOUT_REVIEW_PAGE,
  CHECKOUT_SHIPPING_PAGE,
  CHECKOUT_WARRANTY_PAGE,
  ROUTE_MATCHING_PATTERNS,
  ROUTE_NAMES,
} from './PagePath';

export const useAppNavigation = () => {
  const { transactionId: urlTransactionId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const currentPage = useMemo(() => {
    return Object.keys(ROUTE_NAMES).find((route) =>
      pathname.match(ROUTE_MATCHING_PATTERNS[route])
    )! as ROUTE_NAMES;
  }, [pathname]);

  const isOneOfPages = useCallback(
    (pages: ROUTE_NAMES[]) => pages.includes(currentPage),
    [currentPage]
  );

  const isDetails = useMemo(() => {
    return isOneOfPages([ROUTE_NAMES.QUOTE_DETAILS, ROUTE_NAMES.NEW_QUOTE_DETAILS]);
  }, [isOneOfPages]);

  const isSearch = useMemo(() => {
    return isOneOfPages([ROUTE_NAMES.QUOTE_SEARCH, ROUTE_NAMES.NEW_QUOTE_SEARCH]);
  }, [isOneOfPages]);

  const isCheckout = useMemo(() => {
    return isOneOfPages([
      ROUTE_NAMES.CHECKOUT_WARRANTY,
      ROUTE_NAMES.CHECKOUT_SHIPPING,
      ROUTE_NAMES.CHECKOUT_BILLING,
      ROUTE_NAMES.CHECKOUT_REVIEW,
      ROUTE_NAMES.CHECKOUT_CONFIRMATION,
    ]);
  }, [isOneOfPages]);

  const redirectToAccountSearch = useCallback(() => {
    navigate('/accounts');
  }, [navigate]);

  const redirectToQuoteList = useCallback(() => {
    navigate(BASE_NEW_QUOTE_ROUTE);
  }, [navigate]);

  const redirectToPartSearchForNew = useCallback(() => {
    navigate(`${BASE_NEW_QUOTE_ROUTE}/new/search`);
  }, [navigate]);

  const redirectToQuoteDetailsForNew = useCallback(() => {
    navigate(`${BASE_NEW_QUOTE_ROUTE}/new/details`);
  }, [navigate]);

  const redirectToPartSearch = useCallback(
    (transactionId?: string) => {
      navigate(`${BASE_SAVED_QUOTE_ROUTE}/${transactionId || urlTransactionId}/search`);
    },
    [urlTransactionId, navigate]
  );

  const redirectToQuoteDetails = useCallback(
    (transactionId?: string) => {
      navigate(`${BASE_SAVED_QUOTE_ROUTE}/${transactionId || urlTransactionId}/details`);
    },
    [urlTransactionId, navigate]
  );

  const redirectToCheckoutWarranty = useCallback(
    (transactionId?: string) => {
      navigate(
        `${BASE_SAVED_QUOTE_ROUTE}/${transactionId || urlTransactionId}/${CHECKOUT_WARRANTY_PAGE}`
      );
    },
    [urlTransactionId, navigate]
  );

  const redirectToCheckoutShipping = useCallback(
    (transactionId?: string) => {
      navigate(
        `${BASE_SAVED_QUOTE_ROUTE}/${transactionId || urlTransactionId}/${CHECKOUT_SHIPPING_PAGE}`
      );
    },
    [urlTransactionId, navigate]
  );

  const redirectToCheckoutBilling = useCallback(
    (transactionId?: string) => {
      navigate(
        `${BASE_SAVED_QUOTE_ROUTE}/${transactionId || urlTransactionId}/${CHECKOUT_BILLING_PAGE}`
      );
    },
    [urlTransactionId, navigate]
  );

  const redirectToCheckoutReview = useCallback(
    (transactionId?: string) => {
      navigate(
        `${BASE_SAVED_QUOTE_ROUTE}/${transactionId || urlTransactionId}/${CHECKOUT_REVIEW_PAGE}`
      );
    },
    [urlTransactionId, navigate]
  );

  const redirectToCheckoutConfirmation = useCallback(
    (transactionId?: string) => {
      navigate(
        `${BASE_SAVED_QUOTE_ROUTE}/${transactionId || urlTransactionId}/${CHECKOUT_CONFIRMATION_PAGE}`
      );
    },
    [urlTransactionId, navigate]
  );

  return {
    currentPage,
    isCheckout,
    isDetails,
    isSearch,
    isOneOfPages,
    redirectToAccountSearch,
    redirectToQuoteList,
    redirectToPartSearchForNew,
    redirectToQuoteDetailsForNew,
    redirectToPartSearch,
    redirectToQuoteDetails,
    redirectToCheckoutWarranty,
    redirectToCheckoutShipping,
    redirectToCheckoutBilling,
    redirectToCheckoutReview,
    redirectToCheckoutConfirmation,
  };
};
