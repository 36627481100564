import { NoResultMessage } from '@Calix-Commerce/design-system/components';
import styled from '@emotion/styled';

const LinkText = styled.span`
  color: #0b38db;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
`;

export const NoQuotesMessage = ({ createQuote }: { createQuote: () => void }) => (
  <NoResultMessage>
    You do not currently have any saved quotes. Please click{' '}
    <LinkText onClick={createQuote}>New Quote</LinkText> to start a quote.
  </NoResultMessage>
);

export const NoFilterResultsMessage = ({ clearAllFilters }: { clearAllFilters: () => void }) => (
  <NoResultMessage>
    No results found using these filters. Please{' '}
    <a
      href="#"
      onClick={(event) => {
        event.preventDefault();
        clearAllFilters();
      }}
    >
      clear
    </a>{' '}
    filters to see results.
  </NoResultMessage>
);

export const NoMoreResultsMessage = () => (
  <NoResultMessage>
    No more results. Click{' '}
    <a
      href="#"
      onClick={(event) => {
        event.preventDefault();
        document.documentElement.scrollIntoView({
          behavior: 'smooth',
        });
      }}
    >
      here
    </a>{' '}
    to scroll to the top of the list.
  </NoResultMessage>
);
