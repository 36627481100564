import { Modal } from '@Calix-Commerce/design-system/components';
import styled from '@emotion/styled';

export const CheckoutModal = styled(Modal)`
  h2.MuiTypography-root {
    font-size: 1.25rem;
    font-weight: bold;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  p {
    font-size: 1rem;
    line-height: 150%;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
`;
