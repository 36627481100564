//This replicates the logic to parse account data from the Quote Manager

import { Account } from 'types';

// TODO : add types for data before handling below function
const mapAccountDetailsToStorage = (data): Account => {
  const getAccount = data && data.getAccount;

  if (!getAccount) {
    return {} as Account;
  }

  return {
    accountId: getAccount.accountId.substr(0, 15),
    accountName: getAccount.name,
    accountLead: getAccount.accountLead,
    currencyCode: getAccount.defaultCurrencyCode,
    businessCountry: getAccount.businessCountry,
    commerceComplete: getAccount.commerceComplete,
    accountManagerId: getAccount.accountManager && getAccount.accountManager.id,
    priceType: getAccount.priceType,
  };
};

// Filter accounts based on accountId and account Name to detect any duplicates and return the filtered list and the duplicated ones
const filterUniqueAccountsByIdAndName = (accounts: Account[]) => {
  const listed: { [k: string]: Account } = {};
  const duplicated: { [k: string]: Account } = {};

  const filtered = accounts.filter((account) => {
    const accountIdAndName = `${account.accountId}-${account.accountName}`;

    if (listed[accountIdAndName]) {
      duplicated[accountIdAndName] = account;
      return false;
    } else {
      listed[accountIdAndName] = account;
      return true;
    }
  });

  return {
    filtered,
    duplicated: Object.values(duplicated),
  };
};

// Filter accounts based on a param and return the filtered list and the duplicated ones
const filterUniqueAccountsByParam = (accounts: Account[] = [], param: string = '') => {
  const listed: { [k: string]: Account } = {};
  const duplicated: Account[] = [];

  const filtered = accounts.filter((account) => {
    const paramValue = account[param];
    const duplicatedAccount = listed[paramValue];

    if (duplicatedAccount) {
      duplicated.push(account);
      duplicated.push(duplicatedAccount);
      return false;
    } else {
      listed[paramValue] = account;
      return true;
    }
  });

  return { filtered, duplicated };
};

export { mapAccountDetailsToStorage, filterUniqueAccountsByIdAndName, filterUniqueAccountsByParam };
