import { gql } from '@apollo/client';
import { getAddresses } from 'connectors/fragments';
import { GET_ADDRESSES_OPERATION } from 'utils/constants';

export const GET_ADDRESSES = gql`
  query ${GET_ADDRESSES_OPERATION}($accountId: ID!, $varAccountId: ID) {
    getAccount(accountId: $accountId, varAccountId: $varAccountId) {
      accountId
      ...getAddresses
    }
  }
  ${getAddresses}
`;
