import { gql } from '@apollo/client';
import { FIND_DUPLICATE_PO_NUMBER_OPERATION } from 'utils/constants';
import { DUPLICATE_PO_NUMBER_RESULT_FRAGMENT } from 'connectors/fragments';

export const FIND_DUPLICATE_PO_NUMBER = gql`
  query ${FIND_DUPLICATE_PO_NUMBER_OPERATION}($poNumber: String!, $accountId: ID!) {
    getAccount(accountId: $accountId) {
      accountId
      findDuplicatedPoNumber(poNumber: $poNumber) {
        ...erpOrderSearchResult
      }
    }
  }
  ${DUPLICATE_PO_NUMBER_RESULT_FRAGMENT}
`;
