import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { useLazyQuery, useQuery } from '@apollo/client';

import { Container } from '@Calix-Commerce/design-system/layout';

import { END_CUSTOMER, VAR } from 'connectors/userTypes';
import { SEARCH_ACCOUNTS, GET_ACCOUNT } from 'connectors/queries/account';
import { notifyDuplicatedAccounts } from 'connectors/bugsnag/events';
import { authenticationState, loadingState, loggedInAccountState } from 'state/atoms';
import { defaultCurrentQuoteState } from 'state/defaults';
import { storageSelectedAccountSelector, storageCurrentQuoteSelector } from 'state/selectors';
import {
  useNotifyError,
  useExternalRedirect,
  useUserPermissions,
  useAppNavigation,
} from 'utils/hooks';
import { mapAccountDetailsToStorage } from 'utils/helpers/account';

import { Search, SearchResults } from './subcomponents';
import { useAccountResults } from './useAccountResults';
import { FETCH_POLICY } from 'utils/constants';
import { get15DigitId } from 'utils/helpers/salesforce';
import { resetSearchData } from 'utils/helpers/cache';

const PAGINATION_LIMIT = 50;

const Accounts = () => {
  const { addAccounts, resetAccounts, allAccounts, filteredAccounts, duplicatedAccounts } =
    useAccountResults();
  const notifyError = useNotifyError({ fallbackMessage: 'Unable to fetch account data.' });
  const { redirectToQuoteList } = useAppNavigation();
  const { redirectFromReturnUrl, redirectFromPermissions } = useExternalRedirect();
  const { hasQuoteAccess } = useUserPermissions();

  const setLoading = useSetRecoilState(loadingState);
  const setCurrentQuote = useSetRecoilState(storageCurrentQuoteSelector);
  const { userType } = useRecoilValue(authenticationState);
  const [selectedAccount, setSelectedAccount] = useRecoilState(storageSelectedAccountSelector);
  const loggedInAccount = useRecoilValue(loggedInAccountState);

  const {
    client,
    data: accountsData,
    loading: searchLoading,
    refetch,
  } = useQuery(SEARCH_ACCOUNTS, {
    // Variables for the initial account list
    variables: { searchTerm: '', limit: PAGINATION_LIMIT, offset: 0 },
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      addAccounts(data.searchAccounts.accounts);
    },
    onError: notifyError,
  });

  // Fetch specific account data when selecting a new account
  const [getAccount, { loading: accountLoading }] = useLazyQuery(GET_ACCOUNT, {
    onCompleted: (data) => {
      if (
        get15DigitId(data.getAccount.accountId) !== get15DigitId(selectedAccount.accountId || '')
      ) {
        // VARs and Calix Employees use the Accounts page
        // When the VAR is logged in, we have to use the VAR's currency.
        // When it's a CALIX_EMPLOYEE, then we have to use the selected account's currency
        setCurrentQuote({
          ...defaultCurrentQuoteState,
          accountId: selectedAccount.accountId,
          currencyCode:
            userType === VAR ? loggedInAccount.currencyCode : data.getAccount.defaultCurrencyCode,
        });

        resetSearchData(client.cache);
      }

      const newAccountData = mapAccountDetailsToStorage(data);
      setSelectedAccount(newAccountData);
      notifyDuplicatedAccounts(duplicatedAccounts);

      const hasReturnUrlRedirected = redirectFromReturnUrl();

      if (hasReturnUrlRedirected) {
        return null;
      } else if (hasQuoteAccess) {
        redirectToQuoteList();
      } else {
        redirectFromPermissions(); // Tries to redirect the user to other apps based on their permissions
      }
    },
    onError: notifyError,
  });

  const isPageLoading = searchLoading || accountLoading;

  useEffect(() => {
    setLoading(isPageLoading);
  }, [setLoading, isPageLoading]);

  useEffect(() => {
    if (userType === END_CUSTOMER) {
      redirectToQuoteList();
    }
  }, [userType, redirectToQuoteList]);

  const handleSearchSubmit = ({ searchTerm }) => {
    if (filteredAccounts.length) {
      resetAccounts(); // Reset previous results
    }

    refetch({ searchTerm: searchTerm.trim(), limit: PAGINATION_LIMIT, offset: 0 });
  };

  const handleEndOfPage = () => {
    const shouldFetchMore = !isPageLoading && accountsData && accountsData.searchAccounts.hasMore;

    if (shouldFetchMore) {
      refetch({ offset: allAccounts.length });
    }
  };

  return (
    <Container>
      <Search onSubmit={handleSearchSubmit} />
      <SearchResults
        accounts={filteredAccounts}
        loading={isPageLoading}
        onAccountSelect={({ accountId }) => getAccount({ variables: { accountId } })}
        onEndOfPage={handleEndOfPage}
      />
    </Container>
  );
};

export { Accounts };
