export enum ErrorMessage {
  READ_ONLY_QUOTE = 'READ_ONLY_QUOTE',
  HAS_ITEMS_WITH_QUANTITY_ZERO = 'HAS_ITEMS_WITH_QUANTITY_ZERO',
  HAS_INVALID_ITEM_QUANTITY = 'HAS_INVALID_ITEM_QUANTITY',
  MODIFIED_QUOTE = 'MODIFIED_QUOTE',
  HAS_CURRENCY_MISMATCH = 'HAS_CURRENCY_MISMATCH',
  HAS_ONE_TIME_DISCOUNTS = 'HAS_ONE_TIME_DISCOUNTS',
  QUOTE_LOCKED_BY_ANOTHER_USER = 'QUOTE_LOCKED_BY_ANOTHER_USER',
  EMPTY_QUOTE = 'EMPTY_QUOTE',
  HAS_NON_QUOTABLE_ITEM = 'HAS_NON_QUOTABLE_ITEM',
  HAS_NON_AVAILABLE_ITEM = 'HAS_NON_AVAILABLE_ITEM',
  QUOTE_NOT_READY_FOR_CHECKOUT = 'QUOTE_NOT_READY_FOR_CHECKOUT',
}
