import { useCallback, useMemo, useState } from 'react';

import { ButtonSlim, Tooltip } from '@Calix-Commerce/design-system/components';

import { useQuoteController } from 'utils/hooks';
import {
  PART_SEARCH_ADD_TO_QUOTE_CLASS,
  PART_SEARCH_QUANTITY_CLASS,
} from 'utils/constants/selectors';
import { defaultLineItem } from 'state/defaults';

import { AddMenuPopup } from './AddMenuPopup';
import { SuccessMessage } from './SuccessMessage';
import { QuantityInput } from './styledComponents';
import { PartSearchResponse } from 'types/state/Part';
import { QuoteGroup } from 'types';
import { roundPrice } from 'utils/helpers/price';
import { PriceDisplay } from 'components';
import { Flex } from '@Calix-Commerce/design-system';

const AddToQuote = ({
  product,
  isBackgroundLoading = false,
}: {
  product: PartSearchResponse;
  isBackgroundLoading: boolean;
}) => {
  const sellPrice = product.sellPrice;
  const productMinimumQuantity = product.minimumOrderQuantity ?? 1;
  const productMaximumQuantity = product.maximumOrderQuantity ?? undefined;

  const [quantity, setQuantity] = useState(productMinimumQuantity);
  const [isAddMenuOpen, setIsAddMenuOpen] = useState(false);
  const [showAddSuccess, setShowAddSuccess] = useState(false);
  const { addItem, currentQuoteCurrency } = useQuoteController();

  const tooltipContent = useMemo(() => {
    const content = [] as string[];

    if (typeof product.minimumOrderQuantity === 'number') {
      content.push(`Min. Qty: ${product.minimumOrderQuantity}`);
    }

    if (typeof product.maximumOrderQuantity === 'number') {
      content.push(`Max. Qty: ${product.maximumOrderQuantity}`);
    }

    return content.join(' / ');
  }, [product]);

  const handleQuantityChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(parseInt(event.target.value));
  }, []);

  const handleQuantityBlur = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let givenQuantity = parseInt(event.target.value);

      if (isNaN(givenQuantity)) {
        givenQuantity = productMinimumQuantity;
      } else if (
        typeof productMinimumQuantity === 'number' &&
        givenQuantity < productMinimumQuantity
      ) {
        givenQuantity = productMinimumQuantity;
      } else if (
        typeof productMaximumQuantity === 'number' &&
        givenQuantity > productMaximumQuantity
      ) {
        givenQuantity = productMaximumQuantity;
      }

      setQuantity(givenQuantity);
    },
    [productMinimumQuantity, productMaximumQuantity, setQuantity]
  );

  const addProductToQuote = (group: QuoteGroup) => {
    const hasWarrantyEligibleItems = product.bom.some(
      ({ productDetails }) => productDetails.extendedWarrantyEligible
    );

    addItem({
      ...defaultLineItem,
      ...product,
      groupNumber: group.id,
      groupName: group.name,
      quantity,
      totalPrice: roundPrice(quantity * sellPrice),
      sellPrice,
      packageBom: product.bom
        .filter((item) => item !== null)
        .map((bom) => {
          return {
            partNumber: bom.partNumber,
            quantity: bom.quantity,
            productDetails: {
              description: bom.productDetails.description,
              extendedWarrantyEligible: bom.productDetails.extendedWarrantyEligible,
            },
          };
        }),
      hasWarrantyEligibleItems,
    });
    setIsAddMenuOpen(false);
    setShowAddSuccess(true);
  };

  return (
    <div>
      <div>
        Price: {''}
        <PriceDisplay
          item={{ ...defaultLineItem, ...product }}
          currencyCode={currentQuoteCurrency}
        />
      </div>
      <Flex alignment="center">
        Quantity:
        <QuantityInput
          type="number"
          onChange={handleQuantityChange}
          onBlur={handleQuantityBlur}
          value={quantity}
          min={productMinimumQuantity}
          max={productMaximumQuantity}
          className={PART_SEARCH_QUANTITY_CLASS}
        />
        {tooltipContent && <Tooltip placement="right" content={tooltipContent} />}
      </Flex>

      <div style={{ position: 'relative' }}>
        <ButtonSlim
          style={{ padding: '0 0.43rem' }}
          onClick={() => setIsAddMenuOpen(!isAddMenuOpen)}
          disabled={isBackgroundLoading}
          className={PART_SEARCH_ADD_TO_QUOTE_CLASS}
        >
          Add to Quote
        </ButtonSlim>
        <AddMenuPopup
          show={isAddMenuOpen}
          onConfirm={addProductToQuote}
          onClose={() => setIsAddMenuOpen(false)}
          expandDuration={0.3}
        />
      </div>
      {showAddSuccess && <SuccessMessage onFinish={() => setShowAddSuccess(false)} />}
    </div>
  );
};

export { AddToQuote };
