import { gql } from '@apollo/client';
import { ACCOUNT_DATA_FRAGMENT, ACCOUNT_LEAD_DATA_FRAGMENT } from 'connectors/fragments';
import { GET_ACCOUNT_OPERATION, SEARCH_ACCOUNTS_OPERATION } from 'utils/constants';

export const GET_ACCOUNT = gql`
  query ${GET_ACCOUNT_OPERATION}($accountId: ID!) {
    getAccount(accountId: $accountId) {
      ...accountData
      ...accountLeadData
    }
  }
  ${ACCOUNT_DATA_FRAGMENT}
  ${ACCOUNT_LEAD_DATA_FRAGMENT}
`;

export const SEARCH_ACCOUNTS = gql`
  query ${SEARCH_ACCOUNTS_OPERATION}($searchTerm: String!, $limit: Int!, $offset: Int!) {
    searchAccounts(searchTerm: $searchTerm, limit: $limit, offset: $offset) {
      hasMore
      count
      limit
      accounts {
        ...accountData
      }
    }
  }
  ${ACCOUNT_DATA_FRAGMENT}
`;
