import { useEffect, useCallback, useRef } from 'react';
import { useSetRecoilState, useResetRecoilState } from 'recoil';
import Cookie from 'js-cookie';

import { authenticationState, infoModalState } from 'state/atoms';
import { LOGGED_IN_TOKEN_COOKIE, LOGGED_IN_JWT_COOKIE } from 'utils/constants';

const EXPIRATION_TIME_IN_MINUTES = 120;
const INACTIVE_WARNING_TIME_IN_MINUTES = 115;

const minutesToMilliseconds = (minutes) => minutes * 60 * 1000;

export const useSessionController = () => {
  // Counts down until it reaches the user inactivity threshold and triggers warning modal
  const inactivityTimer = useRef<NodeJS.Timeout | null>(null);

  const setInfoModal = useSetRecoilState(infoModalState);
  const resetInactivityModal = useResetRecoilState(infoModalState);

  const resetAuthentication = useResetRecoilState(authenticationState);

  const showInactivityModal = useCallback(() => {
    setInfoModal({
      open: true,
      config: {
        title: 'Update Session',
        content:
          'You have been inactive for a while. Your session will expire soon. Please click OK to extend your session.',
        flag: 'Warning',
        onDismiss: null,
        onConfirmation: resetInactivityModal,
      },
    });
  }, [resetInactivityModal, setInfoModal]);

  const handleUserActivity = useCallback(() => {
    const jwt = Cookie.get(LOGGED_IN_JWT_COOKIE);
    const token = Cookie.get(LOGGED_IN_TOKEN_COOKIE);
    const cookiesAreValid = jwt && token;

    if (cookiesAreValid) {
      // Valid cookies: reset inactivity timer and extend cookie expiration date
      const expirationTime = minutesToMilliseconds(EXPIRATION_TIME_IN_MINUTES);
      const inactivityTime = minutesToMilliseconds(INACTIVE_WARNING_TIME_IN_MINUTES);

      inactivityTimer.current && clearTimeout(inactivityTimer.current);
      inactivityTimer.current = setTimeout(showInactivityModal, inactivityTime);

      const expirationDate = new Date(new Date().getTime() + expirationTime);

      Cookie.set(LOGGED_IN_JWT_COOKIE, jwt, {
        expires: expirationDate,
        domain: import.meta.env.VITE_DEFAULT_COOKIE_DOMAIN,
      });

      Cookie.set(LOGGED_IN_TOKEN_COOKIE, token, {
        expires: expirationDate,
        domain: import.meta.env.VITE_DEFAULT_COOKIE_DOMAIN,
      });
    } else {
      // Invalid cookies: remove cookies and reset auth state
      Cookie.remove(LOGGED_IN_JWT_COOKIE);
      Cookie.remove(LOGGED_IN_TOKEN_COOKIE);
      resetInactivityModal();
      resetAuthentication();
    }
  }, [resetAuthentication, resetInactivityModal, showInactivityModal]);

  // Attach events to listen for user activity
  useEffect(() => {
    document.addEventListener('visibilitychange', handleUserActivity);
    document.addEventListener('mousedown', handleUserActivity);
    handleUserActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
