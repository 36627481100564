import TagManager from 'react-gtm-module';
import { ReactElement, useEffect } from 'react';
import { Footer as BaseFooter, FooterLink } from '@Calix-Commerce/design-system/components';

const Footer = (): ReactElement => {
  useEffect(() => {
    // Initialize GTM with 'pageOnLoad' event to trigger TrustArc
    // This is done in the footer to make sure the div is available
    TagManager.dataLayer({ dataLayer: { event: 'pageOnLoad' } });
  }, []);

  const termsLink = import.meta.env.VITE_CALIX_PORTAL_TERMS_URL;

  return (
    <BaseFooter style={{ marginTop: '3.55rem' }}>
      <span>
        <FooterLink href={termsLink}>© Calix | All Rights Reserved</FooterLink>
      </span>
      <span>
        <FooterLink href={termsLink}>Terms and Conditions</FooterLink>
      </span>
      <span>
        <FooterLink href={termsLink}>Privacy Policy</FooterLink>
      </span>
      <span id="trust-arc-placeholder" />
    </BaseFooter>
  );
};

export { Footer };
