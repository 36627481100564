import styled from '@emotion/styled';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import Cookie from 'js-cookie';
import TagManager from 'react-gtm-module';
import { client } from 'connectors/apollo';
import { initializeState } from 'state/initializeState';
import {
  BrowserFeaturesCheck,
  ErrorBoundary,
  Footer,
  AuthOverlay,
  LoaderOverlay,
  Breadcrumb,
  InfoModal,
  Header,
  Notification,
} from 'components';

import { PageRoutes } from './Routes';
import { GlobalStyles, ThemeProvider } from '@Calix-Commerce/design-system/globals';

const AppStyled = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.div`
  flex-grow: 1;
`;

TagManager.initialize({ gtmId: import.meta.env.VITE_GTM_ID });

// Default the cookie domain
Cookie.withAttributes({ domain: import.meta.env.VITE_DEFAULT_COOKIE_DOMAIN });

const App = () => {
  return (
    <RecoilRoot initializeState={initializeState}>
      <ErrorBoundary>
        <ThemeProvider>
          <GlobalStyles />
          <BrowserFeaturesCheck>
            <ApolloProvider client={client}>
              <LoaderOverlay />
              <Notification />
              <AppStyled>
                <InfoModal />
                {/* {loadGA ? <GoogleAnalytics /> : null} */}
                <BrowserRouter>
                  <AuthOverlay>
                    <Header />
                    <Breadcrumb />
                    <MainContent>
                      <PageRoutes />
                    </MainContent>
                    <Footer />
                  </AuthOverlay>
                </BrowserRouter>
              </AppStyled>
            </ApolloProvider>
          </BrowserFeaturesCheck>
        </ThemeProvider>
      </ErrorBoundary>
    </RecoilRoot>
  );
};

export default App;
