import { Flex } from '@Calix-Commerce/design-system/layout';
import { ButtonRounded } from '@Calix-Commerce/design-system/components';
import styled from '@emotion/styled';

export const CheckOutFooterContainer = styled(Flex)`
  position: relative;
  gap: 20px;
`;

export const ProgressButton = styled(ButtonRounded)`
  margin-top: 1rem;
`;
