import Cookie from 'js-cookie';

import { defaultAuthenticationState } from 'state/defaults';
import { CURRENT_ACCOUNT_LOCAL_STORAGE, LOGGED_IN_JWT_COOKIE } from 'utils/constants';

import { END_CUSTOMER, VAR } from './userTypes';
import { JWT } from 'types';

export const getJwt = () => Cookie.get(LOGGED_IN_JWT_COOKIE);

export const parseJwt = (token) => {
  let parsedData: JWT = {} as JWT;

  try {
    const base64Jwt = token.split('.')[1].replace('-', '+').replace('_', '/');
    parsedData = JSON.parse(window.atob(base64Jwt));
  } catch (e) {
    /* empty */
  }

  return parsedData;
};

const getAccountName = (accountData, jwt) => {
  const useJwtAccountName = jwt.userType === END_CUSTOMER || !accountData.accountName;

  return useJwtAccountName ? jwt.Account : accountData.accountName;
};

// Get auth data from Cookie
export const getAuthData = () => {
  const loggedInUserEcommJWT = getJwt();
  const currentAccount = localStorage.getItem(CURRENT_ACCOUNT_LOCAL_STORAGE);
  const accountData = currentAccount ? JSON.parse(currentAccount) : {};

  // If the cookie is missing the user is not isAuthenticated
  // so we will do nothing
  if (!loggedInUserEcommJWT) {
    console.error('Missing Auth Token');
    return defaultAuthenticationState;
  }

  // Get jwt data object
  const jwt = parseJwt(loggedInUserEcommJWT);
  if (!jwt.Account || !jwt.userType || !jwt.AccountSFid || !jwt.ECommerceAccess) {
    console.error('Auth Token Missing Required Data', jwt);
    return defaultAuthenticationState;
  }

  // Return an object with the auth data
  return {
    isAuthenticated: true,
    account: getAccountName(accountData, jwt),
    accountId: jwt.AccountSFid,
    endCustomerAccountId:
      jwt.userType === END_CUSTOMER ? jwt.AccountSFid : accountData.accountId || '',
    varAccountId: jwt.userType === VAR ? jwt.AccountSFid : '',
    ecomAccess: jwt.EcomAccess && jwt.EcomAccess.toLowerCase() === 'true',
    ecomCheckout: jwt.EcomCheckout && jwt.EcomCheckout.toLowerCase() === 'true',
    eCommerceAccess: jwt.ECommerceAccess,
    userSFid: jwt.UserSFid,
    fullName: `${jwt.FirstName} ${jwt.LastName}`,
    email: jwt.email,
    contactId: jwt.ContactId,
    userType: jwt.userType,
    userName: jwt.username,
    jwt: jwt,
    rawJwt: loggedInUserEcommJWT,
  };
};
