import styled from '@emotion/styled';

export const PrimaryTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th {
    text-align: left;
    padding: 0.65rem 0.57rem;
    color: #fff;
    background: #0b38db;
    line-height: 1.42rem;
    white-space: nowrap;
  }

  th:first-of-type {
    padding-left: 2.42rem;
  }
`;
