import { ApolloCache } from '@apollo/client';

type FilterCacheInput = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cache: ApolloCache<any>;
  prefixes: string[];
};

type ResetCacheInput = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cache: ApolloCache<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function resetSearchData(cache: ApolloCache<any>) {
  // Reset the search results to make sure we
  // are not using cached inputs from previous accounts
  const updatedQueries = filterCachedObjects({ cache, prefixes: ['searchPartsByTerm:'] });
  const updatedObjects = filterCachedObjects({ cache, prefixes: ['SearchPartsResponse:'] });

  resetCache({
    cache,
    data: { ...updatedObjects, ROOT_QUERY: updatedQueries },
  });
}

export function filterCachedQueries({ cache, prefixes }: FilterCacheInput) {
  const { ROOT_QUERY } = cache.extract();

  return Object.keys(ROOT_QUERY).reduce((refreshedCache, cacheKey) => {
    // Remove all keys related to Search Results
    const skipPrefix = prefixes.some((prefix) => cacheKey.startsWith(prefix));

    if (skipPrefix) {
      return refreshedCache;
    }

    refreshedCache[cacheKey] = ROOT_QUERY[cacheKey];

    return refreshedCache;
  }, {});
}

export function filterCachedObjects({ cache, prefixes }: FilterCacheInput) {
  const currentCache = cache.extract();

  return Object.keys(currentCache).reduce((refreshedCache, cacheKey) => {
    // Remove all keys related to Search Results
    const skipPrefix = prefixes.some((prefix) => cacheKey.startsWith(prefix));

    if (skipPrefix) {
      return refreshedCache;
    }

    refreshedCache[cacheKey] = currentCache[cacheKey];

    return refreshedCache;
  }, {});
}

export function resetCache({ cache, data }: ResetCacheInput) {
  cache.restore(data);
  cache.gc();
}
