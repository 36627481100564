import styled from '@emotion/styled';

import { FormLabel as BaseFormLabel, InputIcon } from '@Calix-Commerce/design-system/components';

export const Header = styled.h1`
  font-weight: 400;
  font-size: 2.5rem;
  color: #333;
  margin: 0 0 3.2rem;
  line-height: 1.1;
`;

export const FormLabel = styled(BaseFormLabel)`
  font-size: 1.15rem;
  margin-bottom: 1.2rem;
`;

export const InputWrapper = styled(InputIcon)`
  max-width: 432px;

  input {
    border-radius: 0;
    padding: 0 25px 0 15px;

    &:placeholder-shown {
      font-style: italic;
    }
  }
`;
