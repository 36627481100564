import { Container } from '@Calix-Commerce/design-system/layout';
import styled from '@emotion/styled';

export const ReviewSubHeader = styled.h3`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 28px;
  margin-bottom: 20px;
  color: #0b38db;
  font-size: 1.75rem;
  font-weight: bold;
`;

export const ReviewContainer = styled(Container)`
  border-left: 1px solid #e5e5e5;
`;

export const ReviewAdditionalConfirmation = styled(Container)`
  margin-top: 26px;

  .label {
    display: flex;
    width: 25%;
    align-items: center;
    color: #333;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 1rem;
    line-height: 150%;
    font-weight: bold;
  }

  .recipients-input {
    display: flex;
    width: 75%;
    gap: 26px;
    align-items: center;

    .input-container {
      display: flex;
      gap: 8px;
      align-items: flex-start;
      width: calc(100% - 160px);
      flex-direction: column;
      justify-content: center;

      .error-text {
        color: #a94442;
        font-size: 1rem;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      }
    }

    input {
      height: 34px;
      padding: 6px 12px;
    }

    .MuiButtonBase-root {
      padding: 0px 12px;
      height: 42px;
      width: 134px;
    }
  }

  .recipients-list {
    margin-left: 25%;
    margin-top: 24px;
    display: flex;
    gap: 20px;
    width: 75%;
    flex-wrap: wrap;
  }
`;

export const ReviewPreviewContent = styled.div`
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 16px;
  max-width: 530px;

  .example-container {
    margin-top: 26px;
    margin-bottom: 26px;

    .example-info {
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 1rem;
      line-height: 150%;
      color: #333;
      line-height: 150%;
    }
  }

  &:after {
    content: ' ';
    width: 0;
    height: 0;
    position: absolute;
    left: calc(50% - 10px);
    display: block;
    border-color: transparent;
    border-style: solid;
    border-width: 10px;
    border-top-color: #fff;
    border-bottom-width: 0;
  }
`;

export const RecipientItem = styled.div`
  display: flex;
  gap: 12px;
  height: 34px;
  line-height: 30px;
  color: #333;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  border: 1px solid #cad8f3;
  background-color: #dee7f8;
  padding: 0 7px;
  border-radius: 3px;

  &.item-disabled {
    opacity: 0.65;
  }

  .remove-icon {
    cursor: pointer;

    &:hover {
      color: #ff7600;
    }
  }
`;
