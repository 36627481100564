import { Container } from '@Calix-Commerce/design-system';
import { ExtendedWarranty } from '@Calix-Commerce/extended-warranty-component';
import { useWarrantyController } from './useWarrantyController';
import { withCheckoutPermissions } from 'hocs/withCheckoutPermissions';
import { useAppNavigation, useQuoteActionController } from 'utils/hooks';
import { withQuoteLoad } from 'hocs';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { storageCurrentQuoteSelector } from 'state/selectors';
import { CheckoutFooter } from 'components';
import { FETCH_POLICY } from 'utils/constants';
import { loadingState } from 'state/atoms';
import { CheckoutStepper } from 'components/stepper';
import { CheckoutTitle } from 'components/Checkout';
import { CURRENT_CHECKOUT_STEP } from 'utils/constants/checkout';
import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { SEARCH_PARTS_BY_TERM } from 'connectors/queries/partSearch';
import { withCheckoutAccessible } from 'hocs/withCheckoutAccessible';

const WarrantyPage = () => {
  const [searchForPart] = useLazyQuery(SEARCH_PARTS_BY_TERM);
  const setLoadingState = useSetRecoilState(loadingState);
  const { redirectToQuoteDetails, redirectToCheckoutShipping } = useAppNavigation();
  const { loadQuote } = useQuoteActionController();
  const quote = useRecoilValue(storageCurrentQuoteSelector);

  const {
    configState,
    currencyCode,
    warrantyInput,
    totalWithoutExtendedWarranty,
    handleWarrantyChange,
    handleWarrantySubmit,
  } = useWarrantyController({
    quote: { ...quote, warrantyInput: quote.warrantyInput || [] },
  });

  const backNavigationConfig = {
    navigate: async () => redirectToQuoteDetails(),
  };

  const nextNavigationValidation = async () => {
    setLoadingState(true);
    try {
      await handleWarrantySubmit();
      // We're intentionally not waiting for the load quote result
      // to expedite the page transition
      await loadQuote({
        transactionId: quote.transactionId,
        overwriteLocalQuote: true,
        fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
      });

      setLoadingState(false);
      return Promise.resolve();
    } catch (error) {
      setLoadingState(false);
      // TODO: handle warranty error
    }
  };

  useEffect(() => {
    // We're preloading the warranty parts to cache to expedite transition to Shipping page
    searchForPart({
      variables: {
        input: {
          requestedAccountId: quote.accountId,
          currencyCode: quote.currencyCode,
          // Extended Warranty Parts
          partNumbers: ['110-01162', '110-01163', '110-01164', '110-01165', '110-01418'],
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextNavigationConfig = {
    navigate: async () => redirectToCheckoutShipping(),
    validate: nextNavigationValidation,
  };

  return (
    <Container>
      <CheckoutStepper
        currentStep={CURRENT_CHECKOUT_STEP.WARRANTY}
        validate={nextNavigationValidation}
      />
      <CheckoutTitle id="checkout-warranty-title">Add Extended Warranty</CheckoutTitle>
      <ExtendedWarranty
        readOnly={false}
        showSelectAll={true}
        configs={warrantyInput}
        configState={configState}
        currency={currencyCode}
        onChange={handleWarrantyChange}
        quoteTotal={totalWithoutExtendedWarranty}
      />
      <CheckoutFooter
        backNavigationConfig={backNavigationConfig}
        nextNavigationConfig={nextNavigationConfig}
      />
    </Container>
  );
};

const CheckoutWarranty = withCheckoutPermissions(
  withQuoteLoad(withCheckoutAccessible(WarrantyPage))
);

export { CheckoutWarranty };
