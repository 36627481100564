import { ReactElement } from 'react';
import { ModalProps, Modal } from '@Calix-Commerce/design-system/components';

export type PriceUpdatedModalProps = Omit<Omit<ModalProps, 'title'>, 'content'> & {
  onConfirmation: () => void;
};

const PriceUpdatedModal = ({ onConfirmation, ...props }: PriceUpdatedModalProps): ReactElement => {
  return (
    <Modal
      title="Pricing Has Been Updated"
      content="The pricing of one or more items on this quote has changed. We will update this quote to reflect your new pricing."
      confirmButtonText="OK"
      onConfirmation={onConfirmation}
      {...props}
    />
  );
};

export { PriceUpdatedModal };
