import { useState } from 'react';
import { TrashSvg, ArchiveSvg, ExportSvg } from '@Calix-Commerce/design-system/assets';

import { useAppNavigation, useFormatPrice } from 'utils/hooks';
import { formatDateTime } from 'utils/helpers/date';
import { getQuoteId } from 'utils/helpers/quote';
import { UNNAMED_QUOTE_LABEL, CONTACT_COPS_LABEL } from 'utils/constants';
import { QUOTE_SEARCH_ITEM_CLASS } from 'utils/constants/selectors';
import { FloatingCard } from './FloatingCard';
import { IconsWrapper, TableRow, TableItem, TableData, TableDataNoWrap } from './styledComponents';
import { Quote } from 'types';
import { FileType } from 'types/FileType';

const ResultRow = ({
  idx,
  item,
  isModified,
  onUnarchive,
  onArchive,
  onDelete,
  onExport,
}: {
  idx: number;
  item: Quote;
  isModified: boolean;
  onUnarchive: () => void;
  onArchive: () => void;
  onDelete: () => void;
  onExport: (transactionId: string, fileType: FileType) => void;
}) => {
  const [showFileType, setShowFileType] = useState(false);
  const {
    quoteName,
    quoteDescription,
    preparedByName,
    lastModifiedDate,
    total,
    poNumber,
    isOrdered,
    archived,
    transactionId,
    currencyCode,
    containsNonDiscoutedDiscountableItems,
  } = item;
  const formatPrice = useFormatPrice(currencyCode);
  const { redirectToQuoteDetails } = useAppNavigation();

  return (
    <>
      <TableRow
        style={{ position: 'relative' }}
        darkBackground={idx % 2 === 0}
        className={QUOTE_SEARCH_ITEM_CLASS}
      >
        <td>
          <TableItem onClick={() => redirectToQuoteDetails(transactionId)}>
            {quoteName || UNNAMED_QUOTE_LABEL}
          </TableItem>
          <div style={{ padding: '0px 0px 8px 0px' }}>
            {isOrdered && (
              <div style={{ fontWeight: 'bold', color: '#ff7600' }}>Ordered - PO: {poNumber}</div>
            )}
          </div>
        </td>
        <td>
          <TableDataNoWrap>{getQuoteId(item)}</TableDataNoWrap>
        </td>
        <td>{quoteDescription && <TableData>{quoteDescription}</TableData>}</td>
        <td>
          <TableData>{preparedByName}</TableData>
        </td>
        <td>{lastModifiedDate && <TableData>{formatDateTime(lastModifiedDate)}</TableData>}</td>
        <td>
          <TableDataNoWrap>
            {containsNonDiscoutedDiscountableItems ? CONTACT_COPS_LABEL : formatPrice(total)}
          </TableDataNoWrap>
        </td>
        <td style={{ position: 'relative' }}>
          <IconsWrapper>
            <IconsWrapper>
              <IconsWrapper title="Remove Quote" onClick={onDelete}>
                <TrashSvg />
              </IconsWrapper>
              <IconsWrapper
                title={`${archived ? 'Unarchive' : 'Archive'} Quote`}
                onClick={archived ? onUnarchive : onArchive}
              >
                <ArchiveSvg />
              </IconsWrapper>
              <IconsWrapper title="Export Quote" onClick={() => setShowFileType(true)}>
                <ExportSvg />
              </IconsWrapper>
            </IconsWrapper>
          </IconsWrapper>
          {showFileType && (
            <FloatingCard
              isModified={isModified}
              onExportPDF={() => {
                onExport(transactionId, FileType.PDF);
                setShowFileType(false);
              }}
              onExportXLS={() => {
                onExport(transactionId, FileType.XLS);
                setShowFileType(false);
              }}
              onClickOutside={() => setShowFileType(false)}
            />
          )}
        </td>
      </TableRow>
    </>
  );
};

export { ResultRow };
