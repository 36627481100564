import { getI18NText } from 'I18N';
import { isValidAddress } from 'components/forms/address/AddressForm';

const addressHeaderId = 'billing-page-header';

export const validateCheckoutBilling = (
  billingData
): {
  isAllDataValid: boolean;
  billingErrors: any;
  focusOnElement: string;
} => {
  let focusOnElement = '';
  let billingErrors: {
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
    poNumber?: string;
  } = {};
  const { billingAddress, poNumber } = billingData;
  const isValidBillAddress = isValidAddress(billingAddress);
  const isPoNumberValid = poNumber && poNumber.trim();

  if (!isValidBillAddress.isValid) {
    focusOnElement = addressHeaderId;

    billingErrors = isValidBillAddress.errors;
  }

  if (!isPoNumberValid) {
    billingErrors.poNumber = getI18NText('PO_NUMBER_ERROR_MESSAGE');
  }

  return {
    isAllDataValid: Boolean(isValidBillAddress.isValid && isPoNumberValid),
    focusOnElement,
    billingErrors,
  };
};
