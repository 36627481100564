import { ApolloClient, InMemoryCache, HttpLink, concat, from } from '@apollo/client';

import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { onError } from '@apollo/client/link/error';

import { getJwt } from './storage';
import { notifyGraphqlError } from './bugsnag/events';

const errorLink = onError(notifyGraphqlError);

const httpLink = new HttpLink({
  // Default uri to /graphql if not testing
  uri: ({ operationName }) =>
    `${
      window.location.origin.substr(0, 16) === 'http://localhost'
        ? `https://${import.meta.env.VITE_CALIX_ENV || 'dev'}-store.calix.com`
        : ''
    }/graphql?op=${operationName}`,
  headers: {
    'x-api-key': import.meta.env.VITE_GRAPHQL_API_KEY,
    authorization: `Bearer ${getJwt()}`,
  },
});

const removeTypenameLink = removeTypenameFromVariables();

export const client = new ApolloClient({
  link: from([removeTypenameLink, concat(errorLink, httpLink)]),
  connectToDevTools: true,
  cache: new InMemoryCache({
    typePolicies: {
      Quote: {
        keyFields: ['transactionId'],
      },
      Account: {
        keyFields: ['accountId'],
        fields: {
          getQuote: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: 'Quote',
                transactionId: args?.transactionId,
              });
            },
          },
        },
      },
      SearchPartsResponse: {
        keyFields: ['partNumber'],
      },
    },
  }),
});
