import styled from '@emotion/styled';
import { DropdownInput, TextInput } from 'components/Common';

export const AddressDropdownInput = styled(DropdownInput)`
  .item-name {
    padding: 0 15px 5px 15px;
  }

  .form-dropdown-secondary {
    width: 75%;
  }
`;

export const AddressTextInput = styled(TextInput)`
  .item-name {
    padding: 0 15px 5px 15px;
  }

  &.form-row {
    input {
      margin-left: 10px;
    }
  }
`;

export const AddressFormContainer = styled.div`
  max-width: 750px;
  min-width: 470px;
`;
