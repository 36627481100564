import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Zoom, ClickAwayListener } from '@mui/material';

import { ButtonSlim, ButtonLink } from '@Calix-Commerce/design-system/components';

import { storageCurrentQuoteSelector } from 'state/selectors';

import { AddMenu, AddMenuHeader, AddMenuGroupButton, AddMenuFooter } from './styledComponents';
import { NULL_GROUP_ID } from 'utils/constants';
import { QuoteGroup } from 'types';

const AddMenuPopup = ({
  show = false,
  onConfirm,
  onClose,
  expandDuration = 0.3,
}: {
  show: boolean;
  onConfirm: (group: QuoteGroup) => void;
  onClose: () => void;
  expandDuration: number;
}) => {
  const currentQuote = useRecoilValue(storageCurrentQuoteSelector);
  const [newGroupName, setNewGroupName] = useState('');

  const groupList = currentQuote.quoteGroups;

  return (
    <div style={{ position: 'absolute', zIndex: 1000 }}>
      <Zoom
        in={show}
        timeout={expandDuration * 1000}
        style={{ transformOrigin: '0 0 0' }}
        unmountOnExit
      >
        <div>
          <ClickAwayListener onClickAway={onClose}>
            <AddMenu>
              <AddMenuHeader alignment="center" spacing="space-between">
                <span>+ Add to a Group in Quote</span>
                <ButtonLink onClick={onClose}>X</ButtonLink>
              </AddMenuHeader>
              <div style={{ padding: '0.8rem 0' }}>
                {groupList.map((group) => (
                  <div key={group.id}>
                    <AddMenuGroupButton onClick={() => onConfirm(group)}>
                      {group.name}
                    </AddMenuGroupButton>
                  </div>
                ))}
              </div>
              <form onSubmit={(event) => event.preventDefault()}>
                <AddMenuFooter spacing="space-between">
                  <input
                    type="text"
                    placeholder="New Group"
                    value={newGroupName}
                    onChange={(event) => setNewGroupName(event.target.value)}
                    style={{ width: '160px' }}
                  />
                  <ButtonSlim
                    type="submit"
                    disabled={!newGroupName}
                    onClick={() =>
                      onConfirm({
                        id: NULL_GROUP_ID,
                        groupName: newGroupName,
                        name: newGroupName,
                      } as QuoteGroup)
                    }
                    style={{ width: '100px' }}
                  >
                    SAVE
                  </ButtonSlim>
                </AddMenuFooter>
              </form>
            </AddMenu>
          </ClickAwayListener>
        </div>
      </Zoom>
    </div>
  );
};

export { AddMenuPopup };
