import { Modal } from '@Calix-Commerce/design-system/components';
import { LineItem } from 'types';
import { useQuoteController } from 'utils/hooks';

type RemoveItemModalProps = { isOpen: boolean; onClose: () => void; item: LineItem | null };

const RemoveItemModal = ({ onClose, isOpen, item, ...props }: RemoveItemModalProps) => {
  const { removeItem } = useQuoteController();

  return (
    <Modal
      title="Remove Item"
      content="Are you sure you want to remove the item from this quote?"
      confirmButtonText="REMOVE"
      isOpen={isOpen}
      onConfirmation={() => {
        if (!item?.sequenceNumber) {
          return;
        }
        removeItem(item.sequenceNumber);
        onClose();
      }}
      onClose={onClose}
      onDismiss={onClose}
      onCancel={onClose}
      {...props}
    />
  );
};

export { RemoveItemModal };
