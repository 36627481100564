import { startBugsnag } from 'connectors/bugsnag/start';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { authenticationState, currentQuoteState, currentQuoteValidationState } from 'state/atoms';

const bugsnag = startBugsnag();

const BugsnagBoundary = bugsnag.getPlugin('react')!.createErrorBoundary(React);

const ErrorBoundary = ({ children }) => {
  const authData = useRecoilValue(authenticationState);
  const quoteData = useRecoilValue(currentQuoteState);
  const validationData = useRecoilValue(currentQuoteValidationState);

  const onError = useCallback(() => {
    bugsnag.addMetadata('user', { id: authData.userName, ...authData });
    bugsnag.addMetadata('quote', quoteData);
    bugsnag.addMetadata('validation state', validationData);
  }, [authData, quoteData, validationData]);

  useEffect(() => {
    bugsnag.addOnError(onError);
  }, [onError]);

  return useMemo(() => <BugsnagBoundary>{children}</BugsnagBoundary>, [children]);
};

export { ErrorBoundary };
