import { Account, Product } from 'types';
import { QUOTE_ITEM_EXTENDED_WARRANTY_LINE } from 'utils/constants';

const MAX_DECIMAL_PLACES = 20;

const decimalAdjust = (type: 'round' | 'floor' | 'ceil' | 'trunc', value: number, exp: number) => {
  let val: string[] = [];
  // If the exp is undefined or zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }

  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  // If the value is negative...
  if (value < 0) {
    return -decimalAdjust(type, -value, exp);
  }
  // Shift
  val = value.toString().split('e');
  value = Math[type](+(val[0] + 'e' + (val[1] ? +val[1] - exp : -exp)));
  // Shift back
  val = value.toString().split('e');

  return +(val[0] + 'e' + (val[1] ? +val[1] + exp : exp));
};

/**
  Rounds the number to a two decimal places only.
  1055.004999999999999999999909090 would be 1055.01
 */
export const roundPrice = (price: number | string): number => {
  const priceIsInvalid = ['number', 'string'].indexOf(typeof price) === -1;
  const priceInt = typeof price === 'number' ? price : parseInt(price);
  const priceString = price.toString();

  // Price is invalid or has no decimal portion
  if (priceIsInvalid || priceInt % 1 === 0) {
    return priceInt;
  }

  const decimalPlacesString = priceString.substring(
    priceString.indexOf('.'),
    MAX_DECIMAL_PLACES + 1
  );

  let decimalPlacesNumber = +decimalPlacesString;

  for (let decimalDigits = decimalPlacesString.length - 1; decimalDigits > 1; decimalDigits--) {
    decimalPlacesNumber = decimalAdjust('round', decimalPlacesNumber, -decimalDigits);
  }

  return parseInt(priceString) + decimalPlacesNumber;
};

export const getWorkingPrice = ({
  account,
  product,
  discountPercent = 0,
}: {
  account: Account;
  product: Product;
  discountPercent?: number;
}) => {
  let workingPrice = 0,
    priceType = account && account.priceType,
    discountTotal = 0,
    priceValue = 0;

  // Get account price type
  if (priceType) {
    priceType = priceType.toLowerCase();
  }

  if (priceType) {
    // Get price list given the item
    // 1) Check into salePrices
    // 2) Check into listPrices
    // 3) Check into salePrice
    // 4) Check into listPrice
    if (product.salePrices && product.salePrices[priceType]) {
      priceValue = product.salePrices[priceType];
    } else if (product.listPrices && product.listPrices[priceType]) {
      priceValue = product.listPrices[priceType];
    } else if (product.salePrice) {
      priceValue = product.salePrice;
    } else {
      priceValue = product.listPrice;
    }

    discountTotal = priceValue * (discountPercent || 0);
    workingPrice = priceValue - discountTotal;
  }

  return roundPrice(workingPrice);
};

export const isProductDiscountable = ({ priceLine = '' }: Product) => {
  return priceLine !== QUOTE_ITEM_EXTENDED_WARRANTY_LINE && !priceLine.endsWith(' ND');
};
