import { useApolloClient } from '@apollo/client';
import { notifyCommerceIncomplete } from 'connectors/bugsnag/events';
import { GET_ACCOUNT } from 'connectors/queries/account';
import { CALIX_EMPLOYEE, END_CUSTOMER } from 'connectors/userTypes';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { authenticationState, loadingState, loggedInAccountState } from 'state/atoms';
import { defaultCurrentQuoteState } from 'state/defaults';
import { storageCurrentQuoteSelector, storageSelectedAccountSelector } from 'state/selectors';
import { Account } from 'types';
import { CURRENT_ACCOUNT_LOCAL_STORAGE, CURRENT_QUOTE_LOCAL_STORAGE } from 'utils/constants';
import { mapAccountDetailsToStorage } from 'utils/helpers/account';
import { isSfIdMatching } from 'utils/helpers/salesforce';

export const useAccountLoad = () => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const setGlobalLoader = useSetRecoilState(loadingState);
  const { accountId, jwt, userType } = useRecoilValue(authenticationState);
  const [{ commerceComplete }, setLoggedInAccount] = useRecoilState(loggedInAccountState);
  const [{ accountId: selectedAccountId }, setSelectedAccount] = useRecoilState(
    storageSelectedAccountSelector
  );
  const setCurrentQuote = useSetRecoilState(storageCurrentQuoteSelector);
  // Use an internal loading control to reduce re-rendering
  const [loading, setLoader] = useState(true);

  const isAccountAccessError = (error: Error) =>
    error.message.includes("doesn't have access to this account");

  const loadInitialData = async () => {
    try {
      const [loggedInAccountResponse, selectedAccountResponse] = await Promise.all([
        client.query({ query: GET_ACCOUNT, variables: { accountId } }),
        selectedAccountId
          ? client.query({ query: GET_ACCOUNT, variables: { accountId: selectedAccountId } })
          : Promise.resolve(null),
      ]);

      // Handle logged in account first
      if (!loggedInAccountResponse?.data?.getAccount.commerceComplete) {
        notifyCommerceIncomplete();
      }

      const loggedInAccount = mapAccountDetailsToStorage(loggedInAccountResponse?.data);
      let selectedAccount: Account | null = null;

      setLoggedInAccount(loggedInAccount);

      if (userType === END_CUSTOMER) {
        setSelectedAccount(loggedInAccount);
      }

      // Now handle the selected account
      if (selectedAccountResponse?.data.getAccount) {
        selectedAccount = mapAccountDetailsToStorage(selectedAccountResponse.data);

        setSelectedAccount(selectedAccount);
      } else if (userType !== END_CUSTOMER) {
        navigate('/accounts', { replace: true });
      }

      setCurrentQuote((currentQuote) => {
        const { accountId } = currentQuote;

        if (
          accountId &&
          (isSfIdMatching(accountId, loggedInAccount.accountId) ||
            isSfIdMatching(accountId, selectedAccount?.accountId || ''))
        ) {
          return currentQuote;
        }

        let defaultCurrencyCode = loggedInAccount.currencyCode;

        if (userType === CALIX_EMPLOYEE && selectedAccount?.accountId) {
          defaultCurrencyCode = selectedAccount?.currencyCode;
        }

        return {
          ...defaultCurrentQuoteState,
          accountId: selectedAccount?.accountId || '',
          currencyCode: defaultCurrencyCode,
        };
      });
      setLoader(false);
    } catch (error) {
      if (isAccountAccessError(error as Error)) {
        localStorage.removeItem(CURRENT_ACCOUNT_LOCAL_STORAGE);
        localStorage.removeItem(CURRENT_QUOTE_LOCAL_STORAGE);
      } else throw error;
    }
  };

  useEffect(() => {
    if (!jwt) {
      setLoader(false);
      setGlobalLoader(false);
      return;
    }

    loadInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When loading, we assume that the account is commerce complete.
  return { loading, commerceComplete: loading ? true : commerceComplete };
};
