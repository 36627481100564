import { gql } from '@apollo/client';
import {
  ADD_EXTERNAL_CONFIGURATION_OPERATION,
  UPDATE_EXTERNAL_CONFIGURATION_OPERATION,
} from 'utils/constants';

export const ADD_EXTERNAL_CONFIGURATION = gql`
  mutation ${ADD_EXTERNAL_CONFIGURATION_OPERATION}($input: SetExternalConfigurationInput!) {
    addExternalConfiguration (input: $input) {
      transactionId
    }
  }
`;

export const UPDATE_EXTERNAL_CONFIGURATION = gql`
  mutation ${UPDATE_EXTERNAL_CONFIGURATION_OPERATION}($input: SetExternalConfigurationInput!) {
    updateExternalConfiguration (input: $input) {
      transactionId
    }
  }
`;
