import { gql } from '@apollo/client';
import { PRODUCT_INFO } from 'connectors/fragments/product';
import { RECENTLY_ORDERED_PARTS_OPERATION, SEARCH_PARTS_BY_TERM_OPERATION } from 'utils/constants';

export const SEARCH_PARTS_BY_TERM = gql`
  query ${SEARCH_PARTS_BY_TERM_OPERATION}($input: SearchPartsInput!) {
    searchPartsByTerm(input: $input) {
      ...productInfo
    }
  }
  ${PRODUCT_INFO}
`;

export const RECENTLY_ORDERED_PARTS = gql`
  query ${RECENTLY_ORDERED_PARTS_OPERATION}(
    $accountId: ID!
    $recentlyOrderedItemsLimit: Int
    $recentlyOrderedItemsOffset: Int
  ) {
    getAccount(accountId: $accountId) {
      accountId
      ...listRecentlyOrderedItems
      __typename
    }
  }

  fragment listRecentlyOrderedItems on Account {
    listRecentlyOrderedItems(limit: $recentlyOrderedItemsLimit, offset: $recentlyOrderedItemsOffset)
    __typename
  }
`;
