import Cookie from 'js-cookie';
import { ModalConfig } from 'types';

import { USER_REQUEST_PATH_COOKIE } from 'utils/constants';

export const notAuthenticatedConfig: ModalConfig = {
  title: 'Not Authenticated',
  content: 'Your session has expired. Please click OK to log in again',
  onConfirmation: () => {
    // Redirect to the current url after user login
    Cookie.set(USER_REQUEST_PATH_COOKIE, window.location.href, {
      domain: import.meta.env.VITE_DEFAULT_COOKIE_DOMAIN,
    });
    window.location.replace(import.meta.env.VITE_CALIX_PORTAL_LOGIN_URL);
  },
};

export const emptyAccessConfig: ModalConfig = {
  title: 'Insufficient Privileges',
  content:
    'You have not been granted access to the My Calix Store. Please contact your Customer Operations Representative to request access.',
  onConfirmation: () => window.location.replace(import.meta.env.VITE_CALIX_PORTAL_URL),
};

export const noAccessConfig: ModalConfig = {
  title: 'Insufficient Privileges',
  content:
    'You have not been granted access to this portion of the My Calix Store. Please contact your Customer Operations Representative to request access.',
  onConfirmation: () => window.location.replace(import.meta.env.VITE_CALIX_PORTAL_URL),
};
