import { QuoteGroupDetail } from 'types';

export const GROUP_DATA_TYPE = 'groupType';
export const ITEM_ID_DATA_TYPE = 'ItemIdType';
export const ITEM_GROUP_ID_DATA_TYPE = 'ItemGroupIdType';

export const handleItemDrop = (
  quoteDetails: QuoteGroupDetail[],
  draggedItemId: string,
  groupId: number,
  dropZoneGroupId: number
) => {
  const draggedFromGroup = quoteDetails.find(({ id }) => groupId === id);

  const draggedItem = draggedFromGroup?.items.find(({ id }) => draggedItemId === id);

  if (!draggedItem) {
    return quoteDetails;
  }

  return quoteDetails.map((group) => {
    if (group.id === dropZoneGroupId) {
      return {
        ...group,
        items: [
          ...group.items,
          { ...draggedItem, groupName: group.groupName, groupNumber: group.id },
        ],
      };
    }

    if (group.id === groupId) {
      return { ...group, items: group.items.filter(({ id }) => id !== draggedItemId) };
    }

    return group;
  });
};

export const handleGroupDrop = (quoteDetails, draggedGroupId, dropZoneGroupId) => {
  const draggedGroup = quoteDetails.find(({ id }) => id === draggedGroupId);

  const dropZoneGroupIndex = quoteDetails.findIndex(({ id }) => id === dropZoneGroupId);

  const newQuoteDetails = quoteDetails.filter(({ id }) => id !== draggedGroupId);

  newQuoteDetails.splice(dropZoneGroupIndex, 0, draggedGroup);

  return newQuoteDetails;
};
