import { useNavigate, useLocation } from 'react-router-dom';

import { RMA_MANAGER_URL, ORDER_MANAGER_URL, RETURN_URL_PARAM } from 'utils/constants';
import { useUserPermissions } from 'utils/hooks/useUserPermissions';

export const useExternalRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { hasOrderAccess, hasRmaAccess } = useUserPermissions();

  // Tries to redirect to a returnUrl. Returns false if no returnUrl is found in the URL
  const redirectFromReturnUrl = () => {
    const returnUrl = new URLSearchParams(location.search).get(RETURN_URL_PARAM);
    if (!returnUrl) {
      return false;
    }

    if (returnUrl.match(/^https/)) {
      window.location.assign(returnUrl);
    } else {
      navigate(returnUrl);
    }

    return true;
  };

  // Tries to redirect to an app based on the user's permissions
  const redirectFromPermissions = () => {
    if (hasOrderAccess) {
      window.location.replace(ORDER_MANAGER_URL);
    } else if (hasRmaAccess) {
      window.location.replace(RMA_MANAGER_URL);
    }
  };

  return { redirectFromReturnUrl, redirectFromPermissions };
};
