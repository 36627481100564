import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { FormGroup, FormError } from '@Calix-Commerce/design-system/components';
import { selectedAccountState } from 'state/atoms';
import { PART_SEARCH_INPUT_ID, PART_SEARCH_SUBMIT_ID } from 'utils/constants/selectors';
import { TERM_URL_PARAM } from 'utils/constants';
import { getSearchUrlParams, buildSearchUrlQuery } from 'pages/PartSearch/helpers';
import { InputWrapper } from './styledComponents';
import { FormLabel } from 'componentsMigrated/FormLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SearchIcon = () => <FontAwesomeIcon icon={faSearch} size="lg" />;

const Search = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const selectedAccount = useRecoilValue(selectedAccountState);
  const urlSearchTerm = new URLSearchParams(location.search).get(TERM_URL_PARAM);

  const {
    register,
    getValues,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: { searchTerm: '' },
  });

  const handleFormSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    trigger().then((isFormValid) => {
      if (isFormValid) {
        const searchParams = getSearchUrlParams(location.search);
        const queryString = buildSearchUrlQuery({
          ...searchParams,
          term: getValues().searchTerm,
        });

        navigate(`${location.pathname}?${queryString}`);
      }
    });
  };

  useEffect(() => {
    // If a search term is present on the URL, update the input
    if (urlSearchTerm && !isEmpty(selectedAccount)) {
      setValue('searchTerm', urlSearchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount, urlSearchTerm]);

  return (
    <form onSubmit={handleFormSubmit}>
      <FormGroup>
        <FormLabel htmlFor={PART_SEARCH_INPUT_ID}>Search for parts:</FormLabel>
        <InputWrapper
          onAction={handleFormSubmit}
          Icon={<SearchIcon />}
          inputProps={{
            id: PART_SEARCH_INPUT_ID,
            placeholder: 'Search by part number, part name, description, product family',
            error: Boolean(errors.searchTerm),
            autoFocus: true,
            autoComplete: 'off',
            ...register('searchTerm', { required: 'Search term is mandatory.' }),
          }}
          buttonProps={{ id: PART_SEARCH_SUBMIT_ID }}
        />
        {errors.searchTerm ? <FormError>{errors.searchTerm.message}</FormError> : null}
      </FormGroup>
    </form>
  );
};

export { Search };
