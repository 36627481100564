import { gql } from '@apollo/client';

import { SEND_FEEDBACK_OPERATION } from 'utils/constants';

export const SEND_FEEDBACK = gql`
  mutation ${SEND_FEEDBACK_OPERATION}($feedbackInput: FeedbackInput) {
    submitFeedback(input: $feedbackInput) {
      success
    }
  }
`;
