import { Modal, SecondaryTable } from '@Calix-Commerce/design-system/components';
import { LineItem } from 'types';
import uniq from 'lodash/uniq';
import { Flex, Input, SecondaryTableBody, SecondaryTableHead } from '@Calix-Commerce/design-system';
import { useState } from 'react';
import { itemsHaveInvalidItemQuantity } from 'utils/helpers/quote';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirmation: (updatedItems: LineItem[]) => void;
  items: LineItem[];
};

const ItemRow = ({
  item,
  newQuantity,
  autoFocus,
  updateQuantity,
}: {
  item: LineItem;
  newQuantity: number;
  autoFocus: boolean;
  updateQuantity: (item: LineItem) => void;
}) => {
  return (
    <>
      <td>{item.groupName}</td>
      <td>
        <Input
          type="number"
          min="0"
          style={{ width: '80px' }}
          value={newQuantity}
          autoFocus={autoFocus}
          onChange={(e) => {
            let newQuantity = Number(e.target.value);
            if (!newQuantity) newQuantity = 0;
            updateQuantity({ ...item, quantity: newQuantity });
          }}
        />
      </td>
    </>
  );
};

const InvalidQtyItem = ({
  items,
  partNumber,
  partName,
  minimumQty,
  maxQty,
  updateQuantity,
}: {
  items: LineItem[];
  partNumber: string;
  partName: string;
  minimumQty: number;
  maxQty: number;
  updateQuantity: (newItem: LineItem) => void;
}) => {
  const total = items.reduce((total, item) => total + Number(item.quantity), 0);
  const invalidTotal = total > maxQty || total < minimumQty;

  return (
    <form
      style={{
        outline: invalidTotal ? '1px solid #ee5f5b' : '1px solid #fff',
        borderRadius: '5px',
        padding: '10px',
        margin: '10px 0',
      }}
    >
      <div style={{ margin: '5px 0', fontWeight: 'bold' }}>{partNumber + ' - ' + partName}</div>
      <Flex>
        <div style={{ width: '33.3%', padding: '0 15px' }}>
          <p style={{ fontStyle: 'italic' }}>Minimum Order Quantity: {minimumQty}</p>
          <p style={{ fontStyle: 'italic' }}>Maximum Order Quantity: {maxQty}</p>
        </div>
        <SecondaryTable>
          <SecondaryTableHead>
            <td>Group</td>
            <td>Quantity</td>
          </SecondaryTableHead>
          <SecondaryTableBody>
            {items.map((item, index) => {
              return (
                <ItemRow
                  autoFocus={index === 0}
                  key={item.sequenceNumber}
                  newQuantity={item.quantity}
                  updateQuantity={updateQuantity}
                  item={item}
                />
              );
            })}
          </SecondaryTableBody>
          <SecondaryTableBody>
            <td>Total</td>
            <td>
              <Input
                type="number"
                disabled={true}
                error={invalidTotal}
                style={{ width: '80px' }}
                value={total}
              />
            </td>
          </SecondaryTableBody>
        </SecondaryTable>
      </Flex>
    </form>
  );
};

const InvalidQtyModal = ({ onClose, isOpen, items, onConfirmation, ...props }: ModalProps) => {
  const [itemsState, setItemsState] = useState(items);

  const getUniquePartNumbersWithInvalidQty = () => {
    const partNumbers = uniq(items.map(({ partNumber }) => partNumber));

    return partNumbers.length ? partNumbers : [];
  };

  const updateQuantity = (newItem: LineItem) => {
    setItemsState((prev) =>
      prev.map((item) => {
        return item.sequenceNumber === newItem.sequenceNumber
          ? {
              ...newItem,
            }
          : item;
      })
    );
  };

  const getUpdatedQuantities = () => {
    return itemsState.map((item) => {
      return {
        ...item,
        quantity: item.quantity < 0 ? 0 : item.quantity,
      };
    });
  };

  const ModalContent = () => {
    return (
      <>
        <p>Please resolve the following issues before proceeding:</p>
        {getUniquePartNumbersWithInvalidQty().map((partNumber) => {
          const parts = itemsState.filter((item) => item.partNumber === partNumber);
          const partDetails = parts.at(0) as LineItem;

          return (
            <InvalidQtyItem
              items={parts}
              partName={partDetails.partName}
              partNumber={partDetails.partNumber}
              minimumQty={partDetails.minimumOrderQuantity!}
              maxQty={partDetails.maximumOrderQuantity!}
              updateQuantity={updateQuantity}
            />
          );
        })}
      </>
    );
  };

  return (
    <Modal
      title="These Parts Require Attention"
      //@ts-expect-error Design-System Modal component only accepts strings as content
      content={ModalContent()}
      confirmButtonText="CONTINUE"
      cancelButtonText="CANCEL"
      isOpen={isOpen}
      onConfirmation={() => {
        if (itemsHaveInvalidItemQuantity(itemsState)) {
          return;
        }

        onConfirmation(getUpdatedQuantities());
      }}
      onClose={onClose}
      onDismiss={onClose}
      onCancel={onClose}
      {...props}
    />
  );
};

export { InvalidQtyModal };
