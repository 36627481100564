import styled from '@emotion/styled';
import { ButtonRounded, Modal } from '@Calix-Commerce/design-system/components';
import { useAppNavigation } from 'utils/hooks';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { authenticationState, selectedAccountState } from 'state/atoms';
import { VAR } from 'connectors/userTypes';
import { isSfIdMatching } from 'utils/helpers/salesforce';

const StartNewWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3.55rem;
  margin-bottom: 2rem;
`;

const Title = styled.span`
  font-size: 1.15rem;
  margin-right: 3.55rem;
  white-space: nowrap;
`;

const StartNewQuote = ({ onStartNewQuote }: { onStartNewQuote: () => void }) => {
  const { userType, accountId: loggedInAccountId } = useRecoilValue(authenticationState);
  const { accountId: selectedAccountId, accountName } = useRecoilValue(selectedAccountState);
  const [openVarQuotingForItselfModal, setOpenVarQuotingForItselfModal] = useState(false);
  const { redirectToAccountSearch, redirectToPartSearchForNew } = useAppNavigation();

  const isVarQuotingForItself =
    userType === VAR && isSfIdMatching(loggedInAccountId, selectedAccountId);

  const startNewQuote = () => {
    onStartNewQuote();
    redirectToPartSearchForNew();
  };

  const handleStartNewQuoteClick = () => {
    if (isVarQuotingForItself) {
      setOpenVarQuotingForItselfModal(true);
    } else {
      startNewQuote();
    }
  };

  const handleVarAccountConfirmation = () => {
    setOpenVarQuotingForItselfModal(false);
    startNewQuote();
  };

  return (
    <StartNewWrapper>
      <Title>Search for an existing quote or start a new one</Title>
      <ButtonRounded onClick={handleStartNewQuoteClick}>START NEW QUOTE</ButtonRounded>
      <Modal
        isOpen={openVarQuotingForItselfModal}
        title="You have selected your own account"
        content={`You are about to start a new quote for your own account <b>(${accountName})</b>. If you would like to start a new quote for a different account, please to back to the account search and select that account instead.`}
        cancelButtonText="GO BACK TO ACCOUNT SEARCH"
        confirmButtonText="OK"
        onConfirmation={handleVarAccountConfirmation}
        onCancel={redirectToAccountSearch}
        onDismiss={() => setOpenVarQuotingForItselfModal(false)}
      />
    </StartNewWrapper>
  );
};

export { StartNewQuote };
