import { useSetRecoilState } from 'recoil';

import { notificationState } from 'state/atoms';

const useNotifyError = ({ fallbackMessage = '', ...notificationProps } = {}) => {
  const setNotification = useSetRecoilState(notificationState);

  return (error: Error) => {
    const errorMessage = error.message || '';
    const messageIsValid = /^Error:/g.test(errorMessage);

    setNotification({
      type: 'error',
      show: true,
      text: messageIsValid ? error?.message : fallbackMessage,
      timeout: null,
      ...notificationProps,
    });
  };
};

export { useNotifyError };
