import { Container } from '@Calix-Commerce/design-system/layout';
import { getI18NText } from 'I18N';
import { CheckoutFooter } from 'components/Footer';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  currentQuoteState,
  loadingState,
  loggedInAccountState,
  notificationState,
} from 'state/atoms';
import { readCheckoutSavedData, saveCheckoutDataInLocalStorage } from 'utils/helpers/checkout';
import { useAppNavigation } from 'utils/hooks';
import { useDuplicatePoNumberLoad } from 'utils/hooks/useCheckout';
import { validateCheckoutBilling } from './CheckoutBillingValidations';
import { BillingInfoForm } from './subcomponents/BillingInfoForm';
import { PurchaseInfoForm } from './subcomponents/PurchaseInfoForm';
import { FETCH_POLICY } from 'utils/constants';
import { Address } from 'types';
import { CheckoutStepper } from 'components/stepper';
import { withQuoteLoad } from 'hocs';
import { withCheckoutPermissions } from 'hocs/withCheckoutPermissions';
import { withCheckoutShippingConfigured } from 'hocs/withCheckoutShippingConfigured';
import { CURRENT_CHECKOUT_STEP } from 'utils/constants/checkout';
import { CheckoutTitle } from 'components/Checkout';
import { storageSelectedAccountSelector } from 'state/selectors';
import { withCheckoutAccessible } from 'hocs/withCheckoutAccessible';

const CheckoutBillingPage = () => {
  const { transactionId } = useParams();
  const { accountId } = useRecoilValue(storageSelectedAccountSelector);
  const currentQuoteData = useRecoilValue(currentQuoteState);
  const setCurrentQuoteState = useSetRecoilState(currentQuoteState);
  const setLoadingState = useSetRecoilState(loadingState);
  const { redirectToCheckoutShipping, redirectToCheckoutReview } = useAppNavigation();
  const [address, setAddress] = useState<Address>();
  const [leasingAgent, setLeasingAgent] = useState(false);
  const [poNumber, setPoNumber] = useState('');
  const [isValidPoNumber, setIsValidPoNumber] = useState(false);
  const [isPoNumberRequestInProgress, setIsPoNumberRequestInProgress] = useState(false);
  const [navigateAfterValidPoNumber, setNavigateAfterValidPoNumber] = useState(false);
  const [poNumberErrors, setPoNumberErrors] = useState([]);
  const [poFile, setPoFile] = useState();
  const [billDataToPersist, setBillDataToPersist] = useState({});
  const [errors, setErrors] = useState({
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    poNumber: '',
    poFile: '',
  });
  const { duplicatePoNumberRequest } = useDuplicatePoNumberLoad();
  const { businessCountry } = useRecoilValue(loggedInAccountState);
  const setNotification = useSetRecoilState(notificationState);
  const validateDuplicatePoNumber = (newPoNumber, relatedTargetId) => {
    const isNextClicked = relatedTargetId === 'checkout-page-next';
    const isBackClicked = relatedTargetId === 'checkout-page-back';

    if (isBackClicked) {
      redirectToCheckoutShipping();
    } else if (isBillingPageValid({ allowDuplicatePoNumber: true })) {
      if (isNextClicked) {
        setLoadingState(true);
      }

      setIsPoNumberRequestInProgress(true);

      duplicatePoNumberRequest({
        onCompleted: (poNumberResponse) => {
          setLoadingState(false);
          setIsPoNumberRequestInProgress(false);

          const duplicatePoNumbers = poNumberResponse.getAccount.findDuplicatedPoNumber || [];

          setPoNumberErrors(duplicatePoNumbers);
          setIsValidPoNumber(duplicatePoNumbers.length === 0);

          if (!duplicatePoNumbers.length && isNextClicked) {
            redirectToCheckoutReview();
          }
        },
        onError: (poNumberError) => {
          setLoadingState(false);
          setIsPoNumberRequestInProgress(false);
          setNotification({
            text: poNumberError.toString(),
            show: true,
            type: 'error',
            timeout: null,
          });
        },
        variables: {
          accountId,
          poNumber: newPoNumber,
        },
        fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
      });
    }
  };

  const saveBillingDataOnCurrentQuote = useCallback(() => {
    setCurrentQuoteState({
      ...currentQuoteData,
      billingAddress: {
        ...(address ?? ({} as Address)),
        id: address?.addressId,
      },
      lesingAgent: leasingAgent,
      poNumber,
    });
  }, [currentQuoteData, poNumber, poFile, leasingAgent, address]);

  const backNavigationConfig = {
    navigate: async () => redirectToCheckoutShipping(),
  };

  const isBillingPageValid = ({ allowDuplicatePoNumber }: { allowDuplicatePoNumber: boolean }) => {
    const billingAddress = {
      id: address?.addressId,
      name: address?.name,
      isFavorite: false,
      address1: address?.address1,
      address2: address?.address2,
      city: address?.city,
      state: address?.state,
      zipcode: address?.zipcode,
      country: address?.country,
      searchAddress: undefined,
      lesingAgent: leasingAgent,
    };

    const { isAllDataValid, billingErrors, focusOnElement } = validateCheckoutBilling({
      billingAddress,
      poFile,
      poNumber,
    });

    if (focusOnElement) {
      setTimeout(() => {
        const focusEl = document.getElementById(focusOnElement);

        focusEl &&
          focusEl.scrollIntoView({
            behavior: 'smooth',
          });
      }, 0);
    }

    if (isAllDataValid && (isValidPoNumber || allowDuplicatePoNumber)) {
      const finalBillingData = {
        billingAddress,
        poFile,
        poNumber,
        lesingAgent: leasingAgent,
        transactionId,
      };

      setBillDataToPersist(finalBillingData);
      saveCheckoutDataInLocalStorage({
        ...finalBillingData,
        transactionId,
      });
      saveBillingDataOnCurrentQuote();

      return true;
    }

    setErrors(billingErrors);

    if (!isAllDataValid) {
      setNotification({
        text: getI18NText('EMPTY_FIELDS_ERROR'),
        show: true,
        type: 'error',
        timeout: null,
      });
    }

    return false;
  };

  const nextNavigationValidation = async () => {
    if (isPoNumberRequestInProgress) {
      setLoadingState(true);
      setNavigateAfterValidPoNumber(true);
    }

    if (isBillingPageValid({ allowDuplicatePoNumber: false })) {
      return Promise.resolve();
    }

    return Promise.reject('Validations fails for billing');
  };

  const nextNavigationConfig = {
    navigate: async () => redirectToCheckoutReview(),
    validate: nextNavigationValidation,
  };

  useEffect(() => {
    if (
      !isPoNumberRequestInProgress &&
      navigateAfterValidPoNumber &&
      isBillingPageValid({ allowDuplicatePoNumber: false })
    ) {
      redirectToCheckoutReview();
    }
  }, [isPoNumberRequestInProgress, navigateAfterValidPoNumber]);

  useEffect(() => {
    const savedCheckoutData = readCheckoutSavedData(transactionId);
    const { billingAddress } = savedCheckoutData;

    if (billingAddress) {
      setAddress({
        addressId: billingAddress.id,
        name: billingAddress.name,
        address1: billingAddress.address1,
        address2: billingAddress.address2,
        city: billingAddress.city,
        state: billingAddress.state,
        zipcode: billingAddress.zipcode,
        country: billingAddress.country,
      });

      setLeasingAgent(billingAddress.lesingAgent);
    } else {
      setAddress({
        ...(address || ({} as Address)),
        country: businessCountry,
      });
    }

    setPoNumber(savedCheckoutData.poNumber || '');
    setIsValidPoNumber(Boolean(savedCheckoutData.poNumber));
    setPoFile(savedCheckoutData.poFile);
  }, [businessCountry]);

  return (
    <Container>
      <CheckoutStepper
        currentStep={CURRENT_CHECKOUT_STEP.BILLING}
        validate={nextNavigationValidation}
      />
      <CheckoutTitle id="billing-page-header">{getI18NText('BILLING_INFO')}</CheckoutTitle>
      <BillingInfoForm
        address={address}
        onAddressChange={(temp) => {
          setAddress(temp);
        }}
        leasingAgent={leasingAgent}
        onLeasingAgentChange={setLeasingAgent}
        errors={errors}
        setErrors={setErrors}
      />
      <CheckoutTitle id="billing-page-po-number-heading">
        {getI18NText('PURCHASE_ORDER_INFO')}
      </CheckoutTitle>
      <PurchaseInfoForm
        poNumber={poNumber}
        onPoNumberChange={setPoNumber}
        onValidPoNumberChange={setIsValidPoNumber}
        onPoFileChange={setPoFile}
        poFile={poFile}
        poNumberErrors={poNumberErrors}
        setPoNumberErrors={setPoNumberErrors}
        errors={errors}
        setErrors={setErrors}
        onConfirmationCallback={() => {
          setIsValidPoNumber(true);
          saveCheckoutDataInLocalStorage({
            ...billDataToPersist,
            transactionId,
          });
          saveBillingDataOnCurrentQuote();

          if (isBillingPageValid({ allowDuplicatePoNumber: true })) {
            nextNavigationConfig.navigate();
          }
        }}
        handleBlurPoNumber={(newPoNumber, relatedTargetId) => {
          validateDuplicatePoNumber(newPoNumber, relatedTargetId);
        }}
      />
      <CheckoutFooter
        backNavigationConfig={backNavigationConfig}
        nextNavigationConfig={nextNavigationConfig}
      />
    </Container>
  );
};

export const CheckoutBilling = withCheckoutPermissions(
  withQuoteLoad(withCheckoutAccessible(withCheckoutShippingConfigured(CheckoutBillingPage)))
);
