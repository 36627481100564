import { gql } from '@apollo/client';
import { QUOTE_CART_INFO, QUOTE_LIST_INFO } from 'connectors/fragments/quote';

export const GET_QUOTE_LIST = gql`
  query LoadList($accountId: ID!, $limit: Int, $offset: Int) {
    getAccount(accountId: $accountId) {
      accountId
      listQuotes(limit: $limit, offset: $offset) {
        hasMore
        count
        limit
        quotes {
          ...quoteListInfo
        }
      }
    }
  }
  ${QUOTE_LIST_INFO}
`;

export const GET_QUOTE = gql`
  query LoadQuote($accountId: ID!, $transactionId: ID!) {
    getAccount(accountId: $accountId) {
      accountId
      getQuote(transactionId: $transactionId) {
        ...quoteCartInfo
      }
    }
  }
  ${QUOTE_CART_INFO}
`;
