import { roundPrice } from 'utils/helpers/price';
import currencies from './currencySymbols.json';
import { Currency } from 'types';
import { useRecoilValue } from 'recoil';
import { storageCurrentQuoteSelector } from 'state/selectors';

export const useFormatPrice = (currency?: Currency) => {
  const { currencyCode } = useRecoilValue(storageCurrentQuoteSelector);

  const formatPrice = (price: number) => {
    // If a currency is not defined on the hook call, fallback to the context
    const symbol = currencies[currency || currencyCode];

    const parsedPrice = roundPrice(price);

    const formattedPrice = parsedPrice.toLocaleString('en-US', {
      minimumFractionDigits: 2,
    });

    return `${symbol}${formattedPrice} ${currency || currencyCode}`;
  };

  return formatPrice;
};
