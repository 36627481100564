import { ReactNode, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/HighlightOff';
import omit from 'lodash/omit';

import {
  MainMenuItemsHeader,
  MainMenuItemsList,
  MainMenuItem,
} from '@Calix-Commerce/design-system/components';
import { SpaceBetween } from '@Calix-Commerce/design-system/layout';
import { currentQuoteState, currentQuoteValidationState } from 'state/atoms';
import { getItemsByGroup } from 'utils/helpers/quote';
import { UNASSIGNED_GROUP_LABEL } from 'utils/constants';
import { RemoveItemModal } from 'components/modals';
import { ItemStatus, LineItem } from 'types';
import { PriceDisplay } from 'components';
import { GroupName, PartName, PartNumber } from './styledComponents';

const ItemList = (): ReactNode => {
  const navigate = useNavigate();
  const [isItemRemoveModalOpen, setIsItemRemoveModalOpen] = useState(false);
  const [itemToRemove, setItemToRemove] = useState<LineItem | null>(null);
  const { transactionId, items = [], isOrdered } = useRecoilValue(currentQuoteState);
  const { isWritable } = useRecoilValue(currentQuoteValidationState);

  const areAllItemsRemoved = items.every((item) => item.status === ItemStatus.REMOVED);
  if (areAllItemsRemoved) {
    return null;
  }

  const itemsByGroup = getItemsByGroup(items);
  const assignedItemsByGroup = omit(itemsByGroup, [UNASSIGNED_GROUP_LABEL]);
  const unassignedItems = itemsByGroup[UNASSIGNED_GROUP_LABEL] || [];

  const renderGroup = (groupName: string, items: LineItem[]) => {
    const itemsToDisplay = isOrdered
      ? items.filter((item) => item.partNumber)
      : items.filter((item) => {
          const isWarrantyItem = item.priceLine === 'Extended Warranty';
          return item.partNumber && !isWarrantyItem && item.status !== ItemStatus.REMOVED;
        });

    return itemsToDisplay.length ? (
      <div key={groupName}>
        <GroupName>{groupName}</GroupName>
        {itemsToDisplay?.map((item) => {
          return (
            <MainMenuItem key={item.sequenceNumber}>
              <PartNumber>{item.partNumber}</PartNumber>
              <PartName>{item.partName}</PartName>
              <SpaceBetween>
                <span>Qty: {item.quantity}</span>
                <span>
                  <PriceDisplay item={item} />
                </span>
              </SpaceBetween>
              {isWritable && !isOrdered && (
                <CloseIcon
                  onClick={() => {
                    setItemToRemove(item);
                    setIsItemRemoveModalOpen(true);
                  }}
                />
              )}
            </MainMenuItem>
          );
        })}
      </div>
    ) : null;
  };

  const handleItemsHeaderClick = () => {
    navigate(
      transactionId
        ? `/quote-manager/quote/${transactionId}/details`
        : '/quote-manager/quotes/new/details'
    );
  };

  return (
    <>
      <MainMenuItemsHeader style={{ textAlign: 'left' }} onClick={handleItemsHeaderClick}>
        Quote Detail
      </MainMenuItemsHeader>
      <MainMenuItemsList style={{ maxHeight: '350px' }}>
        {Object.keys(assignedItemsByGroup).map((groupName) =>
          renderGroup(groupName, itemsByGroup[groupName])
        )}
        {unassignedItems.length ? renderGroup(UNASSIGNED_GROUP_LABEL, unassignedItems) : null}
      </MainMenuItemsList>
      <RemoveItemModal
        item={itemToRemove}
        isOpen={isItemRemoveModalOpen}
        onClose={() => setIsItemRemoveModalOpen(false)}
      />
    </>
  );
};

export { ItemList };
