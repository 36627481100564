import { InView } from 'react-intersection-observer';
import { ACCOUNT_SEARCH_RESULTS_TABLE_ID } from 'utils/constants/selectors';

import {
  PrimaryTable,
  ButtonLink,
  NoResultMessage,
} from '@Calix-Commerce/design-system/components';

import { ACCOUNT_SEARCH_ITEM_CLASS } from 'utils/constants/selectors';
import { AccountRow } from './styledComponents';

const SearchResults = ({ accounts, loading, onAccountSelect, onEndOfPage }) => {
  const showNoMoreResults = accounts.length && !loading;

  const renderResults = () => {
    if (!accounts.length && !loading) {
      return <div>No accounts found. Please adjust your search and try again.</div>;
    } else if (accounts.length) {
      return (
        <>
          <PrimaryTable id={ACCOUNT_SEARCH_RESULTS_TABLE_ID}>
            <thead>
              <tr>
                <th style={{ paddingLeft: '0.57rem' }}>Account</th>
              </tr>
            </thead>
            <tbody>
              {accounts.map((account) => (
                <AccountRow key={account.accountId} className={ACCOUNT_SEARCH_ITEM_CLASS}>
                  <td>
                    <ButtonLink onClick={() => onAccountSelect(account)}>{account.name}</ButtonLink>
                  </td>
                </AccountRow>
              ))}
            </tbody>
          </PrimaryTable>
          <InView as="div" rootMargin="100px" onChange={(inView) => inView && onEndOfPage()} />
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {renderResults()}
      {showNoMoreResults ? (
        <NoResultMessage style={{ marginTop: '2.5rem' }}>
          No more results. Click{' '}
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              document.documentElement.scrollIntoView({
                behavior: 'smooth',
              });
            }}
          >
            here
          </a>{' '}
          to scroll to the top of the list.
        </NoResultMessage>
      ) : null}
    </>
  );
};

export { SearchResults };
