import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import styled from '@emotion/styled';
import { Flex } from '@Calix-Commerce/design-system/layout';

const QuoteNameStyled = styled.span<{ placeholder: string }>`
  width: fit-content;
  font-size: 1.75em;
  padding: 6px 50px 6px 0;
  font-weight: 700;
  z-index: 30;
  color: #0b38db;

  &:empty:before {
    content: attr(placeholder);
    color: #555;
  }

  &:focus-visible {
    outline: none;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const QuoteName = ({ quoteName, onEdit, placeholder, readonly = false }) => {
  return (
    <Flex
      alignment="center"
      style={{ position: 'relative', display: 'flex', width: 'fit-content' }}
    >
      <QuoteNameStyled
        id="quoteName-input"
        contentEditable={!readonly}
        suppressContentEditableWarning={true}
        onBlur={(e) => {
          const inputField = document.getElementById('quoteName-input');
          const { textContent } = e.currentTarget;

          if (!textContent?.trim()) {
            if (inputField) {
              inputField.innerHTML = '';
            }
            onEdit('');
            return;
          } else {
            onEdit(textContent);
          }
        }}
        placeholder={placeholder}
      >
        {quoteName}
      </QuoteNameStyled>
      {!readonly ? (
        <IconWrapper>
          <EditOutlinedIcon htmlColor="#0b38db" />
        </IconWrapper>
      ) : null}
    </Flex>
  );
};

export { QuoteName };
