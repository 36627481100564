import { Container, Flex } from '@Calix-Commerce/design-system/layout';
import { getI18NText } from 'I18N/I18N';
import RemoveCalendarSVG from 'assets/remove-calendar.svg';
import AddCalendarSVG from 'assets/add-calendar.svg';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { currentQuoteState } from 'state/atoms';
import { AdditionalShippingInstruction } from './AdditionalShippingInstruction';
import { Mapping, ShipmentScheduler } from '@Calix-Commerce/design-system/components';
import {
  AdditionalShippingInfoWarning,
  ShipmentInfoParagraph,
  ShipmentSchedulerContainer,
  ShippingFormSubHeader,
} from './styledComponents';
import { QUOTE_ITEM_EXTENDED_WARRANTY_LINE } from 'utils/constants';

export const AdditionalShippingInfo = ({
  shipments = [],
  onShipmentChange,
  packagingInstruction,
  onPackagingInstructionChange,
  shippingInstruction,
  onShippingInstructionChange,
}) => {
  const { items } = useRecoilValue(currentQuoteState);
  const [quoteMapping, setQuoteMapping] = useState<Mapping[]>([]);
  const [showScheduler, setShowScheduler] = useState(false);

  useEffect(() => {
    const parsedItems = parseLineGroups(items);

    setQuoteMapping(getMappingData(parsedItems));

    if (shipments.length) {
      const updatedShipments = processShipmentForChanges(shipments, parsedItems);

      onShipmentChange(updatedShipments);
    } else {
      onShipmentChange(parsedItems);
    }

    setShowScheduler(true);
  }, [items]);

  const shipmentMapping: Mapping[] = getMappingData(shipments);

  const SchedulerChangeHandler = (changedShipments) => {
    onShipmentChange(
      changedShipments.map((changedShipment) => {
        return {
          lines: changedShipment.lines.map((line) => {
            return {
              partNumber: line.partNumber,
              partName: line.partName,
              totalQuantity: line.total,
              quantity: line.quantity,
            };
          }),
          shipDate: changedShipment.shipDate,
        };
      })
    );
  };

  return (
    <>
      <Container>
        <Flex direction="column">
          <ShippingFormSubHeader>
            {getI18NText('REQUESTED_SHIPMENT_SCHEDULE')}
          </ShippingFormSubHeader>
          <ShipmentInfoParagraph>
            {getI18NText('SPLIT_SHIPMENT_BUTTON_INFO')}
            <br></br>
            <b>{getI18NText('NOTE')}:</b>&nbsp;
            {getI18NText('SHIPPING_DATE_NOTE')}
          </ShipmentInfoParagraph>
        </Flex>
      </Container>
      <ShipmentSchedulerContainer>
        {showScheduler && (
          <ShipmentScheduler
            shipmentMapping={shipmentMapping}
            quoteMapping={quoteMapping}
            buttonText={getI18NText('SPLIT_SHIPMENTS')}
            //@ts-ignore
            buttonIcon={<AddCalendarSVG className="add-calendar" />}
            handleChange={SchedulerChangeHandler}
            onlyFutureShipDateSelection={true}
            //@ts-ignore
            removeShipmentIcon={<RemoveCalendarSVG className="remove-scheduler-group" />}
          ></ShipmentScheduler>
        )}
      </ShipmentSchedulerContainer>
      <Container>
        <AdditionalShippingInfoWarning>
          {getI18NText('SPLIT_SHIPMENT_WARNING_1')}
          <br></br>
          <b>{getI18NText('SPLIT_SHIPMENT_WARNING_2')}</b>&nbsp;
          <a href="mailto:calixcustomeroperations@calix.com" target="_blank">
            calixcustomeroperations@calix.com
          </a>
          &nbsp;<b>{getI18NText('SPLIT_SHIPMENT_WARNING_3')}</b>
        </AdditionalShippingInfoWarning>
        <AdditionalShippingInstruction
          id="packagin-instructions"
          label={getI18NText('PACKAGING_INSTRUCTIONS')}
          characterLimit={150}
          onInstructionChange={onPackagingInstructionChange}
          instruction={packagingInstruction}
          instructionInfo={getI18NText('PACKAGING_INSTRUCTIONS_INFO')}
        />
        <AdditionalShippingInstruction
          id={'shipping-instructions'}
          label={getI18NText('SHIPPING_INSTRUCTIONS')}
          characterLimit={150}
          onInstructionChange={onShippingInstructionChange}
          instruction={shippingInstruction}
          instructionInfo={getI18NText('SHIPPING_INSTRUCTIONS_INFO')}
        />
      </Container>
    </>
  );
};

const processShipmentForChanges = (shipments, quoteShipments) => {
  let newShipments: Mapping[] = [];

  //clone shipment and remove item if it is not present on new quote data
  shipments.forEach((shipment) => {
    newShipments.push({
      ...shipment,
      lines: shipment.lines.filter((line) =>
        Boolean(
          quoteShipments[0].lines.find((quoteLine) => quoteLine.partNumber === line.partNumber)
        )
      ),
    });
  });

  quoteShipments[0]?.lines.forEach((quoteLine) => {
    //checking if quote item is present on current shipment data
    const shipmentItem = newShipments[0]?.lines.find(
      (shipmentLine) => shipmentLine.partNumber === quoteLine.partNumber
    );

    if (!shipmentItem) {
      newShipments = newShipments.map((newShipment, index) => ({
        ...newShipment,
        lines: [
          ...newShipment.lines,
          {
            ...quoteLine,
            modified: index === 0,
            quantity: index === 0 ? quoteLine.quantity : 0,
          },
        ],
      }));
    }
  });

  return newShipments;
};

const getMappingData = (items) => {
  return items.map((shipment) => {
    return {
      //@ts-ignore
      lines: shipment.lines.map((line) => {
        return {
          partNumber: line.partNumber,
          partName: line.partName,
          total: line.totalQuantity,
          quantity: line.quantity,
          modified: line.modified,
        };
      }),
      //@ts-ignore
      shipDate: shipment.shipDate,
    };
  });
};

const parseLineGroups = (items) => {
  type line = {
    partName: string;
    partNumber: string;
    quantity: number;
    totalQuantity: number;
    modified?: boolean;
  };
  const shipments: {
    shipDate: string;
    lines: line[];
  }[] = [];

  const lines: line[] = [];

  items
    .filter((line) => line.partNumber && line.priceLine !== QUOTE_ITEM_EXTENDED_WARRANTY_LINE)
    .forEach((item) => {
      const { partNumber, quantity, partName } = item;

      const existingLine = lines.find((line) => line.partNumber === partNumber);

      if (existingLine) {
        existingLine.quantity += quantity;
        existingLine.totalQuantity = existingLine.quantity;
      } else {
        lines.push({
          partName,
          partNumber,
          quantity,
          totalQuantity: quantity,
        });
      }
    });

  shipments.push({
    shipDate: dayjs(new Date()).add(1, 'day').format('MM/DD/YYYY'),
    lines,
  });

  return shipments;
};
