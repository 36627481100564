export const BASE_NEW_QUOTE_ROUTE = `/quote-manager/quotes`;
export const BASE_SAVED_QUOTE_ROUTE = `/quote-manager/quote`;

export const CHECKOUT_WARRANTY_PAGE = 'checkout/warranty';
export const CHECKOUT_SHIPPING_PAGE = 'checkout/shipping';
export const CHECKOUT_BILLING_PAGE = 'checkout/billing';
export const CHECKOUT_REVIEW_PAGE = 'checkout/review';
export const CHECKOUT_CONFIRMATION_PAGE = 'checkout/confirmation';

export enum ROUTE_NAMES {
  ACCOUNTS = 'ACCOUNTS',
  QUOTES = 'QUOTES',
  NEW_QUOTE_SEARCH = 'NEW_QUOTE_SEARCH',
  NEW_QUOTE_DETAILS = 'NEW_QUOTE_DETAILS',
  QUOTE_SEARCH = 'QUOTE_SEARCH',
  QUOTE_DETAILS = 'QUOTE_DETAILS',
  CHECKOUT_WARRANTY = 'CHECKOUT_WARRANTY',
  CHECKOUT_SHIPPING = 'CHECKOUT_SHIPPING',
  CHECKOUT_BILLING = 'CHECKOUT_BILLING',
  CHECKOUT_REVIEW = 'CHECKOUT_REVIEW',
  CHECKOUT_CONFIRMATION = 'CHECKOUT_CONFIRMATION',
}

export const ROUTE_MATCHING_PATTERNS = {
  [ROUTE_NAMES.ACCOUNTS]: new RegExp(`^/accounts$`),
  [ROUTE_NAMES.QUOTES]: new RegExp(`^${BASE_NEW_QUOTE_ROUTE}$`),
  [ROUTE_NAMES.NEW_QUOTE_SEARCH]: new RegExp(`^${BASE_NEW_QUOTE_ROUTE}/new/search$`),
  [ROUTE_NAMES.NEW_QUOTE_DETAILS]: new RegExp(`^${BASE_NEW_QUOTE_ROUTE}/new/details$`),
  [ROUTE_NAMES.QUOTE_SEARCH]: new RegExp(`^${BASE_SAVED_QUOTE_ROUTE}/\\d+/search$`),
  [ROUTE_NAMES.QUOTE_DETAILS]: new RegExp(`^${BASE_SAVED_QUOTE_ROUTE}/\\d+/details$`),
  [ROUTE_NAMES.CHECKOUT_WARRANTY]: new RegExp(
    `^${BASE_SAVED_QUOTE_ROUTE}/\\d+/${CHECKOUT_WARRANTY_PAGE}$`
  ),
  [ROUTE_NAMES.CHECKOUT_SHIPPING]: new RegExp(
    `^${BASE_SAVED_QUOTE_ROUTE}/\\d+/${CHECKOUT_SHIPPING_PAGE}$`
  ),
  [ROUTE_NAMES.CHECKOUT_BILLING]: new RegExp(
    `^${BASE_SAVED_QUOTE_ROUTE}/\\d+/${CHECKOUT_BILLING_PAGE}$`
  ),
  [ROUTE_NAMES.CHECKOUT_REVIEW]: new RegExp(
    `^${BASE_SAVED_QUOTE_ROUTE}/\\d+/${CHECKOUT_REVIEW_PAGE}$`
  ),
  [ROUTE_NAMES.CHECKOUT_CONFIRMATION]: new RegExp(
    `^${BASE_SAVED_QUOTE_ROUTE}/\\d+/${CHECKOUT_CONFIRMATION_PAGE}$`
  ),
};
