import difference from 'lodash/difference';
import { Quote } from 'types';

import { getQuoteId } from 'utils/helpers/quote';

const CREATED_BY_ALL_FILTER = 'created-by-all';
const ACTIVE_FILTER = 'active';
const ARCHIVED_FILTER = 'archived';

const applyTermFilter = (quote: Quote, term?: string) => {
  if (!term) {
    return true;
  }

  const { quoteName, quoteDescription } = quote;
  const termLowerCase = term?.trim().toLowerCase();
  const quoteId = getQuoteId(quote);

  return (
    (quoteName && quoteName.toLowerCase().includes(termLowerCase)) ||
    (quoteDescription && quoteDescription.toLowerCase().includes(termLowerCase)) ||
    (quoteId && quoteId.toLowerCase().includes(termLowerCase))
  );
};

const applyCreatedByFilter = (quote: Quote, createdByFilter?: string[]) => {
  if (!createdByFilter || createdByFilter.includes(CREATED_BY_ALL_FILTER)) {
    return true;
  }

  return createdByFilter.includes(quote.preparedByName);
};

const applyTagsFilter = (quote: Quote, filterTags: string[] = []) => {
  if (!filterTags.length) {
    return true;
  }

  const itemTags = quote.tags || [];
  const unmatchedTags = difference(filterTags, itemTags);

  const itemContainsAllTags = unmatchedTags.length === 0;
  return itemContainsAllTags;
};

const applyTabFilter = (quote: Quote, tabFilter?: 'active' | 'archived') => {
  if (!tabFilter) {
    return true;
  }

  if (tabFilter === ACTIVE_FILTER) {
    return !quote.archived;
  } else if (tabFilter === ARCHIVED_FILTER) {
    return quote.archived;
  } else {
    return true;
  }
};

const applyFilters = ({
  items = [],
  term,
  createdByFilter,
  tagsFilter,
  tabFilter,
}: {
  items: Quote[];
  term?: string;
  createdByFilter?: string[];
  tagsFilter?: string[];
  tabFilter?: 'active' | 'archived';
}): Quote[] =>
  items.filter((quote) => {
    return (
      quote &&
      applyTermFilter(quote, term) &&
      applyCreatedByFilter(quote, createdByFilter) &&
      applyTagsFilter(quote, tagsFilter) &&
      applyTabFilter(quote, tabFilter)
    );
  });

export { CREATED_BY_ALL_FILTER, ACTIVE_FILTER, ARCHIVED_FILTER, applyFilters };
