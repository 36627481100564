import { useMutation, useQuery } from '@apollo/client';
import { getI18NText } from 'I18N';
import { CREATE_ADDRESS, DELETE_ADDRESS } from 'connectors/mutations';
import { GET_ADDRESSES } from 'connectors/queries';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  authenticationState,
  checkoutAddressesState,
  loadingState,
  notificationState,
} from 'state/atoms';
import { storageSelectedAccountSelector } from 'state/selectors';

export const useAddressLoad = (): {
  addressRequest;
  deleteAddressRequest;
  createAddressRequest;
} => {
  const { varAccountId } = useRecoilValue(authenticationState);
  const { accountId } = useRecoilValue(storageSelectedAccountSelector);
  const setCheckoutAddress = useSetRecoilState(checkoutAddressesState);
  const setLoadingState = useSetRecoilState(loadingState);
  const setNotification = useSetRecoilState(notificationState);

  const addressRequest = useQuery(GET_ADDRESSES, {
    variables: { accountId, varAccountId },
    onCompleted: (response) => {
      if (response) {
        setCheckoutAddress(response.getAccount.getAddresses);
      }
    },
  });

  const deleteAddressRequest = useMutation(DELETE_ADDRESS);
  const [createAddressMutation] = useMutation(CREATE_ADDRESS);

  const createAddressRequest = async (shippingAddress, addFavCallback) => {
    setLoadingState(true);

    createAddressMutation({
      variables: {
        address: {
          accountId,
          varAccountId,
          address: {
            address1: shippingAddress.address1,
            address2: shippingAddress.address2,
            city: shippingAddress.city,
            country: shippingAddress.country,
            state: shippingAddress.state,
            zipcode: shippingAddress.zipcode,
          },
          channel: null,
          favorite: true,
        },
      },
      onCompleted: (newFavorite) => {
        addFavCallback && addFavCallback(newFavorite);
        setLoadingState(false);

        setNotification({
          text: getI18NText('FAVORITE_ADDRESS_CREATE_SUCCESS'),
          show: true,
          type: 'success',
          timeout: 4000,
        });
      },
    });
  };

  return { addressRequest, deleteAddressRequest, createAddressRequest };
};
