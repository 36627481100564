import { gql } from '@apollo/client';
import { QUOTE_CART_INFO, QUOTE_LOCK_INFO } from 'connectors/fragments/quote';

export const CLONE_QUOTE = gql`
  mutation CloneQuote($transactionId: ID!, $accountId: String!, $currencyCode: String!) {
    cloneQuote(
      input: { transactionId: $transactionId, accountId: $accountId, currencyCode: $currencyCode }
    ) {
      ...quoteCartInfo
    }
  }
  ${QUOTE_CART_INFO}
`;

export const LOCK_QUOTE = gql`
  mutation LockQuote($quoteInput: AlterQuoteInput) {
    alterQuote(input: $quoteInput) {
      ...quoteLockInfo
    }
  }
  ${QUOTE_LOCK_INFO}
`;

export const UNLOCK_QUOTE = gql`
  mutation UnlockQuote($quoteInput: AlterQuoteInput) {
    alterQuote(input: $quoteInput) {
      ...quoteLockInfo
    }
  }
  ${QUOTE_LOCK_INFO}
`;

export const CREATE_QUOTE = gql`
  mutation CreateQuote($quoteInput: QuoteInput) {
    createQuote(input: $quoteInput) {
      ...quoteCartInfo
    }
  }
  ${QUOTE_CART_INFO}
`;

export const ALTER_QUOTE = gql`
  mutation AlterQuote($quoteInput: AlterQuoteInput) {
    alterQuote(input: $quoteInput) {
      ...quoteCartInfo
    }
  }
  ${QUOTE_CART_INFO}
`;

export const EXPORT_QUOTE = gql`
  mutation ExportQuote(
    $transactionId: Int!
    $accountId: String!
    $documentFormat: QuoteDocumentFormat!
    $input: QuoteDocumentInput
  ) {
    generateQuoteDocument(
      input: {
        input: $input
        transactionId: $transactionId
        accountId: $accountId
        documentFormat: $documentFormat
      }
    ) {
      name
      base64
    }
  }
`;

export const ALTER_QUOTE_CART = gql`
  mutation AlterQuoteCart($quoteInput: AlterQuoteInput) {
    alterQuote(input: $quoteInput) {
      ...quoteCartInfo
    }
  }
  ${QUOTE_CART_INFO}
`;

export const DELETE_QUOTE = gql`
  mutation DeleteQuote($transactionId: Int!) {
    deleteQuote(transactionId: $transactionId)
  }
`;

export const REFRESH_QUOTE = gql`
  mutation RefreshQuote($transactionId: ID!) {
    refreshQuotePricing(transactionId: $transactionId) {
      ...quoteCartInfo
    }
  }
  ${QUOTE_CART_INFO}
`;

export const SUBMIT_ORDER = gql`
  mutation SubmitOrder($quoteInput: AlterQuoteInput) {
    alterQuote(input: $quoteInput) {
      ...quoteCartInfo
    }
  }
  ${QUOTE_CART_INFO}
`;
