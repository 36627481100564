import { CurrentQuoteState } from 'types';
import {
  InfoStyled,
  Item,
  Title,
  Block,
  Header,
  AdditionalShippingContact,
} from './styledComponents';
import { formatDate } from 'utils/helpers/date';
import { formatPhoneNumber } from 'utils/helpers/quote';
import { getI18NText } from 'I18N';
import { downloadPoFile } from 'utils/helpers/checkout';

const OrderDetails = ({ quote }: { quote: CurrentQuoteState }) => {
  const shipping = {
    ...quote.shippingAddress,
    shipmentSchedules: quote.shipmentSchedule ?? [],
    shippingContactFirstName: quote.shippingContactFirstName,
    shippingContactLastName: quote.shippingContactLastName,
    shippingContactEmail: quote.shippingContactEmail,
    shippingContactPhone: quote.shippingContactPhone,
    requestedShippingDate: quote.requestedShippingDate,
    shippingInstructions: quote.shippingInstructions,
    packingInstructions: quote.packingInstructions,
  };

  const billing = {
    ...quote.billingAddress,
    taxExempt: quote.taxExempt,
    leasingAgent: quote.lesingAgent,
    poNumber: quote.poNumber,
    poAttachment: quote.poAttachment,
  };

  const items = quote.items;

  return (
    <>
      <Header>Order Details</Header>
      <InfoStyled>
        <Block>
          <Title>Shipping</Title>
          <Item>
            <span>Address1:</span>
            <span>{shipping.address1}</span>
          </Item>
          <Item>
            <span>Address2:</span>
            <span>{shipping.address2}</span>
          </Item>
          <Item>
            <span>City:</span>
            <span>{shipping.city}</span>
          </Item>
          <Item>
            <span>State/Province/Region:</span>
            <span>{shipping.state || shipping.province}</span>
          </Item>
          <Item>
            <span>Postal Code:</span>
            <span>{shipping.zipcode}</span>
          </Item>
          <Item>
            <span>Country:</span>
            <span>{shipping.country}</span>
          </Item>
          <Item topMargin={true}>
            <span>Requested Ship Date:</span>
            <span>
              {shipping.requestedShippingDate ? formatDate(shipping.requestedShippingDate) : ''}
            </span>
          </Item>
          <Item style={{ width: '100%', flexDirection: 'column' }}>
            <span>Shipment Schedule:</span>
            <span>
              {shipping.shipmentSchedules.map((shipmentSchedule) => (
                <div className="shipment-schedule-details" key={shipmentSchedule.shipDate}>
                  <div className="shipment-group-header">
                    <span className="ship-date">{formatDate(shipmentSchedule.shipDate)}</span>
                    <span className="quantity">QTY</span>
                  </div>
                  {shipmentSchedule.lines.map((line) => {
                    const partInfo = items.find(({ partNumber }) => line.partNumber === partNumber);
                    return (
                      <div className="shipment-schedule-list" key={line.partNumber}>
                        <span className="part-name">{`${line.partNumber} - ${partInfo?.partName}`}</span>
                        <span className="quantity">{line.quantity}</span>
                      </div>
                    );
                  })}
                </div>
              ))}
            </span>
          </Item>
          <Item topMargin={true}>
            <span>Packing Instructions</span>
            <span>{shipping.packingInstructions}</span>
          </Item>
          <Item>
            <span>Shipping Instructions</span>
            <span>{shipping.shippingInstructions}</span>
          </Item>
          <AdditionalShippingContact topMargin={true}>
            <span>Additional Shipping Contact</span>
            <span>
              {shipping.shippingContactFirstName} {shipping.shippingContactLastName}
            </span>
            <span>
              {shipping.shippingContactPhone
                ? formatPhoneNumber(shipping.shippingContactPhone)
                : null}
            </span>
            <span>
              <a href={`mailto:${shipping.shippingContactEmail}`}>
                <p>{shipping.shippingContactEmail}</p>
              </a>
            </span>
          </AdditionalShippingContact>
        </Block>
        <Block>
          <Title>Billing</Title>
          <Item>
            <span>Address1:</span>
            <span>{billing.address1}</span>
          </Item>
          <Item>
            <span>Address2:</span>
            <span>{billing.address2}</span>
          </Item>
          <Item>
            <span>City:</span>
            <span>{billing.city}</span>
          </Item>
          <Item>
            <span>State/Province/Region:</span>
            <span>{billing.state || billing.province}</span>
          </Item>
          <Item>
            <span>Postal Code:</span>
            <span>{billing.zipcode}</span>
          </Item>
          <Item>
            <span>Country:</span>
            <span>{billing.country}</span>
          </Item>
          <Item>
            <span>Leasing Agent:</span>
            <span>{billing.leasingAgent ? 'Yes' : 'No'}</span>
          </Item>
          <Item topMargin={true}>
            <span>PO Number</span>
            <span>{billing.poNumber}</span>
          </Item>
          {billing.poAttachment ? (
            <Item>
              <span>{getI18NText('ATTACHMENTS')}:</span>
              <span>
                <a
                  className="review-link"
                  onClick={() => {
                    downloadPoFile(
                      'pdf',
                      billing?.poAttachment?.base64,
                      billing.poAttachment?.name
                    );
                  }}
                >
                  {billing.poAttachment.name}
                </a>
              </span>
            </Item>
          ) : null}
        </Block>
      </InfoStyled>
    </>
  );
};

export { OrderDetails };
