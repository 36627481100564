import { ReactNode, useEffect, useState } from 'react';
import Cookie from 'js-cookie';
import { Modal } from '@Calix-Commerce/design-system/components';
import { notifyMissingFeature } from 'connectors/bugsnag/events';
import { noCookiesConfig, noStorageConfig, noConnectionConfig } from './modalConfigs';
import { ModalConfig } from 'types';

type EnabledFeatures = {
  internetConnection: boolean;
  cookies: boolean;
  storage: boolean;
  resizeObserver: boolean;
};

const getModalConfig = (enabledFeatures: EnabledFeatures) => {
  if (!enabledFeatures.internetConnection) {
    return noConnectionConfig;
  } else if (!enabledFeatures.cookies) {
    return noCookiesConfig;
  } else if (!enabledFeatures.storage) {
    return noStorageConfig;
  }

  return {} as ModalConfig;
};

// Checks if cookies are enabled by trying to set and unset a test cookie
const checkCookiesEnabled = () => {
  const TEST_COOKIE = '__testcookie__';

  try {
    Cookie.set(TEST_COOKIE, '1');
    const cookiesEnabled = Cookie.get(TEST_COOKIE);
    Cookie.remove(TEST_COOKIE);
    return cookiesEnabled === '1' ? true : false;
  } catch (e) {
    return false;
  }
};

// Checks if the storage is enabled by trying to set and unset test data
const checkStorageEnabled = () => {
  const TEST_KEY = '__teststorage__';

  try {
    localStorage.setItem(TEST_KEY, TEST_KEY);
    localStorage.removeItem(TEST_KEY);
    return true;
  } catch (e) {
    return false;
  }
};

const BrowserFeaturesCheck = ({ children }: { children: ReactNode }) => {
  const [internetConnection, setInternetConnection] = useState(true);

  const enabledFeatures: EnabledFeatures = {
    internetConnection,
    cookies: checkCookiesEnabled(),
    storage: checkStorageEnabled(),
    resizeObserver: typeof ResizeObserver !== 'undefined',
  };

  useEffect(() => {
    window.addEventListener('online', () => setInternetConnection(true));
    window.addEventListener('offline', () => setInternetConnection(false));
  }, []);

  useEffect(() => {
    if (!enabledFeatures.cookies) {
      notifyMissingFeature('Cookies are blocked');
    } else if (!enabledFeatures.storage) {
      notifyMissingFeature('localStorage is blocked');
    } else if (!enabledFeatures.resizeObserver) {
      notifyMissingFeature(`User's browser does not support ResizeObserver`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasRequiredFeatures =
    enabledFeatures.internetConnection && enabledFeatures.cookies && enabledFeatures.storage;

  return (
    <>
      {hasRequiredFeatures ? (
        children
      ) : (
        <Modal isOpen position="top" {...getModalConfig(enabledFeatures)} />
      )}
    </>
  );
};

export { BrowserFeaturesCheck };
