import { ButtonRounded } from '@Calix-Commerce/design-system';
import styled from '@emotion/styled';

export const Title = styled.h1`
  font-size: 2.6rem;
  font-weight: normal;
  margin: 0 0 1.43rem;
`;

export const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    color: #0b38db;
    font-size: 1.75em;
    font-weight: 700;
  }

  a {
    text-decoration: none;
    color: #ff7600;
  }
`;

export const ReOrderButton = styled(ButtonRounded)`
  background: #ff7600;
  margin-right: 0;
  margin-left: auto;
`;
