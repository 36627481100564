import { Flex } from '@Calix-Commerce/design-system/layout';
import styled from '@emotion/styled';
import { DropdownInput, TextInput } from 'components/Common';

export const ContactDropdownSecondary = styled(DropdownInput)`
  &.phone-number-item {
    width: 20%;
    color: red;

    p {
      margin-left: 0px;
    }

    .form-dropdown-secondary {
      margin-left: 0px;
      width: 100%;
    }
  }
`;

export const InputTextWarning = styled.p`
  color: #a94442;
  padding: 0 15px 5px 0px;
  margin-left: 25%;
  @media (max-width: 990px) {
    padding-left: 0;
    margin-left: 0;
  }
`;

export const Item = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  @media (max-width: 990px) {
    flex-flow: column;
    align-items: start;
  }
`;

export const ContactTextInput = styled(TextInput)`
  &.form-row {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  p {
    margin-left: 25%;
  }

  &.phone-number-item-area-code {
    width: 25%;

    div {
      display: flex;
      justify-content: center;
    }

    input {
      width: 100%;
    }

    p {
      margin-left: 0px;
    }
  }

  &.phone-number-item-number {
    width: 30.63%;

    div {
      display: flex;
      justify-content: center;
    }

    input {
      width: 100%;
    }

    p {
      margin-left: 0px;
    }
  }

  &.phone-number-item-extension {
    width: 25%;

    div {
      display: flex;
      justify-content: center;
    }

    input {
      width: 100%;
    }

    p {
      margin-left: 0px;
    }
  }
`;

export const PhoneNumberRow = styled.div`
  display: flex;
  width: 75%;
  gap: 30px;
`;

export const ContactInfoFrom = styled.form`
  max-width: 750px;
  min-width: 470px;
`;

export const ErrorListContainer = styled(Flex)`
  margin-left: 25%;
  gap: 5px;

  .error-text {
    margin: 0;
    padding: 0;
  }
`;
