import { useEffect, useMemo, useState } from 'react';
import { ButtonRounded } from '@Calix-Commerce/design-system/components';
import { Flex } from '@Calix-Commerce/design-system/layout';
import {
  PartListItem,
  QuickAddInput,
  QuickAddLabel,
  QuickAddTableColumn,
  QuickAddTableRow,
} from './styledComponents';
import debounce from 'lodash/debounce';

const QuickAdd = ({
  handleAddPart,
  readonly,
  groupId,
  groupName,
  results,
  searchPartNumbers,
}: {
  handleAddPart?: (partNumber: string, groupId: number, groupName: string) => void;
  readonly: boolean;
  groupId: number;
  groupName: string;
  results: { partNumber: string }[];
  searchPartNumbers?: (searchTerm: string, groupId: number) => void;
}) => {
  const [input, setInput] = useState('');

  const debounceHandler = useMemo(
    () =>
      debounce((event) => {
        searchPartNumbers && searchPartNumbers(event.target.value, groupId);
      }, 500),
    [groupId, searchPartNumbers]
  );

  useEffect(() => {
    return () => {
      debounceHandler.cancel();
    };
  }, [debounceHandler]);

  return (
    <QuickAddTableRow>
      <QuickAddTableColumn colSpan={1} style={{ width: '100px' }}>
        <Flex spacing="justify-center">
          <QuickAddLabel htmlFor={`quickAdd-${groupId}`}>Quick Add:</QuickAddLabel>
        </Flex>
      </QuickAddTableColumn>
      <QuickAddTableColumn colSpan={1} style={{ width: '310px' }}>
        <Flex direction="column">
          <QuickAddInput
            id={`quickAdd-${groupId}`}
            value={input}
            disabled={readonly}
            placeholder="Enter Part Number to Add to Group"
            error={results.length === 0 && input.length > 0}
            onChange={(e) => {
              setInput(e.target.value);
              debounceHandler(e);
            }}
          />
          <div
            id="searchPartList"
            style={{ position: 'absolute', background: '#fff', margin: '28px 5px', width: '300px' }}
          >
            {input ? (
              <ul style={{ margin: '0', padding: '0', maxHeight: '250px', overflowY: 'scroll' }}>
                {results.map((part) => {
                  return (
                    <PartListItem
                      onClick={() => {
                        setInput(part.partNumber);
                      }}
                      emphasize={results.length === 1 && results.at(0)?.partNumber === input}
                      key={part.partNumber}
                    >
                      {part.partNumber}
                    </PartListItem>
                  );
                })}
              </ul>
            ) : null}
          </div>
        </Flex>
      </QuickAddTableColumn>
      <QuickAddTableColumn colSpan={4}>
        <ButtonRounded
          style={{ height: '28px' }}
          colorVariant="tertiary"
          disabled={readonly || (results.length === 0 && input.length > 0)}
          onClick={() => {
            if (input.length !== 9) return;

            handleAddPart && handleAddPart(input, groupId, groupName);
            setInput('');
          }}
        >
          ADD
        </ButtonRounded>
      </QuickAddTableColumn>
    </QuickAddTableRow>
  );
};

export { QuickAdd };
