import bugsnag from '@bugsnag/js';

import {
  BUGSNAG_ERROR_CLASSES,
  BUGSNAG_GRAPHQL_IGNORED_OPERATIONS,
  BUGSNAG_NETWORK_IGNORED_OPERATIONS,
} from 'utils/constants';
import { parseJwt, getJwt } from 'connectors/storage';

export const notifyError = ({
  errorClass = BUGSNAG_ERROR_CLASSES.DEFAULT_ERROR,
  message,
  severity,
  metadata = {},
}) => {
  const error = {
    name: errorClass,
    message,
  };

  bugsnag.notify(error, (event) => {
    event.severity = severity;
    event.groupingHash = errorClass;
    for (const metaKey of Object.keys(metadata)) {
      event.addMetadata(metaKey, metadata[metaKey]);
    }
  });
};

export const notifySpinnerTimeout = (timeout) => {
  notifyError({
    errorClass: BUGSNAG_ERROR_CLASSES.SPINNER_TIMEOUT,
    message: `${timeout}s limit was reached`,
    severity: 'warning',
  });
};

// TODO : add types for graphQL Error
export const notifyGraphqlError = (graphqlError) => {
  const { graphQLErrors, networkError, operation } = graphqlError;

  if (operation.query) {
    operation.query = operation.query.loc.source.body;
  }

  const customMessage = operation.operationName + ' Error';

  if (!BUGSNAG_GRAPHQL_IGNORED_OPERATIONS.includes(operation?.operationName) && graphQLErrors) {
    notifyError({
      errorClass: BUGSNAG_ERROR_CLASSES.GRAPHQL_ERROR,
      message: customMessage,
      severity: 'warning',
      metadata: {
        GraphQL_Response: graphqlError.response,
        GraphQL_Request: graphqlError.operation,
      },
    });
  }

  if (!BUGSNAG_NETWORK_IGNORED_OPERATIONS.includes(operation.operationName) && networkError) {
    notifyError({
      errorClass: BUGSNAG_ERROR_CLASSES.GRAPHQL_NETWORK_ERROR,
      message: customMessage,
      severity: 'warning',
      metadata: {
        GraphQL_Network_Error: networkError,
        GraphQL_Request: operation,
      },
    });
  }
};

export const notifyNoAccess = (authData) => {
  const { isAuthenticated, eCommerceAccess } = authData;

  let customMessage = '';

  if (!isAuthenticated) {
    customMessage = BUGSNAG_ERROR_CLASSES.USER_UNAUTHORIZED;
  } else if (!eCommerceAccess.length) {
    customMessage = BUGSNAG_ERROR_CLASSES.USER_WITHOUT_ACCESSES;
  } else {
    customMessage = BUGSNAG_ERROR_CLASSES.USER_WITHOUT_PROPER_ACCESS;
  }

  notifyError({
    errorClass: BUGSNAG_ERROR_CLASSES.USER_ERROR,
    message: customMessage,
    severity: 'info',
  });
};

export const notifyMissingFeature = (message = '') => {
  notifyError({
    message,
    errorClass: BUGSNAG_ERROR_CLASSES.MISSING_BROWSER_FEATURE,
    severity: 'warning',
    metadata: { user: parseJwt(getJwt()) },
  });
};

export const notifyCommerceIncomplete = () => {
  notifyError({
    errorClass: BUGSNAG_ERROR_CLASSES.USER_ERROR,
    message: `Commerce incomplete account`,
    severity: 'warning',
  });
};

export const notifyDuplicatedAccounts = ({ byId = [], byName = [] } = {}) => {
  if (byId.length) {
    notifyError({
      errorClass: BUGSNAG_ERROR_CLASSES.DUPLICATED_ACCOUNT_INFORMATION_ERROR,
      message: 'Duplicated Account Ids and Names',
      severity: 'info',
      metadata: {
        Duplicated_Account_Data: { results: byId },
      },
    });
  }

  if (byName.length) {
    notifyError({
      errorClass: BUGSNAG_ERROR_CLASSES.DUPLICATED_ACCOUNT_INFORMATION_ERROR,
      message: 'Duplicated Account Names',
      severity: 'info',
      metadata: {
        Duplicated_Account_Name_Data: { results: byName },
      },
    });
  }
};
