import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { storageCurrentQuoteSelector } from 'state/selectors';
import { useAppNavigation } from 'utils/hooks';

export const withCheckoutAccessible = (CheckoutPage: React.ComponentType) => {
  const WrappedComponent = () => {
    const { redirectToQuoteDetails } = useAppNavigation();
    const [isAccessible, setIsAccessible] = useState(false);
    const { isOrdered, transactionId } = useRecoilValue(storageCurrentQuoteSelector);

    useEffect(() => {
      if (isOrdered) {
        redirectToQuoteDetails(transactionId);
      } else {
        setIsAccessible(true);
      }
    }, [isOrdered, transactionId, redirectToQuoteDetails]);

    return isAccessible ? <CheckoutPage /> : null;
  };

  return WrappedComponent;
};
