import { useLazyQuery } from '@apollo/client';
import { FIND_DUPLICATE_PO_NUMBER } from 'connectors/queries';

export const useDuplicatePoNumberLoad = (): {
  duplicatePoNumberRequest;
} => {
  const [duplicatePoNumberRequest] = useLazyQuery(FIND_DUPLICATE_PO_NUMBER);

  return { duplicatePoNumberRequest };
};
