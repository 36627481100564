import { Input } from '@Calix-Commerce/design-system/components';
import {
  MandatorySymbol,
  ItemName,
  ItemRow,
  InputTextWarning,
  Item,
  FormDropdownSecondary,
} from './styledComponents';

type DropDownInputType = {
  label?: string;
  required?: boolean;
  error?: string;
  options: any;
  selected: any;
  handleSelectOption: any;
  handleRemoveOption?: any;
  isReadOnly?: boolean;
  className?: string;
  dropdownProps?: any;
};

type TextInputType = {
  label?: string;
  required?: boolean;
  error?: string;
  inputProps?: any;
  isReadOnly?: boolean;
  className?: string;
};

export const FormInputLabel = ({ label, required }: { label: string; required?: boolean }) => {
  return (
    <ItemName className="item-name">
      {label}
      {required && <MandatorySymbol>*</MandatorySymbol>}
    </ItemName>
  );
};

export const ConditionalReadOnlyInput = ({
  readOnly,
  readOnlyValue,
  editComponent,
}: {
  readOnly?: boolean;
  readOnlyValue: string | number;
  editComponent: React.ReactNode;
}) => {
  return <>{readOnly ? <span className="read-only-text">{readOnlyValue}</span> : editComponent}</>;
};

export const DropdownInput = ({
  label,
  required,
  error,
  options,
  selected,
  handleSelectOption,
  handleRemoveOption,
  className,
  isReadOnly,
  dropdownProps = {},
}: DropDownInputType) => {
  return (
    <ItemRow className={`${className || ''} item-row`}>
      {error && <InputTextWarning className="error-text">{error}</InputTextWarning>}
      <Item>
        {label && <FormInputLabel label={label} required={required}></FormInputLabel>}
        <ConditionalReadOnlyInput
          readOnly={isReadOnly}
          readOnlyValue={selected}
          editComponent={
            <FormDropdownSecondary
              className="form-dropdown-secondary"
              attributeToBeDisplayed={'displayContent'}
              options={options}
              selectedOptionId={selected}
              onSelectOption={handleSelectOption}
              onRemoveOption={handleRemoveOption}
              {...dropdownProps}
            ></FormDropdownSecondary>
          }
        ></ConditionalReadOnlyInput>
      </Item>
    </ItemRow>
  );
};

export const TextInput = ({
  label,
  required,
  error,
  inputProps = {},
  isReadOnly,
  className,
}: TextInputType) => {
  return (
    <ItemRow className={`form-row ${className || ''}`}>
      {error && error.trim() && <InputTextWarning className="error-text">{error}</InputTextWarning>}
      <Item>
        {label && <FormInputLabel label={label} required={required}></FormInputLabel>}
        <ConditionalReadOnlyInput
          readOnly={isReadOnly}
          readOnlyValue={inputProps.value}
          editComponent={<Input {...inputProps} error={Boolean(error)}></Input>}
        ></ConditionalReadOnlyInput>
      </Item>
    </ItemRow>
  );
};
