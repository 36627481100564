import styled from '@emotion/styled';

import { PrimaryTableRow } from '@Calix-Commerce/design-system/components';

export const AccountRow = styled(PrimaryTableRow)`
  td,
  td:first-of-type {
    padding: 0;
    padding-left: 0.57rem;
  }

  &:nth-of-type(even) {
    background: #f5f5f5;
  }

  button {
    display: block;
    width: 100%;
    font-weight: 700;
    text-align: left;
    line-height: 150%;
    padding: 0.51rem 0;

    &:hover {
      color: #ff7600;
      text-decoration: none;
    }
  }
`;
