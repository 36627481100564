import { Modal } from '@Calix-Commerce/design-system/components';
import { ReactElement } from 'react';
import { ModalProps } from '@Calix-Commerce/design-system/components';

export type ReturnToAccountModalProps = Omit<Omit<ModalProps, 'title'>, 'content'> & {
  onClose: () => void;
};

const ReturnToAccountModal = ({
  onClose,
  onConfirmation,
  ...props
}: ReturnToAccountModalProps): ReactElement => {
  return (
    <Modal
      title="Return to Account Search"
      content="Any unsaved data will be lost. Are you sure you want to proceed?"
      flag="Warning"
      onConfirmation={onConfirmation}
      onDismiss={onClose}
      onCancel={onClose}
      {...props}
    />
  );
};

export { ReturnToAccountModal };
