import styled from '@emotion/styled';
import { ReactElement, ReactNode } from 'react';
import { TransitionGroup as BaseTransitionGroup, CSSTransition } from 'react-transition-group';

const TRANSITION_CLASSNAME = 'blink-transition';

const TransitionGroup = styled(BaseTransitionGroup)<{ timeout: number }>`
  .${TRANSITION_CLASSNAME}-enter {
    opacity: 0.2;
  }

  .${TRANSITION_CLASSNAME}-enter-done {
    opacity: 1;
    transition: all ${({ timeout }) => timeout}ms ease-in;
  }
`;

// Blinks the child element every time the transitionKey changes
const BlinkTransition = ({
  transitionKey = '',
  children,
  timeout = 250,
  ...transitionProps
}: {
  transitionKey: string;
  children: ReactNode;
  timeout?: number;
}): ReactElement => {
  return (
    <TransitionGroup timeout={timeout}>
      <CSSTransition
        key={transitionKey}
        classNames={TRANSITION_CLASSNAME}
        timeout={{ enter: timeout }}
        exit={false}
        {...transitionProps}
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  );
};

export { BlinkTransition };
