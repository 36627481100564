import { gql } from '@apollo/client';

export const DUPLICATE_PO_NUMBER_RESULT_FRAGMENT = gql`
  fragment erpOrderSearchResult on ErpOrderSearchResult {
    purchaseOrderNumber
    salesOrderNumber
    orderedBy
    fiscalOrderDate
    orderStatus
    orderTotal
    currencyCode
    orderType
    billToOracleId
    billToSfdcAccountId
    billToName
    soldToName
  }
`;
