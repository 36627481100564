import { PrimaryTableRow } from '@Calix-Commerce/design-system/components';
import styled from '@emotion/styled';

export const QuoteItemTableRow = styled(PrimaryTableRow)`
  td {
    &:first-of-type {
      padding-left: 15px;
    }

    &:last-of-type {
      padding-right: 15px;
    }
  }
`;
