import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { currentQuoteState } from 'state/atoms';
import { useAppNavigation } from 'utils/hooks';

export const withCheckoutWarrantyConfigured = (CheckoutPage: React.ComponentType) => {
  const WrappedComponent = () => {
    const { redirectToCheckoutWarranty } = useAppNavigation();
    const { requiresWarrantyConfiguration, items } = useRecoilValue(currentQuoteState);

    useEffect(() => {
      if (
        requiresWarrantyConfiguration &&
        items.some((currentQuoteItem) => currentQuoteItem.extendedWarrantyEligible)
      ) {
        redirectToCheckoutWarranty();
      }
    }, [requiresWarrantyConfiguration, items, redirectToCheckoutWarranty]);

    return <CheckoutPage />;
  };

  return WrappedComponent;
};
