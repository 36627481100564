import {
  Quotes,
  QuoteDetails,
  PartSearch,
  Accounts,
  GenerateQuote,
  Confirmation,
  CheckoutWarranty,
  CheckoutShipping,
  CheckoutBilling,
  CheckoutReview,
  CheckoutConfirmation,
} from 'pages';
import { useEffect } from 'react';
import { Navigate, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { homeUrlSelector } from 'state/selectors';
import {
  BASE_NEW_QUOTE_ROUTE,
  BASE_SAVED_QUOTE_ROUTE,
  CHECKOUT_BILLING_PAGE,
  CHECKOUT_CONFIRMATION_PAGE,
  CHECKOUT_REVIEW_PAGE,
  CHECKOUT_SHIPPING_PAGE,
  CHECKOUT_WARRANTY_PAGE,
} from 'utils/hooks/useAppNavigation/PagePath';
import { BUY_ID_PARAM, QUOTE_ID_PARAM } from 'utils/constants';

const RedirectWithParams = ({
  param,
  fallback,
  getRoute,
}: {
  param: string;
  fallback: string;
  getRoute: (paramValue: string) => string;
}) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const paramValue = params.get(param);

  const url = paramValue ? getRoute(paramValue) : fallback;

  useEffect(() => {
    navigate(url);
  }, [navigate, url]);

  return null;
};

const PageRoutes = () => {
  const homeUrl = useRecoilValue(homeUrlSelector);

  return (
    <Routes>
      <Route path="/accounts" element={<Accounts />} />

      {/* Quote Manager routes */}
      <Route path={BASE_NEW_QUOTE_ROUTE} element={<Quotes />} />
      <Route path="/quote-manager/generate-quote" element={<GenerateQuote />} />
      <Route path={`${BASE_NEW_QUOTE_ROUTE}/new/search`} element={<PartSearch />} />
      <Route path={`${BASE_NEW_QUOTE_ROUTE}/new/details`} element={<QuoteDetails />} />
      <Route path="/quote-manager/quote/:transactionId/confirmation" element={<Confirmation />} />
      <Route path="/quote-manager/quote/:transactionId/search" element={<PartSearch />} />
      <Route path="/quote-manager/quote/:transactionId/details" element={<QuoteDetails />} />
      <Route
        path={`${BASE_SAVED_QUOTE_ROUTE}/:transactionId/confirmation`}
        element={<Confirmation />}
      />
      <Route path={`${BASE_SAVED_QUOTE_ROUTE}/:transactionId/search`} element={<PartSearch />} />
      <Route path={`${BASE_SAVED_QUOTE_ROUTE}/:transactionId/details`} element={<QuoteDetails />} />
      <Route
        path={`${BASE_SAVED_QUOTE_ROUTE}/:transactionId/${CHECKOUT_WARRANTY_PAGE}`}
        element={<CheckoutWarranty />}
      />
      <Route
        path={`${BASE_SAVED_QUOTE_ROUTE}/:transactionId/${CHECKOUT_SHIPPING_PAGE}`}
        element={<CheckoutShipping />}
      />
      <Route
        path={`${BASE_SAVED_QUOTE_ROUTE}/:transactionId/${CHECKOUT_BILLING_PAGE}`}
        element={<CheckoutBilling />}
      />
      <Route
        path={`${BASE_SAVED_QUOTE_ROUTE}/:transactionId/${CHECKOUT_REVIEW_PAGE}`}
        element={<CheckoutReview />}
      />
      <Route
        path={`${BASE_SAVED_QUOTE_ROUTE}/:transactionId/${CHECKOUT_CONFIRMATION_PAGE}`}
        element={<CheckoutConfirmation />}
      />

      {/* Redirects */}
      <Route path="/account-search" element={<Navigate to="/accounts" />} />
      <Route path="/quote-list" element={<Navigate to="/quote-manager/quotes" />} />
      <Route path="/cart/new" element={<Navigate to="/quote-manager/quotes/new/search" />} />
      <Route
        path="/generateQuote"
        element={
          <RedirectWithParams
            param={BUY_ID_PARAM}
            getRoute={(buyId: string) => `/quote-manager/generate-quote?${BUY_ID_PARAM}=${buyId}`}
            fallback={homeUrl}
          />
        }
      />

      <Route
        path="/cart"
        element={
          <RedirectWithParams
            param={QUOTE_ID_PARAM}
            getRoute={(quoteId: string) => `/quote-manager/quote/${quoteId}/details`}
            fallback={`/quote-manager/quotes/new/details`}
          />
        }
      />

      <Route
        path="/checkout"
        element={
          <RedirectWithParams
            param={QUOTE_ID_PARAM}
            getRoute={(quoteId: string) => `/quote-manager/quote/${quoteId}/details`}
            fallback={homeUrl}
          />
        }
      />

      <Route path="/*" element={<Navigate to={homeUrl} />} />
    </Routes>
  );
};

export { PageRoutes };
