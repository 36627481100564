import { ButtonSlim, Flex } from '@Calix-Commerce/design-system';
import { FloatingMenuWrapper, FloatingMenuHeader, FloatingMenuNote } from './styledComponents';
import { ClickAwayListener } from '@mui/material';

export const FloatingCard = ({
  isModified,
  onExportPDF,
  onExportXLS,
  onClickOutside,
}: {
  isModified: boolean;
  onExportPDF: () => void;
  onExportXLS: () => void;
  onClickOutside: () => void;
}) => (
  <ClickAwayListener onClickAway={onClickOutside}>
    <FloatingMenuWrapper>
      <FloatingMenuHeader>Select the file type</FloatingMenuHeader>
      {isModified && (
        <FloatingMenuNote>
          NOTE: Current quote is being modified and will be exported with current changes.
        </FloatingMenuNote>
      )}
      <Flex style={{ gap: '8px', padding: '16px' }} spacing="space-evenly">
        <ButtonSlim onClick={onExportPDF}>PDF</ButtonSlim>
        <ButtonSlim onClick={onExportXLS}>XLS</ButtonSlim>
      </Flex>
    </FloatingMenuWrapper>
  </ClickAwayListener>
);
