import styled from '@emotion/styled';

import { Flex } from '@Calix-Commerce/design-system/layout';
import { ButtonLink } from 'componentsMigrated/ButtonLink';

export const QuantityInput = styled.input`
  min-width: 64px;
  max-width: 4.57rem;
  height: 1.93rem;
  padding: 0.07rem 0 0.07rem 0.57rem;
  margin: 0.71rem 0 0.71rem 0.5rem;
`;

export const SuccessMessageBlock = styled(Flex)`
  flex-wrap: wrap;
  line-height: 1.75;

  svg {
    margin-right: 0.5rem;
    color: #0b38db;
  }

  button {
    min-width: auto;
    padding: 0;
    color: #0b38db;
  }
`;

export const AddMenu = styled.div`
  border: 1px solid #a0a0a0;
  background: #fff;
  width: 300px;
`;

export const AddMenuHeader = styled(Flex)`
  padding: 0.71rem;
  background: #f0f0f0;

  button {
    padding: 0;
    min-width: auto;
    margin-right: 0.3rem;
  }
`;

export const AddMenuGroupButton = styled(ButtonLink)`
  min-width: auto;
  line-height: 30px;
  padding: 0;
  margin-left: 0.71rem;
`;

export const AddMenuFooter = styled(Flex)`
  border-top: 1px solid #a0a0a0;
  padding: 0.71rem;
`;
