import { DesignTheme } from '@Calix-Commerce/design-system';
import styled from '@emotion/styled';
import MuiDialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';

export const Dialog = styled(MuiDialog)<{ position?: 'top' | 'bottom' | 'center' }>`
  .MuiBackdrop-root {
    background-color: rgba(15, 15, 15, 0.5);
  }

  .MuiDialog-scrollPaper {
    align-items: ${({ position }) => {
      if (position === 'top') {
        return 'baseline';
      } else if (position === 'bottom') {
        return 'flex-end';
      } else {
        return 'center';
      }
    }};
  }

  .MuiDialog-paperScrollPaper {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
`;

export const DialogTitle = styled(MuiDialogTitle)<{ theme?: DesignTheme }>`
  padding: 1.08rem;
  border-bottom: 1px solid #e5e5e5;

  h2 {
    font-size: 1.25rem;
    font-weight: bold;
    font-family: ${({ theme }) => theme.colors.secondary};
  }
`;

export const DialogContent = styled(MuiDialogContent)<{ theme?: DesignTheme }>`
  padding: 1.45rem;
  border-bottom: 1px solid #e5e5e5;

  p {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.textDark};
    font-family: ${({ theme }) => theme.fonts.secondary};
  }
`;

export const DialogActions = styled(MuiDialogActions)`
  padding: 1.35rem;

  .MuiButton-root {
    margin-left: 1.43rem;
  }
`;

export const CloseButton = styled.button<{ theme?: DesignTheme }>`
  font-size: 1.5rem;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.secondary};
  opacity: 0.2;
  color: ${({ theme }) => theme.colors.textDark};
  float: right;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &:hover {
    opacity: 0.5;
  }
`;
