import { Control, Controller } from 'react-hook-form';

import {
  DropdownPrimaryMulti as BaseDropdown,
  DropdownOption,
  DropdownPrimaryMultiProps,
} from '@Calix-Commerce/design-system/components';
import { SearchFilterValues } from 'pages/Quotes/Quotes';
import { useCallback, useState } from 'react';

const DropdownPrimaryMulti = ({
  control,
  onChange,
  name,
  filterSelectedOptions,
  defaultSelectedOptions = [],
  ...dropdownProps
}: Omit<DropdownPrimaryMultiProps, 'onSelectedOptionsChange'> & {
  control: Control<SearchFilterValues, SearchFilterValues>;
  onChange?: (id: string[]) => void;
  filterSelectedOptions?: (options: DropdownOption[]) => string[];
  defaultSelectedOptions?: string[];
  name: string;
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>(defaultSelectedOptions);
  const defaultFilterSelectedOptions = useCallback((option) => option.id, []);

  return (
    <Controller
      control={control}
      name={name}
      render={(form) => {
        return (
          <BaseDropdown
            {...dropdownProps}
            selectedOptionIds={selectedOptions}
            onSelectedOptionsChange={(options) => {
              const newOptions = filterSelectedOptions
                ? filterSelectedOptions(options)
                : options.map(defaultFilterSelectedOptions);

              setSelectedOptions(newOptions);
              onChange && onChange(newOptions);
              form.field.onChange(newOptions);
              form.field.onBlur();
            }}
          />
        );
      }}
    />
  );
};

export { DropdownPrimaryMulti };
