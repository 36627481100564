import { Modal, SelectedAddress, setupCanadaPost } from '@Calix-Commerce/design-system/components';
import { getI18NText } from 'I18N';
import { useEffect, useState } from 'react';
import Countries from './Countries';
import States from './States';
import { Address, AddressFormType } from 'types';
import { Flex } from '@Calix-Commerce/design-system/layout';
import { loggedInAccountState } from 'state/atoms';
import { useRecoilValue } from 'recoil';
import * as yup from 'yup';
import { AddressDropdownInput, AddressFormContainer, AddressTextInput } from './styledComponents';
import { DEFAULT_SHIPPING_ADDRESS_COUNTRY } from 'utils/constants/checkout';

export const AddressForm = ({
  favoriteAddresses = [],
  handleSelectFavorite,
  handleRemoveFavorite,
  handleAddressChange,
  showFavoriteAdressSelect = true,
  isReadOnly = false,
  address = {
    addressId: undefined,
    address1: '',
    address2: '',
    city: '',
    country: '',
    state: '',
    zipcode: '',
  },
  errors = {
    address: '',
    city: '',
    state: '',
    zip: '',
  },
  setError,
}: AddressFormType) => {
  const country = address.country || '';
  const [modal, setModal] = useState({ showModal: false, removeOption: undefined });
  const address1Id = 'oe-form-fields-shipping-form--address1Shipping';
  const { businessCountry } = useRecoilValue(loggedInAccountState);

  const onAddressChange = (address: Address) => {
    handleAddressChange(address, isValidAddress(address).isValid);
  };

  useEffect(() => {
    setupCanadaPost(
      {
        elements: [
          {
            element: address1Id,
            field: 'Line1',
            mode: 1,
          },
        ],
        options: {
          key: import.meta.env.VITE_CANADA_POST_KEY,
          autoSearch: false,
          bar: {
            showLogo: false,
            logoLink: false,
          },
        },
        defaultCountry: businessCountry || DEFAULT_SHIPPING_ADDRESS_COUNTRY,
      },
      (selectedAddress: SelectedAddress) => {
        onAddressChange(selectedAddress as Address);
        setError({
          ...errors,
          address: '',
          city: '',
          state: '',
          zip: '',
        });
      }
    );
  }, []);

  const handleSetError = (attribute, event, errorMessage) => {
    setError({
      ...errors,
      [attribute]: event.target.value && event.target.value.trim() ? '' : errorMessage,
    });
  };

  return (
    <AddressFormContainer>
      <Modal
        isOpen={modal.showModal}
        position={'center'}
        title="Remove from favorites"
        content="Are you sure you want to remove this address from favorites?"
        flag="Warning"
        onConfirmation={() => {
          handleRemoveFavorite && handleRemoveFavorite(modal.removeOption);
          setModal({ showModal: false, removeOption: undefined });
        }}
        onCancel={() => {
          setModal({ showModal: false, removeOption: undefined });
        }}
      ></Modal>
      <Flex direction="column">
        {showFavoriteAdressSelect && (
          <AddressDropdownInput
            label="Favorite"
            options={[{ id: 'fav', text: 'Favorite', hideRemoveIcon: true }, ...favoriteAddresses]}
            selected={address.addressId || 'fav'}
            handleSelectOption={(selected) => {
              handleSelectFavorite && handleSelectFavorite(selected);
              setError({
                address: '',
                city: '',
                state: '',
                zip: '',
              });
            }}
            handleRemoveOption={(option) => {
              if (option === 'fav') return;

              setModal({ showModal: true, removeOption: option });
            }}
          ></AddressDropdownInput>
        )}

        <AddressTextInput
          label="Address"
          required={true}
          error={errors.address}
          isReadOnly={isReadOnly}
          inputProps={{
            onChange: (event) => {
              handleSetError('address', event, getI18NText('ADDRESS_1_ERROR_MESSAGE'));

              onAddressChange({
                ...address,
                address1: event.currentTarget.value,
              });
            },
            id: address1Id,
            maxLength: '40',
            value: address.address1,
          }}
        ></AddressTextInput>

        <AddressTextInput
          label="Address 2"
          isReadOnly={isReadOnly}
          inputProps={{
            maxLength: '40',
            value: address.address2,
            onChange: (event) => {
              onAddressChange({
                ...address,
                address2: event.currentTarget.value,
              });
            },
          }}
        ></AddressTextInput>

        <AddressTextInput
          label="City"
          isReadOnly={isReadOnly}
          required={true}
          error={errors.city}
          inputProps={{
            maxLength: '60',
            value: address.city,
            onChange: (event) => {
              handleSetError('city', event, getI18NText('CITY_ERROR_MESSAGE'));

              onAddressChange({
                ...address,
                city: event.currentTarget.value,
              });
            },
          }}
        ></AddressTextInput>

        {States[country] ? (
          <AddressDropdownInput
            label="State/Province/Region"
            isReadOnly={isReadOnly}
            required={true}
            options={States[country]}
            selected={address.state}
            handleSelectOption={(selected) => {
              onAddressChange({
                ...address,
                state: selected.id,
              });

              setError({
                ...errors,
                state: '',
              });
            }}
            error={errors.state}
          ></AddressDropdownInput>
        ) : (
          <AddressTextInput
            label="State/Province/Region"
            isReadOnly={isReadOnly}
            required={true}
            error={errors.state}
            inputProps={{
              onChange: (event) => {
                handleSetError('state', event, getI18NText('STATE_ERROR_MESSAGE'));

                onAddressChange({
                  ...address,
                  state: event.currentTarget.value,
                });
              },
              maxLength: '60',
              value: address.state,
            }}
          ></AddressTextInput>
        )}

        <AddressTextInput
          label="Zip/Postal Code"
          isReadOnly={isReadOnly}
          required={true}
          error={errors.zip}
          inputProps={{
            onChange: (event) => {
              handleSetError('zip', event, getI18NText('ZIPCODE_ERROR_MESSAGE'));

              onAddressChange({
                ...address,
                zipcode: event.currentTarget.value,
              });
            },
            maxLength: '60',
            value: address.zipcode,
          }}
        ></AddressTextInput>

        <AddressDropdownInput
          label="Country"
          isReadOnly={isReadOnly}
          required={true}
          options={Countries}
          selected={!country ? 'Country' : country}
          error={errors.country}
          handleSelectOption={(selected) => {
            setError({
              ...errors,
              country: '',
            });

            onAddressChange({
              ...address,
              addressId: '',
              country: selected.id,
            });
          }}
        ></AddressDropdownInput>
      </Flex>
    </AddressFormContainer>
  );
};

export const isValidAddress = (
  address: Address
): {
  isValid: boolean;
  errors: {
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
  };
} => {
  try {
    yup
      .object()
      .shape({
        address: yup.string().required(getI18NText('ADDRESS_1_ERROR_MESSAGE')),
        city: yup.string().required(getI18NText('CITY_ERROR_MESSAGE')),
        country: yup.string().required(getI18NText('COUNTRY_ERROR_MESSAGE')),
        state: yup.string().required(getI18NText('STATE_ERROR_MESSAGE')),
        zip: yup.string().required(getI18NText('ZIPCODE_ERROR_MESSAGE')),
      })
      .validateSync(
        {
          ...address,
          address: address?.address1,
          zip: address?.zipcode,
        },
        {
          abortEarly: false,
        }
      );

    return {
      isValid: true,
      errors: {},
    };
  } catch (errors) {
    return {
      isValid: false,
      //@ts-ignore
      errors: errors.inner.reduce((allErrors, currentError) => {
        return {
          ...allErrors,
          [currentError.path]: currentError.message,
        };
      }, {}),
    };
  }
};
