import { useMutation } from '@apollo/client';
import {
  ADD_EXTERNAL_CONFIGURATION,
  UPDATE_EXTERNAL_CONFIGURATION,
} from 'connectors/mutations/configuration';

export function useWarrantyRequest() {
  const [addExternalConfiguration] = useMutation(ADD_EXTERNAL_CONFIGURATION);
  const [updateExternalConfiguration] = useMutation(UPDATE_EXTERNAL_CONFIGURATION);

  return { addExternalConfiguration, updateExternalConfiguration };
}
