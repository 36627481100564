import { getI18NText } from 'I18N';
import { BillingDropdownInput, BillingModal } from './styledComponents';
import { Container } from '@Calix-Commerce/design-system/layout';
import { BillingTextArea } from './BillingInfoForm';
import { AddressForm, isValidAddress } from 'components/forms/address/AddressForm';
import { useState, useEffect } from 'react';
import {
  authenticationState,
  loadingState,
  loggedInAccountState,
  notificationState,
} from 'state/atoms';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { SEND_CONTACT_MESSAGE } from 'connectors/mutations';
import { useMutation } from '@apollo/client';
import { useNotifyError } from 'utils/hooks';
import { Address } from 'types';
import { DEFAULT_SHIPPING_ADDRESS_COUNTRY } from 'utils/constants/checkout';

export const AddUpdateBillingAddress = ({
  billingAddresses = [],
  checkoutAddresses = [],
  setShowBillingModal,
}: {
  billingAddresses: {
    id: string;
    text: string;
  }[];
  checkoutAddresses: {
    address: Address;
    addressId: string;
  }[];
  setShowBillingModal: (show: boolean) => void;
}) => {
  const { accountId } = useRecoilValue(authenticationState);
  const { businessCountry } = useRecoilValue(loggedInAccountState);
  const setNotification = useSetRecoilState(notificationState);
  const setLoadingState = useSetRecoilState(loadingState);
  const [address, setAddress] = useState<Address>({} as Address);
  const [errors, setErrors] = useState<{
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
  }>();
  const [additionalInstruction, setAdditionalInstruction] = useState('');
  const [sendContactMessage] = useMutation(SEND_CONTACT_MESSAGE);
  const notifyError = useNotifyError({ fallbackMessage: 'Unable to send message.' });

  const handleBillToAddressChange = (newAddress) => {
    if (newAddress.id === 'addNewAddress') {
      setAddress({
        addressId: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
      });
    } else {
      const selectedAddress = checkoutAddresses.find(
        (checkoutAddress) => checkoutAddress.addressId === newAddress.id
      );

      if (selectedAddress) {
        setAddress({
          ...selectedAddress.address,
          addressId: selectedAddress?.addressId,
        });
      }
    }
  };

  const handleAddressChange = (newAddress) => {
    setAddress(newAddress);
  };

  const handleAddressSubmit = () => {
    const addressValidations = isValidAddress(address);

    if (addressValidations.isValid) {
      setLoadingState(true);
      setShowBillingModal(false);

      sendContactMessage({
        variables: {
          messageInput: {
            accountId,
            recipientType: 'AccountReceivable',
            type: 'BillToAddressUpdate',
            content:
              `\n${getContactMessageHeader(address)}\n<p></p>\n` +
              `<p>Address: ${address?.address1}</p>\n<p>Address 2: ${address?.address2 || ''}</p>` +
              `\n<p>City: ${address?.city}</p>\n<p>State/Province/Region: ${address?.state}</p>` +
              `\n<p>Zip/Postal Code: ${address?.zipcode}</p>\n<p>Country: ${address?.country}</p>` +
              `\n<p>Additional Instructions: ${additionalInstruction || ''}</p>`,
          },
        },
      })
        .then(() => {
          setLoadingState(false);

          setNotification({
            text: getI18NText('ADD_UPDATE_BILL_TO_ADDRESS_SUCCESS'),
            show: true,
            type: 'success',
            timeout: 4000,
          });
        })
        .catch((error) => {
          setLoadingState(false);
          notifyError(error);
        });
    } else {
      setErrors(addressValidations.errors);
      setNotification({
        text: getI18NText('EMPTY_FIELDS_ERROR'),
        show: true,
        type: 'error',
        timeout: null,
      });
    }
  };

  useEffect(() => {
    if (!address.country) {
      setAddress({
        ...address,
        country: businessCountry || DEFAULT_SHIPPING_ADDRESS_COUNTRY,
      } as Address);
    }
  }, []);

  return (
    <BillingModal
      isOpen={true}
      position={'center'}
      title={getI18NText('ADD_BILL_TO_ADDRESS')}
      confirmButtonText={getI18NText('SEND')}
      //@ts-ignore
      content={
        <>
          <p>{getI18NText('ADD_BILL_ADDRESS_INFO')}</p>
          <Container>
            <BillingDropdownInput
              //@ts-ignore
              dropDownProps={{
                className: 'select-address-dropdown',
              }}
              label={getI18NText('SELECTED_ADDRESS')}
              options={[
                { id: 'addNewAddress', text: getI18NText('ADD_NEW_ADDRESS') },
                ...billingAddresses,
              ]}
              selected={(address && address.addressId) || 'addNewAddress'}
              handleSelectOption={handleBillToAddressChange}
            ></BillingDropdownInput>
            <AddressForm
              showFavoriteAdressSelect={false}
              address={address}
              handleAddressChange={handleAddressChange}
              errors={errors}
              setError={setErrors}
            ></AddressForm>
            <BillingTextArea
              label={getI18NText('ADDITIONAL_INSTRUCTIONS')}
              textareaProps={{
                value: additionalInstruction,
                onChange: (event) => {
                  setAdditionalInstruction(event.currentTarget.value);
                },
              }}
            ></BillingTextArea>
          </Container>
        </>
      }
      flag="Warning"
      onConfirmation={() => {
        handleAddressSubmit();
      }}
      onCancel={() => {
        setShowBillingModal(false);
      }}
    ></BillingModal>
  );
};

const getContactMessageHeader = (address) => {
  if (address.addressId) {
    return (
      `<p>A billing address update was requested by your customer:</p>\n` +
      `<p>Address Record - <a href="${import.meta.env.VITE_SALESFORCE_URL}/${address.addressId}">Link</a></p>`
    );
  } else {
    return '<p>A new billing address was requested by your customer:</p>';
  }
};
