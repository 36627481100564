import { Modal } from '@Calix-Commerce/design-system/components';
import { Container } from '@Calix-Commerce/design-system/layout';
import styled from '@emotion/styled';
import { DropdownInput, FormInputLabel } from 'components/Common';

export const ItemRow = styled.div`
  display: flex;
  flex-flow: column;
  align-items: start;
  margin-bottom: 15px;

  input {
    width: 75%;
    margin-left: 10px;
  }

  &.billing-file-upload {
    .error-text {
      width: 100%;
      margin-top: 0px;
      margin-bottom: 6px;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
    input {
      border: 0;
      padding: 0;
      box-shadow: none;
      cursor: pointer;
      font-weight: bold;
      color: #333333;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      line-height: 150%;
      background-color: transparent;
      &::file-selector-button {
        background-color: #ffffff;
        outline: 0;
        box-shadow: none;
        border: 1px solid #ccc;
        padding: 5px 10px;
        border-radius: 4px;
        font-weight: bold;
        color: #333333;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        cursor: pointer;
        &:hover {
          background-color: #ebebeb;
        }
      }
    }
  }

  @media (max-width: 990px) {
    flex-flow: column;
    align-items: start;
  }
`;

export const Item = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  .read-only-text {
    margin-left: 10px;
  }

  .rem-character-text {
    color: #808080;
    font-size: 0.9rem;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 150%;
    margin-left: 10px;
  }

  &.billing-po-number {
    align-items: flex-start;

    label {
      margin-top: 7px;
    }

    .billing-input-container {
      width: 75%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      position: relative;

      input[type='file'] {
        width: 100%;
        color: transparent;
        z-index: 10;
      }
      .upload-file-label {
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-weight: bold;
        color: #333333;
        cursor: pointer;
        line-height: 150%;
        width: fit-content;
        position: absolute;
        left: 118px;
        top: -3px;
        z-index: 11;
      }
    }
  }

  @media (max-width: 990px) {
    flex-flow: column;
    align-items: start;
  }
`;

export const BillingFormInputLabel = styled(FormInputLabel)`
  padding: 0 15px 5px 15px;
`;

export const InputTextWarning = styled.p`
  color: #a94442;
  padding: 0 15px 5px 0px;
  margin-left: calc(25% + 10px);
  @media (max-width: 990px) {
    padding-left: 0;
    margin-left: 0;
  }
`;

export const BillingDropdownInput = styled(DropdownInput)`
  width: 75%;
  margin-left: 10px;

  div {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  &.item-row {
    width: 100%;

    .bill-to-address-select {
      input {
        width: 100%;
      }
    }
  }

  .item-name {
    padding: 0 15px 5px 5px;
    width: 168px;
  }
`;

export const BillingContainer = styled(Container)`
  width: 80%;
  margin: 0;
`;

export const BillingModal = styled(Modal)`
  .MuiPaper-root {
    max-width: 800px;
  }

  .MuiDialogTitle-root {
    font-size: 1.25rem;
    font-weight: bold;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  p {
    font-size: 1rem;
    line-height: 150%;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  div {
    .error-text {
      color: #a94442;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
  }

  .item-row {
    margin-left: 0;
  }

  .item-name {
    padding: 0 15px 5px 15px;
    width: 27%;
  }
`;

export const PoNumberPopup = styled(Modal)`
  .MuiPaper-root {
    max-width: 600px;
  }

  .MuiDialogTitle-root {
    font-size: 1.25rem;
    font-weight: bold;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  .po-number-info-1 {
    margin-top: 25px;

    span,
    b {
      color: #333333;
      font-size: 1rem;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
  }

  .po-number-info-2 {
    margin-top: 25px;
    margin-bottom: 25px;

    span {
      color: #333333;
      font-size: 1rem;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
  }

  .duplicate-po-number_list {
    max-height: 300px;
    overflow-y: auto;

    table {
      border-collapse: collapse;
      width: 100%;

      thead {
        tr {
          position: sticky;
          top: 0px;
          border-bottom: 2px solid #ddd;

          th {
            line-height: 1.42857143;
            font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
            font-size: 1rem;
            color: #333333;
            padding: 8px;
            text-align: left;
            background-color: #ffffff;
          }
        }
      }

      tbody {
        tr {
          &:nth-child(odd) {
            td {
              background-color: #f9f9f9;
            }
          }

          td {
            padding: 8px;
            line-height: 1.42857143;
            vertical-align: top;
            border-top: 1px solid #ddd;
          }
        }
      }
    }
  }
`;

export const TextArea = styled.textarea`
  margin-left: 10px;
  width: 75%;
  height: 54px;
  font-size: 14px;
  line-height: 1.4;
  color: #555555;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: 0;
  resize: none;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

  &:focus {
    border-color: #66afe9;
    box-shadow:
      inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
`;
