import { Container, Flex } from '@Calix-Commerce/design-system/layout';
import {
  RecipientItem,
  ReviewAdditionalConfirmation,
  ReviewPreviewContent,
} from './styledComponents';
import { getI18NText } from 'I18N';
import { ButtonRounded, Input } from '@Calix-Commerce/design-system/components';
import { useCallback, useState } from 'react';
import { Popper } from '@mui/material';
import OrderExamplImg from 'assets/order-confirmation-example.png';
import { useRecoilState } from 'recoil';
import { storageCurrentQuoteSelector } from 'state/selectors';
import * as yup from 'yup';

const Recipients = ({ email, disabled, removeEmail }) => {
  return (
    <RecipientItem className={disabled ? 'item-disabled' : ''}>
      {email}{' '}
      {!disabled && (
        <span
          className="remove-icon"
          onClick={() => {
            removeEmail(email);
          }}
        >
          X
        </span>
      )}
    </RecipientItem>
  );
};

export const AdditionalOrderConfirmation = ({ recipients, onRecipientsChange }) => {
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [email, setEmail] = useState('');
  const [currentQuote] = useRecoilState(storageCurrentQuoteSelector);

  const [previewAnchorEl, setPreviewAnchorEl] = useState<EventTarget & HTMLInputElement>();
  const { preparedByEmail } = currentQuote;

  const addRecipient = useCallback(
    (event) => {
      event.preventDefault();

      if (
        yup
          .object()
          .shape({
            email: yup
              .string()
              .email()
              .notOneOf([preparedByEmail, ...recipients]),
          })
          .isValidSync({
            email,
          })
      ) {
        onRecipientsChange([...recipients, email]);
        setEmail('');
      }
    },
    [email, recipients]
  );

  const removeEmail = useCallback(
    (removeEmailId) => {
      onRecipientsChange(recipients.filter((recipient) => recipient !== removeEmailId));
    },
    [recipients]
  );

  const validateEmail = (userEmail: string): boolean => {
    return yup
      .object()
      .shape({
        userEmail: yup.string().email(),
      })
      .isValidSync({
        userEmail,
      });
  };

  return (
    <ReviewAdditionalConfirmation>
      <Flex>
        <div className="label">
          <label htmlFor="quote-recipients">{getI18NText('ADDITIONAL_RECIPIENT')}</label>
        </div>
        <form className="recipients-input" onSubmit={addRecipient}>
          <Popper open={Boolean(previewAnchorEl)} anchorEl={previewAnchorEl} placement="auto-start">
            <ReviewPreviewContent>
              <Container className="example-container">
                <img src={OrderExamplImg}></img>
                <div className="example-info">{getI18NText('REVIEW_EXAMPLE_INFO')}</div>
              </Container>
            </ReviewPreviewContent>
          </Popper>
          <div className="input-container">
            {!isValidEmail && (
              <span className="error-text">{getI18NText('INVALID_EMAIL_ADDRESS_ERROR')}</span>
            )}
            <Input
              id="quote-recipients"
              type="email"
              onFocus={(event) => {
                setPreviewAnchorEl(event.currentTarget);
              }}
              onBlur={(event) => {
                if (event.currentTarget.value) {
                  setIsValidEmail(validateEmail(event.currentTarget.value));
                } else {
                  setIsValidEmail(true);
                }

                setPreviewAnchorEl(undefined);
              }}
              onChange={(event) => {
                setEmail(event.currentTarget.value);
              }}
              value={email}
              maxLength={360}
              error={!isValidEmail}
            />
          </div>
          <ButtonRounded type="submit">{getI18NText('ADD_RECIPIENT')}</ButtonRounded>
        </form>
      </Flex>
      <div className="recipients-list">
        <Recipients email={preparedByEmail} disabled={true} removeEmail={removeEmail} />
        {recipients.map((recipient) => (
          <Recipients
            email={recipient}
            disabled={false}
            key={recipient}
            removeEmail={removeEmail}
          />
        ))}
      </div>
    </ReviewAdditionalConfirmation>
  );
};
