import { isValidAddress } from 'components/forms/address/AddressForm';
import { downloadFile } from './file';
import { Address, PhoneInput } from 'types';

const checkoutDataKey = 'oe.ordering.storedCheckoutFieldsData';

export const saveCheckoutDataInLocalStorage = (checkoutData: any): void => {
  const { transactionId } = checkoutData;
  const existingCheckoutData = JSON.parse(localStorage.getItem(checkoutDataKey) || '{}');
  let updatedData;

  if (transactionId === existingCheckoutData.transactionId) {
    updatedData = {
      ...existingCheckoutData,
      ...checkoutData,
    };
  } else {
    updatedData = checkoutData;
  }

  localStorage.setItem(checkoutDataKey, JSON.stringify(updatedData));
};

export const removeCheckoutDataFromLocalStorage = () => {
  localStorage.removeItem(checkoutDataKey);
};

export const readCheckoutSavedData = (
  transactionId
): {
  shippingAddress?: Address;
  billingAddress?: Address & {
    lesingAgent: boolean;
  };
  shippingContact?: {
    phoneNumber?: PhoneInput;
    email: string;
    confirmEmail: string;
    lastName: string;
    firstName: string;
  };
  shippingInfo:
    | {
        shipmentSchedule: any;
        packingInstructions: string | undefined;
        shippingInstructions: string | undefined;
      }
    | undefined;
  poNumber: string;
  poFile: any;
  lesingAgent?: boolean;
} => {
  const localStorageData = getSavedCheckoutData(transactionId);

  const {
    shippingAddress,
    shippingInfo,
    shippingContact,
    billingAddress,
    poNumber,
    poFile,
    lesingAgent,
  } = localStorageData;
  return {
    shippingAddress: shippingAddress
      ? {
          ...shippingAddress,
          addressId: shippingAddress.id,
        }
      : undefined,
    shippingInfo: shippingInfo
      ? {
          packingInstructions: shippingInfo.packingInstructions,
          shippingInstructions: shippingInfo.shippingInstructions,
          shipmentSchedule: shippingInfo.shipmentSchedule,
        }
      : undefined,
    shippingContact: shippingContact
      ? {
          firstName: shippingContact.firstName,
          lastName: shippingContact.lastName,
          email: shippingContact.email,
          confirmEmail: shippingContact.emailConfirm,
          phoneNumber: {
            countryCode: shippingContact.phoneCountry,
            areaCode: shippingContact.phoneArea,
            number: shippingContact.phoneNumber,
            extension: shippingContact.phoneExtension,
          },
        }
      : undefined,
    billingAddress,
    poNumber,
    lesingAgent,
    poFile,
  };
};

export const getSavedCheckoutData = (transactionId) => {
  const localStorageData = JSON.parse(localStorage.getItem(checkoutDataKey) || '{}');

  if (transactionId === localStorageData.transactionId) {
    return localStorageData;
  } else {
    return {};
  }
};

export const isAllShipmentLinesZero = (shipment) => {
  return shipment.lines.every((line) => line.quantity === 0);
};

export const getShipmentQuantityError = (shipments) => {
  const errors = {};
  const partNumberTotals: {
    partNumber: string;
    quantity: number;
    totalQuantity: number;
  }[] = [];

  shipments.forEach((shipment) => {
    shipment.lines.forEach((line) => {
      const existingData = partNumberTotals.find(
        (partNumberTotal) => partNumberTotal.partNumber === line.partNumber
      );

      if (existingData) {
        existingData.quantity += line.quantity;
      } else {
        partNumberTotals.push({
          partNumber: line.partNumber,
          quantity: line.quantity,
          totalQuantity: line.totalQuantity,
        });
      }
    });
  });

  partNumberTotals.forEach((partNumberTotal) => {
    const diffInQuantity = partNumberTotal.quantity - partNumberTotal.totalQuantity;
    let error;

    if (diffInQuantity > 0) {
      error = `${diffInQuantity} over total`;
    } else if (diffInQuantity < 0) {
      error = `${-diffInQuantity} under total`;
    }

    if (error) {
      errors[partNumberTotal.partNumber] = error;
    }
  });

  return errors;
};

export const validFavAddressDetails = (address, favorites): boolean => {
  const { address1, address2, city, state, zipcode, country } = address;
  const isValid = isValidAddress(address).isValid;

  return (
    isValid &&
    !favorites.some((favorite) => {
      const favAddress = favorite.address;

      return (
        favAddress.address1 === address1 &&
        (favAddress.address2 ?? '') === address2 &&
        favAddress.city === city &&
        favAddress.state === state &&
        favAddress.zipcode === zipcode &&
        favAddress.country === country
      );
    })
  );
};

export const downloadPoFile = (type, base64, exportName) => {
  downloadFile({
    fileName: exportName,
    fileData: base64,
    type,
  });
};

export const mapCheckoutDataToQuote = ({
  shippingAddress,
  shipmentSchedules,
  shippingContact,
  billingAddress,
  recipients,
  lesingAgent,
  poNumber,
  shippingInfo,
}) => {
  return {
    confirmationRecipients: recipients,
    shippingAddress: {
      name: shippingAddress?.name,
      address1: shippingAddress?.address1,
      address2: shippingAddress?.address2,
      city: shippingAddress?.city,
      state: shippingAddress?.state,
      zipcode: shippingAddress?.zipcode,
      country: shippingAddress?.country,
    },
    requestedShippingType: shipmentSchedules.length > 1 ? 'Multiple Shipments' : 'Specified Date',
    requestedShippingDate: shipmentSchedules.length === 1 ? shipmentSchedules[0].shipDate : null,
    multipleShipments:
      shipmentSchedules.length > 1
        ? shipmentSchedules.reduce((schedule1, schedule2) => {
            return `${schedule1}\n${schedule2.shipDate}${schedule2.lines.reduce((line1, line2) => {
              return `${line1}-${line2.partNumber}x${line2.quantity}`;
            }, '')}`;
          }, '')
        : null,
    shippingContactFirstName: shippingContact?.firstName,
    shippingContactLastName: shippingContact?.lastName,
    shippingContactPhone: {
      countryCode: shippingContact?.phoneNumber?.countryCode
        ? parseInt(shippingContact?.phoneNumber?.countryCode + '')
        : null,
      areaCode: shippingContact?.phoneNumber?.areaCode
        ? parseInt(shippingContact?.phoneNumber?.areaCode + '')
        : null,
      number: shippingContact?.phoneNumber?.number,
      extension: shippingContact?.phoneNumber?.extension,
    },
    shippingContactEmail: shippingContact?.email,
    billingAddress: {
      name: billingAddress?.name,
      address1: billingAddress?.address1,
      address2: billingAddress?.address2,
      city: billingAddress?.city,
      state: billingAddress?.state,
      zipcode: billingAddress?.zipcode,
      country: billingAddress?.country,
    },
    shipmentSchedule: shipmentSchedules,
    packingInstructions: shippingInfo.packingInstructions,
    shippingInstructions: shippingInfo.shippingInstructions,
    lesingAgent,
    poNumber,
  };
};
