import { useState } from 'react';
import { Tooltip, PrimaryTable as BaseTable } from '@Calix-Commerce/design-system/components';
import { Flex } from '@Calix-Commerce/design-system/layout';
import { GroupNameInput, IconTableHeader, QuoteGroupDragicon } from './styledComponents';
import { CloneSvg, TrashSvg } from '@Calix-Commerce/design-system/assets';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { QuickAdd } from './QuickAdd';
import { TotalRow } from './TotalRow';
import { DraggableQuoteItem } from './TableRows';
import { LineItem, QuoteGroupDetail, QuoteGroupItem } from 'types';

type GroupProps = {
  groupData: QuoteGroupDetail;
  handleAddPart?: (partNumber: string, groupId: number, groupName: string) => void;
  searchPartNumbers?: (searchTerm: string, groupId: number) => void;
  handleOnGroupDrag: (e: React.DragEvent, groupId: string) => void;
  handleOnDragOver: (e: React.DragEvent, groupId: string) => void;
  handleOnDrop: (e: React.DragEvent) => void;
  handleItemOnDrag: (e: React.DragEvent, itemId: string, groupId: string) => void;
  handleItemUpdate: (groupId: number, newItem: QuoteGroupItem) => void;
  handleRemoveGroup?: (groupId: number) => void;
  handleCloneGroup: (groupId: number) => void;
  handleRenameGroup?: (quoteGroupId: number, newName: string) => void;
  handleRemoveItem?: (removedItem: LineItem) => void;
  readonly: boolean;
  allowRemovalOnReadOnly?: boolean;
  isQuoteOrdered?: boolean;
  results: { partNumber: string }[];
};

const Group = ({
  groupData,
  handleAddPart,
  results,
  searchPartNumbers,
  handleOnGroupDrag,
  handleOnDragOver,
  handleOnDrop,
  handleItemOnDrag,
  handleItemUpdate,
  handleRemoveGroup,
  handleCloneGroup,
  handleRenameGroup,
  handleRemoveItem,
  readonly,
  isQuoteOrdered,
  allowRemovalOnReadOnly,
}: GroupProps) => {
  const { items, id, isUnassigned, displaySubTotal } = groupData;
  const [groupName, setGroupName] = useState(groupData.groupName);
  const canEdit = !isUnassigned && !readonly;

  const handleGroupNameChange = () => {
    handleRenameGroup && handleRenameGroup(groupData.id, groupName);
  };

  return (
    <div
      draggable={canEdit}
      onDragStart={(e) => {
        handleOnGroupDrag(e, String(id));
      }}
      onDragOver={(e) => {
        handleOnDragOver(e, String(id));
      }}
      onDrop={(e) => {
        handleOnDrop(e);
      }}
    >
      <BaseTable>
        <thead>
          <tr>
            <th>
              {canEdit ? (
                <Tooltip
                  className="tooltipShow"
                  //@ts-expect-error used styled component
                  Icon={QuoteGroupDragicon}
                  placement="top"
                  content="You can change the order of your groups by dragging them above and below other groups. Note: Unassigned is always the last group."
                ></Tooltip>
              ) : null}
            </th>
            <th>
              <Flex alignment="center">
                <GroupNameInput
                  id={id + '-input'}
                  type="text"
                  disabled={!canEdit}
                  value={groupName}
                  onChange={(e) => {
                    setGroupName(e.target.value);
                  }}
                  onBlur={() => handleGroupNameChange()}
                />
                {canEdit && (
                  <Tooltip
                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                    Icon={EditOutlinedIcon}
                    placement="right"
                    content="Click group name to edit."
                    onClick={() => {
                      document?.getElementById(id + '-input')?.focus();
                    }}
                  />
                )}
              </Flex>
            </th>
            <th></th>
            <th></th>
            <th></th>
            <IconTableHeader>
              {canEdit && (
                <>
                  <button onClick={() => handleCloneGroup(id)}>
                    <CloneSvg />
                  </button>
                  <button onClick={() => handleRemoveGroup && handleRemoveGroup(id)}>
                    <TrashSvg />
                  </button>
                </>
              )}
            </IconTableHeader>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            return (
              <DraggableQuoteItem
                key={item.sequenceNumber + item.partNumber + groupData.id + index}
                item={item}
                index={index}
                groupId={id}
                handleItemUpdate={handleItemUpdate}
                handleOnItemDrag={handleItemOnDrag}
                handleRemoveItem={handleRemoveItem}
                readonly={readonly}
                allowRemovalOnReadOnly={allowRemovalOnReadOnly}
                isQuoteOrdered={isQuoteOrdered}
              />
            );
          })}
        </tbody>
      </BaseTable>
      <BaseTable>
        <tbody>
          {!readonly ? (
            <QuickAdd
              results={results}
              handleAddPart={handleAddPart}
              searchPartNumbers={searchPartNumbers}
              readonly={readonly}
              groupId={id}
              groupName={groupName}
            />
          ) : null}
        </tbody>
      </BaseTable>
      <TotalRow
        backgroundColor="#e0e0e0"
        totalText={String(groupData.id) === 'extendedWarranty' ? 'Warranty Total' : 'Sub-Total'}
        price={displaySubTotal}
      />
    </div>
  );
};

export { Group };
