import { Modal } from '@Calix-Commerce/design-system/components';
import capitalize from 'lodash/capitalize';

const getContent = ({
  isLoading,
  action,
}: {
  isLoading: boolean;
  action: 'delete' | 'archive' | 'unarchive';
}) => {
  if (isLoading) {
    return `Please wait, while we ${action} the quote...`;
  } else if (action) {
    return `Are you sure you want to ${action} the quote?`;
  } else {
    return 'Something went wrong';
  }
};

const getWarning = ({ action }: { action: 'archive' | 'unarchive' }) => {
  return `The current quote is being modified. Do you want to ${action} the quote?`;
};

export const ConfirmationModal = ({
  action = 'delete',
  isLoading = false,
  showWarning = false,
  onConfirm,
  onCancel,
}: {
  action?: 'delete' | 'archive' | 'unarchive';
  showWarning?: boolean;
  isLoading?: boolean;
  onConfirm: (() => void) | null;
  onCancel: () => void;
}) => {
  const showWarningLabel = showWarning && action !== 'delete';
  return (
    <Modal
      isOpen={true}
      title={`${capitalize(action)} Quote`}
      content={showWarningLabel ? getWarning({ action }) : getContent({ action, isLoading })}
      flag={showWarningLabel ? 'WARNING' : ''}
      isLoading={isLoading}
      onConfirmation={onConfirm}
      onDismiss={onCancel}
      onCancel={onCancel}
      onClose={onCancel}
      confirmButtonText={action.toUpperCase()}
    />
  );
};
