import { Modal } from '@Calix-Commerce/design-system';
type RemoveGroupModalProps = { isOpen: boolean; onClose: () => void; onConfirmation: () => void };

const RemoveGroupModal = ({ onClose, onConfirmation, ...props }: RemoveGroupModalProps) => {
  return (
    <Modal
      title="Remove Group"
      content="Are you sure you want to remove this group from this quote?"
      confirmButtonText="REMOVE"
      onConfirmation={() => {
        onConfirmation();
        onClose();
      }}
      onDismiss={onClose}
      onCancel={onClose}
      {...props}
    />
  );
};

export { RemoveGroupModal };
