import { PrimaryTableRow } from '@Calix-Commerce/design-system/components';
import { QuoteTableRowActions, QuoteTableRowsProps } from 'types';
import { QuoteItemDetails, QuoteItemRow } from '../QuoteItemRow';
import { QuoteItemTableRow } from './styledComponents';

export const DraggableQuoteItem = ({
  item,
  groupId,
  handleItemUpdate,
  handleOnItemDrag,
  handleRemoveItem,
  index,
  readonly,
  allowRemovalOnReadOnly,
  isQuoteOrdered,
}: QuoteTableRowsProps & QuoteTableRowActions) => {
  const { id } = item;

  return (
    <QuoteItemTableRow
      darkBackground={index % 2 !== 0}
      draggable
      onDragStart={(e) => handleOnItemDrag && handleOnItemDrag(e, id, String(groupId))}
    >
      <QuoteItemRow
        item={item}
        readonly={readonly}
        index={index}
        isQuoteOrdered={isQuoteOrdered}
        groupId={groupId}
        allowRemovalOnReadOnly={allowRemovalOnReadOnly}
        handleItemUpdate={handleItemUpdate}
        handleRemoveItem={handleRemoveItem}
      />
    </QuoteItemTableRow>
  );
};

export const QuoteItem = ({
  item,
  groupId,
  index,
  isQuoteOrdered,
}: QuoteTableRowsProps & QuoteTableRowActions) => {
  return (
    <PrimaryTableRow darkBackground={index % 2 !== 0}>
      <QuoteItemDetails
        item={item}
        index={index}
        isQuoteOrdered={isQuoteOrdered}
        groupId={groupId}
      />
    </PrimaryTableRow>
  );
};
