import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  RepresentativeSimpleMenu,
  RepresentativeExtendedMenu,
} from '@Calix-Commerce/design-system/components';

import {
  authenticationState,
  selectedAccountState,
  notificationState,
  loadingState,
} from 'state/atoms';
import { useNotifyError } from 'utils/hooks';
import { CUSTOMER_OPS_EMAIL } from 'utils/constants';
import { SEND_CONTACT_MESSAGE } from 'connectors/mutations/contact';

export const RepresentativeMenu = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { endCustomerAccountId } = useRecoilValue(authenticationState);
  const { accountLead } = useRecoilValue(selectedAccountState);
  const setNotification = useSetRecoilState(notificationState);
  const setLoading = useSetRecoilState(loadingState);

  const notifyError = useNotifyError({ fallbackMessage: 'Unable to send message.' });

  const [sendContactMessage, { loading: messageLoading }] = useMutation(SEND_CONTACT_MESSAGE);

  useEffect(() => {
    setLoading(messageLoading);
  }, [messageLoading, setLoading]);

  const notifyEmptyMessage = () => {
    setNotification({
      type: 'error',
      show: true,
      text: 'Please insert a message to send to your Customer Operations Representative',
      timeout: null,
    });
  };

  const notifyMessageSuccess = () => {
    setNotification({
      type: 'success',
      show: true,
      text: 'Thank you! Your request has been sent to your Customer Operations Representative.',
      timeout: 4000,
    });
  };

  const handleMessageSubmit = ({ message, subject }: { message: string; subject: string }) => {
    const trimmedMessage = message.trim();

    if (!trimmedMessage) {
      notifyEmptyMessage();
    } else {
      sendContactMessage({
        variables: {
          messageInput: {
            accountId: endCustomerAccountId,
            recipientType: 'CustomerOperations',
            type: subject,
            content: trimmedMessage,
          },
        },
      })
        .then(() => {
          notifyMessageSuccess();
          setIsDropdownOpen(false);
        })
        .catch(notifyError);
    }
  };

  return accountLead ? (
    <RepresentativeExtendedMenu
      isOpen={isDropdownOpen}
      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      onClose={() => setIsDropdownOpen(false)}
      onMessageSubmit={handleMessageSubmit}
      customerOpsEmail={CUSTOMER_OPS_EMAIL}
      title="Your Customer Operations Rep."
      infoBlockTitle="Your Customer Operations Representative"
      representative={accountLead}
    />
  ) : (
    <RepresentativeSimpleMenu
      menuProps={{}}
      isOpen={isDropdownOpen}
      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      onClose={() => setIsDropdownOpen(false)}
      onMessageSubmit={() => null}
      customerOpsEmail={CUSTOMER_OPS_EMAIL}
      title="Your Customer Operations Rep."
      infoBlockTitle="Your Customer Operations Representative"
      subjectOptions={[]}
    />
  );
};
