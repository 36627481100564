import { ModalConfig } from 'types';

export const noCookiesConfig: ModalConfig = {
  title: 'Error: Your browser is blocking cookies.',
  content:
    'We require cookies to be allowed for the My Calix Store to function properly. Please disable cookie blocking in your browser and reload the page to proceed. Contact your administrator for assistance.',
  onConfirmation: () => window.location.reload(),
  confirmButtonText: 'RELOAD',
};

export const noStorageConfig: ModalConfig = {
  title: 'Error: Your browser is blocking the Local Storage.',
  content:
    'We require Local Storage to be allowed for the My Calix Store to function properly. Please re-enable the Local Storage in your browser and reload the page to proceed. Contact your administrator for assistance.',
  onConfirmation: () => window.location.reload(),
  confirmButtonText: 'RELOAD',
};

export const noConnectionConfig: ModalConfig = {
  title: `You're Offline`,
  content: `It looks like you've lost your internet connection. Once you are reconnected, please refresh the page.`,
  onDismiss: null,
  onConfirmation: null,
};
