import { ProductImage, Tooltip } from '@Calix-Commerce/design-system/components';
import { Flex } from '@Calix-Commerce/design-system/layout';
import Collapse from '@mui/material/Collapse';
import { noImageSrc, TrashSvg } from '@Calix-Commerce/design-system/assets';
import { PackageBom, QuoteGroupItem, QuoteTableRowActions, QuoteTableRowsProps } from 'types';
import {
  BomListTable,
  ProductDetailsButton,
  QuoteGroupDragicon,
  QuoteQuantityText,
  TrashIconButton,
  quantityInputStyles,
  quantityTrashStyles,
} from './styledComponents';
import { PriceDisplay } from 'components';
import { useState } from 'react';

const renderWarrantyDetails = (bom: PackageBom[], darkBackground: boolean) => {
  if (bom.length) {
    return (
      <BomListTable style={{ width: '400px' }}>
        <thead>
          <tr>
            <th className={darkBackground ? 'darkBackground' : 'whiteBackground'}>Part</th>
            <th className={darkBackground ? 'darkBackground' : 'whiteBackground'}>Description</th>
            <th className={darkBackground ? 'darkBackground' : 'whiteBackground'}>Qty</th>
          </tr>
        </thead>
        <tbody>
          {bom.map((item) => {
            return (
              <tr key={item.partNumber}>
                <td style={{ whiteSpace: 'nowrap' }}>{item.partNumber}</td>
                <td>{item.productDetails.description}</td>
                <td>{item.quantity}</td>
              </tr>
            );
          })}
        </tbody>
      </BomListTable>
    );
  }
};

const ItemDescription = ({
  item,
  isQuoteOrdered,
}: {
  item: QuoteGroupItem;
  isQuoteOrdered?: boolean;
}) => {
  const { priceWarnings } = item;

  return (
    <Flex direction="column" style={{ width: 'fit-content' }}>
      Unit Price: <PriceDisplay item={item} showPriceWarnings={!isQuoteOrdered} />
      {priceWarnings?.map((warning) => {
        return (
          <b style={{ color: '#f00' }} key={warning}>
            {warning}
          </b>
        );
      })}
    </Flex>
  );
};

const ItemQuantity = ({
  item,
  readonly,
  allowRemovalOnReadOnly,
  groupId,
  handleItemUpdate,
  handleRemoveItem,
}: QuoteTableRowsProps & QuoteTableRowActions) => {
  const quantity = item.extendedWarrantyQuantity || item.quantity;

  return (
    <Flex direction="column" alignment="center">
      Quantity:
      {readonly ? (
        <QuoteQuantityText>{quantity}</QuoteQuantityText>
      ) : (
        <input
          style={quantityInputStyles}
          disabled={readonly}
          type="number"
          min="0"
          value={String(quantity)}
          onChange={({ target }) => {
            let newQuantity = parseInt(target.value);

            if (isNaN(newQuantity) || newQuantity < 0) {
              newQuantity = 0;
            }

            handleItemUpdate &&
              handleItemUpdate(groupId, {
                ...item,
                quantity: newQuantity,
              });
          }}
        />
      )}
      {!readonly || allowRemovalOnReadOnly ? (
        <TrashIconButton onClick={() => handleRemoveItem && handleRemoveItem(item)}>
          <TrashSvg
            // @ts-expect-error svg configuration error
            fill="#0B38DB"
            style={{ ...quantityTrashStyles }}
            alt="trash icon"
          ></TrashSvg>
        </TrashIconButton>
      ) : null}
    </Flex>
  );
};

const PriceDetails = ({
  item,
  isQuoteOrdered,
}: {
  item: QuoteGroupItem;
  isQuoteOrdered?: boolean;
}) => {
  return (
    <Flex direction="column" alignment="flex-end">
      <span style={{ textAlign: 'end' }}>
        Ext. Price:{' '}
        <b>
          <PriceDisplay item={item} showTotalPrice={true} showPriceWarnings={!isQuoteOrdered} />
        </b>
      </span>
    </Flex>
  );
};

const ProductDetails = ({ item, index }: QuoteTableRowsProps) => {
  const [isWarrantyDetailsExpanded, setIsWarrantyDetailsExpanded] = useState(false);
  const { partNumber, imgSource, description, packageBom, partName, itemWarnings, itemNotes } =
    item;
  const isPackage = item.packageBom.length;

  return (
    <Flex style={{ padding: '15px 0' }}>
      <ProductImage
        fallbackSrc={noImageSrc}
        src={imgSource}
        style={{ width: '150px', maxWidth: '100%', marginRight: '10px', maxHeight: '120px' }}
      />
      <div>
        <div>{partNumber}</div>
        <div style={{ marginBottom: '20px' }}>
          <b>{partName}</b>
        </div>
        <div>{description}</div>
        {itemNotes?.map((note) => {
          return (
            <ul key={note} style={{ paddingLeft: '10px' }}>
              <li>{note}</li>
            </ul>
          );
        })}
        {isPackage ? (
          <ProductDetailsButton
            onClick={() => setIsWarrantyDetailsExpanded(!isWarrantyDetailsExpanded)}
          >
            {isWarrantyDetailsExpanded ? '-' : '+'} Details
          </ProductDetailsButton>
        ) : null}
        {isWarrantyDetailsExpanded ? (
          <Collapse in={isWarrantyDetailsExpanded}>
            {renderWarrantyDetails(packageBom, index % 2 !== 0)}
          </Collapse>
        ) : null}
        {itemWarnings?.map((warning) => {
          return (
            <div style={{ color: '#f00' }} key={warning}>
              {warning}
            </div>
          );
        })}
      </div>
    </Flex>
  );
};

export const QuoteItemRow = ({
  item,
  readonly,
  index,
  isQuoteOrdered,
  handleItemUpdate,
  groupId,
  allowRemovalOnReadOnly,
  handleRemoveItem,
}: QuoteTableRowsProps & QuoteTableRowActions) => {
  return (
    <>
      <td style={{ width: '75px' }}>
        <Flex alignment="center" spacing="center" style={{ padding: '50px 0' }}>
          {!readonly && (
            <Tooltip
              className="tooltipShow"
              //@ts-expect-error used styled component
              Icon={QuoteGroupDragicon}
              placement="top"
              content="You can change the order of your groups by dragging them above and below other groups. Note: Unassigned is always the last group."
            />
          )}
        </Flex>
      </td>
      <td style={{ width: '400px' }}>
        <ProductDetails item={item} index={index} groupId={groupId} readonly={readonly} />
      </td>
      <td style={{ paddingTop: '15px', width: '200px' }}>
        <ItemDescription item={item} isQuoteOrdered={isQuoteOrdered}></ItemDescription>
      </td>
      <td style={{ padding: '15px 0 0 0', width: '100px' }}>
        <ItemQuantity
          item={item}
          groupId={groupId}
          index={index}
          readonly={readonly}
          handleItemUpdate={handleItemUpdate}
          allowRemovalOnReadOnly={allowRemovalOnReadOnly}
          handleRemoveItem={handleRemoveItem}
        />
      </td>
      <td style={{ padding: '15px 0 0 0', width: '0' }}></td>
      <td style={{ paddingTop: '15px', width: '200px' }}>
        <PriceDetails item={item} isQuoteOrdered={isQuoteOrdered} />
      </td>
    </>
  );
};

export const QuoteItemDetails = ({
  item,
  index,
  isQuoteOrdered,
  groupId,
}: {
  item: QuoteGroupItem;
  index: number;
  isQuoteOrdered?: boolean;
  groupId: number;
}) => {
  return (
    <>
      <td style={{ width: '400px' }}>
        <ProductDetails
          item={item}
          index={index}
          groupId={groupId}
          readonly={true}
        ></ProductDetails>
      </td>
      <td style={{ paddingTop: '15px', width: '200px' }}>
        <ItemDescription item={item} isQuoteOrdered={isQuoteOrdered}></ItemDescription>
      </td>
      <td style={{ padding: '15px 0 0 0', width: '100px' }}>
        <ItemQuantity item={item} groupId={groupId} index={index} readonly={true}></ItemQuantity>
      </td>
      <td style={{ padding: '15px 0 0 0', width: '0' }}></td>
      <td style={{ paddingTop: '15px', width: '200px' }}>
        <PriceDetails item={item} isQuoteOrdered={isQuoteOrdered}></PriceDetails>
      </td>
    </>
  );
};
