import styled from '@emotion/styled';

export const QuoteManagerWrapper = styled.div`
  padding: 2rem;
`;

export const MyCalixLink = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const TermsOfSaleLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const Title = styled.h1`
  font-size: 2.25em;
  font-weight: normal;
`;

export const ConfirmationContainer = styled.div`
  text-align: center;
`;
