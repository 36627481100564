import { gql } from '@apollo/client';
import { address } from './address';

export const QUOTE_LOCK_INFO = gql`
  fragment quoteLockInfo on Quote {
    transactionId
    lockedBy
    lockExpiry
    eCommercePermissions
    lastModifiedDate
    isOrdered
  }
`;

export const QUOTE_LIST_INFO = gql`
  fragment quoteListInfo on Quote {
    ...quoteLockInfo
    accountId
    projectId
    quoteNumber
    option
    revisionNumber
    quoteName
    quoteStatus
    quoteDescription
    preparedByName
    currencyCode
    total
    archived
    containsNonDiscoutedDiscountableItems
    calculatedOneTimeDiscount
    tags
    createdDate
    opportunityId
    isOrdered
    poNumber
    salesOrderNumber
  }
  ${QUOTE_LOCK_INFO}
`;

export const QUOTE_CART_INFO = gql`
  fragment quoteCartInfo on Quote {
    ...quoteListInfo
    accountName
    commerceComplete
    preparedByEmail
    expirationDate
    totalLineItemDiscounts
    totalNetPrice
    totalDiscount
    totalDiscountPercent
    warrantyTotal
    creator
    opportunityName
    requiresWarrantyConfiguration
    lineGroups {
      groupId
      lineItems {
        documentNumber
        parentDocumentNumber
        modelName
        partnerEntityId
        lineItemId
        quantity
        listPrice
        salePrice
        priceLine
        totalPrice
        partId
        groupNumber
        groupName
        sequenceNumber
        partNumber
        partDescription
        partName
        lastModifiedDate
        discountBy
        ongoingLine
        oneTimeDiscount
        optional
        lineItemDiscount
        packageBom {
          partNumber
          description
          quantity
        }
        extendedWarrantyQuantity
        subscriptionDuration
        warrantyForPartNumber
      }
    }

    quoteGroups {
      name
    }

    orderSubmittedDate
    packingInstructions
    shippingInstructions
    multipleShipments
    shipmentSchedule {
      lines {
        partNumber
        quantity
      }
      shipDate
    }
    requestedShippingType
    requestedShippingDate
    shippingContactLastName
    shippingContactFirstName
    shippingContactPhone {
      countryCode
      areaCode
      number
      extension
    }
    shippingContactEmail
    shippingAddress {
      ...address
    }
    poNumber
    poAttachment {
      name
      type
      url
      base64
    }
    lesingAgent
    taxExempt
    billingAddress {
      ...address
    }
    warrantyInput {
      warrantedPartNumber
      warrantedPartDescription
      warrantedQuantity
      warrantyStdDuration
      warrantyMaxExtDuration
      warrantyPartNumber
      warrantyOptions {
        duration
        price
      }
    }
  }
  ${QUOTE_LIST_INFO}
  ${address}
`;

export const QUOTE_FRAGMENT = gql`
  fragment quoteInfo on Quote {
    ...quoteCartInfo
  }
  ${QUOTE_CART_INFO}
`;
