import { useRecoilValue } from 'recoil';
import { OverlayLoader } from '@Calix-Commerce/design-system/components';
import { notifySpinnerTimeout } from 'connectors/bugsnag/events';
import { loadingState } from 'state/atoms';
import { ReactElement } from 'react';

const LoaderOverlay = (): ReactElement => {
  const isLoading = useRecoilValue(loadingState);

  const timeoutLimit = parseInt(import.meta.env.VITE_SPINNER_TIME_LIMIT);

  return (
    <OverlayLoader
      isLoading={isLoading}
      timeoutLimit={timeoutLimit}
      timeoutAction={() => notifySpinnerTimeout(timeoutLimit)}
    />
  );
};

export { LoaderOverlay };
