import { ReactElement } from 'react';
import { ModalProps, Modal } from '@Calix-Commerce/design-system/components';

export type QuoteExpiredModalProps = Omit<Omit<ModalProps, 'title'>, 'content'> & {
  onConfirmation: () => void;
};

const QuoteExpiredModal = ({ onConfirmation, ...props }: QuoteExpiredModalProps): ReactElement => {
  return (
    <Modal
      title="Quote Expired"
      content="This quote is expired. Please, contact C-Ops."
      confirmButtonText="OK"
      onConfirmation={onConfirmation}
      {...props}
    />
  );
};

export { QuoteExpiredModal };
