import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { useForm } from 'react-hook-form';
import { FormGroup } from '@Calix-Commerce/design-system/components';
import { ACCOUNT_SEARCH_INPUT_ID, ACCOUNT_SEARCH_SUBMIT_ID } from 'utils/constants/selectors';
import { Header, InputWrapper } from './styledComponents';
import { FormLabel } from 'componentsMigrated/FormLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SearchIcon = () => <FontAwesomeIcon icon={faSearch} size="lg" />;

const Search = ({ onSubmit }) => {
  const {
    formState: { errors },
    register,
    getValues,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: { searchTerm: '' },
  });

  const handleFormSubmit = (event) => {
    event.preventDefault();

    trigger().then((isFormValid) => {
      if (isFormValid) {
        onSubmit(getValues());
      }
    });
  };

  return (
    <form onSubmit={handleFormSubmit} style={{ marginBottom: '3.6rem' }}>
      <Header>Account Search</Header>
      <FormGroup>
        <FormLabel htmlFor={ACCOUNT_SEARCH_INPUT_ID}>Search for an account</FormLabel>
        <InputWrapper
          onAction={handleFormSubmit}
          Icon={<SearchIcon />}
          inputProps={{
            id: ACCOUNT_SEARCH_INPUT_ID,
            placeholder: 'Search by account name or customer operations rep.',
            error: Boolean(errors.searchTerm),
            autoFocus: true,
            ...register('searchTerm'),
          }}
          buttonProps={{ id: ACCOUNT_SEARCH_SUBMIT_ID }}
        />
      </FormGroup>
    </form>
  );
};

export { Search };
