import { getI18NText } from 'I18N';
import { CheckOutFooterContainer, ProgressButton } from './styledComponents';
import { ButtonBaseProps } from '@Calix-Commerce/design-system';

type CheckoutFooterActions = {
  navigate: () => Promise<void>;
  validate?: (navigationRedirectFn?) => Promise<unknown>;
};
type CheckoutFooterProps = {
  backNavigationConfig: CheckoutFooterActions;
  nextNavigationConfig: CheckoutFooterActions;
  nextButtonText?: string;
  prefixElements?: React.ReactNode;
  nextButtonProps?: ButtonBaseProps;
};

export const CheckoutFooter = ({
  backNavigationConfig,
  nextNavigationConfig,
  nextButtonText = getI18NText('NEXT'),
  nextButtonProps = {},
  prefixElements,
}: CheckoutFooterProps) => {
  const handleBackClick = async () => {
    await backNavigationConfig.navigate();
  };

  const handleNextClick = async () => {
    try {
      if (typeof nextNavigationConfig.validate === 'function') {
        await nextNavigationConfig.validate();
      }

      await nextNavigationConfig.navigate();
    } catch (error) {}
  };

  return (
    <CheckOutFooterContainer spacing="flex-end">
      {prefixElements}
      <ProgressButton onClick={handleBackClick} id="checkout-page-back">
        {getI18NText('BACK')}
      </ProgressButton>
      <ProgressButton onClick={handleNextClick} {...nextButtonProps} id="checkout-page-next">
        {nextButtonText}
      </ProgressButton>
    </CheckOutFooterContainer>
  );
};
