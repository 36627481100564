import { getI18NText } from 'I18N/I18N';
import { AdditionalShippingInstructionRow } from './styledComponents';
import { Input } from '@Calix-Commerce/design-system/components';

export const AdditionalShippingInstruction = ({
  id,
  label,
  characterLimit,
  onInstructionChange,
  instruction = '',
  instructionInfo,
}) => {
  const instructionCharacterLength = !instruction ? 0 : instruction.length;
  return (
    <AdditionalShippingInstructionRow>
      <label className="instruction-label" htmlFor={id}>
        {label}
      </label>
      <div className="instruction-data">
        <Input
          id={id}
          className="pkg-instructions"
          maxLength={characterLimit}
          value={instruction}
          onChange={(event) => {
            onInstructionChange(event.currentTarget.value);
          }}
        ></Input>
        <span className="remaining-character">{`${characterLimit - instructionCharacterLength} ${getI18NText('CHARACTERS_REMAINING')}`}</span>
        {instructionInfo && <span className="instruction-info">{instructionInfo}</span>}
      </div>
    </AdditionalShippingInstructionRow>
  );
};
